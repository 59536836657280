export function UniversityHatIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fontSize: '20px' }}
    >
      <path
        opacity="0.4"
        d="M24.23 17.978L8.91 20.97a3.608 3.608 0 00-2.906 3.355 2.4 2.4 0 01.165 4.045l1.905 8.57A1.2 1.2 0 016.9 38.4H2.695a1.2 1.2 0 01-1.171-1.46l1.904-8.57a2.399 2.399 0 01.175-4.05 6.012 6.012 0 014.846-5.706l15.32-2.991a1.202 1.202 0 011.43.943 1.2 1.2 0 01-.97 1.412z"
        fill="currentColor"
      ></path>
      <path
        d="M46.674 19.71L25.75 26.138a6 6 0 01-3.509 0L7.475 21.6a3.588 3.588 0 011.435-.63l15.32-2.992a1.2 1.2 0 10-.46-2.355L8.45 18.614a5.99 5.99 0 00-3.568 2.189l-3.558-1.094c-1.765-.542-1.765-2.877 0-3.419l20.92-6.427a5.994 5.994 0 013.51 0l20.92 6.427c1.767.543 1.767 2.877 0 3.42zM26.46 28.432a8.395 8.395 0 01-4.92 0L10.666 25.09 9.6 33.6c0 2.65 6.45 4.8 14.4 4.8s14.4-2.15 14.4-4.8l-1.065-8.511-10.876 3.342z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
