import { PrimaryDropdownProps, PrimaryDropdownVariant } from './primary-dropdown-types';
import React, { useEffect, useId, useState } from 'react';

import {
  Box,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Popover,
  PopoverOrigin,
  Select,
  SelectChangeEvent,
  Stack,
  useTheme,
} from '@mui/material';
import { primaryDropdownStyles } from './primary-dropdown.styles';

import { ButtonComponent } from '../primary-button/primary-button';
import { InternalErrorMessage } from '../../constants/message-constants';
import { ArrowDownIcon } from '../../icons/arrow-icons';

const DefaultAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const DefaultTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

function LinksDropdown({
  options,
  contentTopSpace,
  buttonContent,
  arrowSettings,
  anchorOrigin,
  transformOrigin,
  bottomLineDistance,
  wrapperSx,
  buttonContentWrapperSx,
  isDropdownOpen,
}: PrimaryDropdownProps) {
  const styles = primaryDropdownStyles()({ ...arrowSettings, bottomLineDistance });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const isBottomLineVisible = isOpen && bottomLineDistance;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event?.currentTarget);

  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    if (!isDropdownOpen) handleClose();
  }, [isDropdownOpen]);

  return (
    <div style={{ display: 'flex' }}>
      <ButtonComponent disableRipple onClick={handleClick} sx={{ padding: '0 16px', height: 'inherit' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={arrowSettings?.distance ?? 0.5}
          alignItems="center"
          sx={buttonContentWrapperSx}
        >
          {buttonContent}

          {!arrowSettings?.isHidden && <ArrowDownIcon color={arrowSettings?.color ?? '#5D676B'} isUp={isOpen} />}
        </Stack>
        {isBottomLineVisible && <Box sx={styles.bottomLine} />}
      </ButtonComponent>

      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        marginThreshold={contentTopSpace}
        onClose={handleClose}
        sx={styles.select}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Box sx={wrapperSx}>
          {options.map((option) => {
            if (option.variant === PrimaryDropdownVariant.Standard) return option.content;

            return (
              <MenuItem sx={styles.selectItem} key={option.id} onClick={handleClose}>
                <Link
                  href={option.link}
                  target={option.openBlankPage ? '_blank' : undefined}
                  underline="none"
                  sx={{ width: '100%' }}
                >
                  {option.content}
                </Link>
              </MenuItem>
            );
          })}
        </Box>
      </Popover>
    </div>
  );
}

const StandardDropdown = ({
  options,
  labelText,
  onSelect,
  contentTopSpace,
  defaultValue,
  arrowSettings,
  anchorOrigin,
  transformOrigin,
  wrapperSx,
  selectSx,
}: PrimaryDropdownProps) => {
  const id = useId();
  const theme = useTheme();

  const styles = primaryDropdownStyles(theme)(arrowSettings);
  const [selectedItem, setSelectedItem] = useState<string | number>(() => {
    const firstOptionItemValue = options[0].value as string;
    return defaultValue ?? firstOptionItemValue;
  });

  const selectSxStyles = Object.assign({}, styles.select, selectSx ?? {});

  const handleChange = (event: SelectChangeEvent) => {
    const selectedItem = event.target.value as string;
    setSelectedItem(selectedItem);
    onSelect?.(selectedItem);
  };

  return (
    <FormControl fullWidth sx={wrapperSx}>
      <InputLabel id={id}>{labelText}</InputLabel>
      <Select
        sx={selectSxStyles}
        labelId={id}
        value={String(selectedItem)}
        label={labelText}
        onChange={handleChange}
        MenuProps={{
          marginThreshold: contentTopSpace,
          anchorOrigin,
          transformOrigin,
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} sx={styles.selectItem} value={option.value}>
            {option.content}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const PrimaryDropdown = ({
  variant = PrimaryDropdownVariant.Standard,
  options,
  buttonContent,
  labelText,
  onSelect,
  defaultValue,
  contentTopSpace,
  arrowSettings,
  anchorOrigin = DefaultAnchorOrigin,
  transformOrigin = DefaultTransformOrigin,
  bottomLineDistance,
  wrapperSx,
  selectSx,
  buttonContentWrapperSx,
  isDropdownOpen,
}: PrimaryDropdownProps) => {
  switch (variant) {
    case PrimaryDropdownVariant.Link:
      return (
        <LinksDropdown
          isDropdownOpen={isDropdownOpen}
          contentTopSpace={contentTopSpace}
          arrowSettings={arrowSettings}
          options={options}
          buttonContent={buttonContent}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          bottomLineDistance={bottomLineDistance}
          buttonContentWrapperSx={buttonContentWrapperSx}
          wrapperSx={wrapperSx}
        />
      );
    case PrimaryDropdownVariant.Standard:
      return (
        <StandardDropdown
          contentTopSpace={contentTopSpace}
          defaultValue={defaultValue}
          options={options}
          onSelect={onSelect}
          labelText={labelText}
          selectSx={selectSx}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          wrapperSx={wrapperSx}
        />
      );
    default:
      return <span style={{ color: 'red' }}>{InternalErrorMessage.Dropdown()}</span>;
  }
};
