import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import dots from 'assets/images/dots.svg'
import iconmodal from 'assets/images/iconmodal.svg'
import moment from 'moment'
import './ListRegistrants.scss'
import axios from 'axios'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

interface ListRegistrantsItemType {
  id: string
  name: string
  email: string
  syncdate: string
  sessions: Array<{
    demioId: string
    id: string
    timestampWithTimezone: string
    updatedDate: string
    createdDate: string
  }>
  eventToPromoterReferringSource: string | null
  registrations: string
  onUserDelete: (id: string, eventToPromoterId: string) => Promise<void>
  eventToPromoterId: string
  setChanged: React.Dispatch<React.SetStateAction<boolean>>
  archived: boolean
  onUserAddBack: (id: string, eventToPromoterId: string) => Promise<void>
  setCopied: React.Dispatch<React.SetStateAction<boolean>>
  setRemoved: React.Dispatch<React.SetStateAction<boolean>>
  setBack: React.Dispatch<React.SetStateAction<boolean>>
  setResend: React.Dispatch<React.SetStateAction<boolean>>
  emailRecieved: boolean
}

const BACKEND_API = process.env.REACT_APP_BACKEND_API
const MY_URL = process.env.REACT_APP_MY_URL

const ListRegistrantsItem: React.FC<ListRegistrantsItemType> = ({
  id,
  name,
  email,
  registrations,
  onUserDelete,
  eventToPromoterId,
  eventToPromoterReferringSource,
  setChanged,
  archived,
  syncdate,
  onUserAddBack,
  setCopied,
  emailRecieved,
  setRemoved,
  setBack,
  setResend,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState(false)
  const [user] = useAuthState(auth)
  const isMenuOpen = Boolean(anchorEl)

  const handleOpen = () => setOpenModal(true)
  const handleCloseModal = () => {
    setOpenModal(false)
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const resendEmail = async () => {
    await axios.patch(
      `${BACKEND_API}/events/event/${id}/promoters/promoter/${id}/event-to-promoters/event-to-promoter/${eventToPromoterId}`,
      {
        invitationEmailSent: true,
      },
      {
        headers: {
          Authorization: `Bearer ${await user?.getIdToken(true)}`,
        },
      },
    )
  }

  const copyLink = () => {
    navigator.clipboard.writeText(`${MY_URL}/share?boost_unique_code=${eventToPromoterId}`)
    setCopied(true)
    setRemoved(false)
    setBack(false)
    setResend(false)
    handleClose()
  }

  const formatedDate = new Date(syncdate)
  const formatedSync = moment(formatedDate).format('ddd,MMM, Do, YYYY @ h:mm A Z')

  return (
    <tr key={id} className={archived ? 'archived' : ''}>
      <td>
        <div className='user_table'>
          <div className='user_table_img'>
            <p>{name.substring(0, 1)}</p>
          </div>
          <div className='user_table_info'>
            <p className='user_table_name' dangerouslySetInnerHTML={{ __html: name }} />
            <p className='user_table_email'>{email}</p>
          </div>
        </div>
      </td>
      <td>
        {' '}
        <p className='refering_source'>
          {eventToPromoterReferringSource === null ? 'Demio' : 'Boost'}
        </p>
      </td>

      <td className='tabletd'>{registrations}</td>
      <td className='tabletd' style={{ width: '150px' }}>
        {formatedSync}
      </td>
      <td>
        <Button
          id='demo-positioned-button'
          aria-controls={isMenuOpen ? 'demo-positioned-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={isMenuOpen ? 'true' : undefined}
          onClick={handleClick}
        >
          <img src={dots} alt='icondown' />
        </Button>
        <Menu
          id='demo-positioned-menu'
          aria-labelledby='demo-positioned-button'
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: 300,
              borderRadius: '4px',
            },
          }}
          sx={{ marginTop: '125px' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          {emailRecieved && (
            <MenuItem
              sx={{ fontFamily: 'Lato,sans-serif', fontSize: '14px', color: '#5D676B' }}
              onClick={() => {
                resendEmail()
                setResend(true)
                setCopied(false)
                setRemoved(false)
                setBack(false)
              }}
            >
              Re-send email
            </MenuItem>
          )}

          <MenuItem
            sx={{ fontFamily: 'Lato,sans-serif', fontSize: '14px', color: '#5D676B' }}
            onClick={copyLink}
          >
            Copy sharing link
          </MenuItem>
          {archived ? (
            <MenuItem
              sx={{ fontFamily: 'Lato,sans-serif', fontSize: '14px', color: '#5D676B' }}
              onClick={handleOpen}
            >
              Add to campaign
            </MenuItem>
          ) : (
            <MenuItem
              sx={{ fontFamily: 'Lato,sans-serif', fontSize: '14px', color: '#5D676B' }}
              onClick={handleOpen}
            >
              Remove from campaign
            </MenuItem>
          )}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 480,
                padding: '50px',
                bgcolor: 'background.paper',
                borderRadius: '8px',
                boxShadow: 24,
                p: 4,
              }}
            >
              <div className='flex_start'>
                <img src={iconmodal} alt='iconmodal' />
                {archived ? (
                  <p
                    style={{
                      marginLeft: '20px',
                      color: '#2C3336',
                      fontSize: '16px',
                      fontWeight: '400',
                      fontFamily: 'Lato,sans-serif',
                    }}
                  >
                    Are you sure you want to add back?
                  </p>
                ) : (
                  <p
                    style={{
                      marginLeft: '20px',
                      color: '#2C3336',
                      fontSize: '16px',
                      fontWeight: '400',
                      fontFamily: 'Lato,sans-serif',
                    }}
                  >
                    Are you sure you want to remove?
                  </p>
                )}
              </div>
              {archived ? (
                <Typography
                  id='modal-modal-description'
                  sx={{
                    mt: 2,
                    marginLeft: '45px',
                    color: '#2C3336',
                    fontSize: '14px',
                    fontWeight: '400',
                    fontFamily: 'Lato,sans-serif',
                  }}
                >
                  By adding promoters to your campaign, they will start receiving future sharing
                  emails about this campaign.
                </Typography>
              ) : (
                <Typography
                  id='modal-modal-description'
                  sx={{
                    mt: 2,
                    marginLeft: '45px',
                    color: '#2C3336',
                    fontSize: '14px',
                    fontWeight: '400',
                    fontFamily: 'Lato,sans-serif',
                  }}
                >
                  By removing promoters from your campaign, they will stop receiving future sharing
                  emails about this campaign. They will still be registered for the event, and they
                  will still receive email notifications about this event.
                </Typography>
              )}

              <div className='flex_end' style={{ marginTop: '25px' }}>
                <button className='cancel' onClick={handleCloseModal}>
                  Cancel
                </button>
                {archived ? (
                  <button
                    className='confirm'
                    onClick={() => {
                      onUserAddBack(id, eventToPromoterId)
                      setChanged(true)
                      handleCloseModal()
                      setBack(true)
                      setCopied(false)
                      setRemoved(false)
                      setResend(false)
                    }}
                    style={{ margin: '0px 10px' }}
                  >
                    Confirm
                  </button>
                ) : (
                  <button
                    className='confirm'
                    onClick={() => {
                      onUserDelete(id, eventToPromoterId)
                      setChanged(true)
                      handleCloseModal()
                      setRemoved(true)
                      setCopied(false)
                      setBack(false)
                      setResend(false)
                    }}
                    style={{ margin: '0px 10px' }}
                  >
                    Confirm
                  </button>
                )}
              </div>
            </Box>
          </Modal>
        </Menu>
      </td>
    </tr>
  )
}

export default ListRegistrantsItem
