import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material'
import customizetab from 'assets/images/customizetab.svg'
import room from 'assets/images/room.svg'
import { useState } from 'react'
import CustomizeModal from 'components/CustomizeModal/CustomizeModal'
import '../Customize/Customize.scss'

interface CustomizeTabProps {
  handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void
  expanded: string | false
}

const CustomizeTab: React.FC<CustomizeTabProps> = ({ expanded, handleChange }) => {
  const [open, setOpen] = useState(false)

  const [isOpen, setIsOpen] = useState<string | false>(false)

  const handleOpen = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setIsOpen(isExpanded ? panel : false)
  }

  return (
    <>
      <Accordion
        className={expanded === 'panel3' ? 'bd_top' : 'accordionhover'}
        sx={{
          width: '100%',
          paddingTop: '12px',
          paddingBottom: '12px',
          marginTop: '30px',
        }}
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
        data-intercom-target='rewards'
      >
        <AccordionSummary aria-controls='panel3bh-content' id='panel3bh-header'>
          <div className='flex_start'>
            <img src={customizetab} alt='emailicon' style={{ width: '36px', height: '36px' }} />
            <div className='flex_column' style={{ marginLeft: '30px' }}>
              <p className='acordion_heading'>Sharing Page</p>
              <p className='acordion_about'>Customize the Page</p>
            </div>
          </div>
          <div className='flex_end'>
            {expanded === 'panel3' && <button className='done'>DONE</button>}
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            paddingTop: '30px',
            paddingBottom: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
          }}
          className={expanded ? 'bd_top_gray' : ''}
        >
          <div className='customize_square'>
            <img src={room} alt='' />
            <p>Sharing Page</p>
            <Button
              sx={{ '&:hover': { backgroundColor: '#1d4842' } }}
              className='customize_btn'
              onClick={() => setOpen(true)}
            >
              CUSTOMIZE
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
      <CustomizeModal open={open} setOpen={setOpen} handleOpen={handleOpen} isOpen={isOpen} />
    </>
  )
}

export default CustomizeTab
