import { ReactNode } from 'react';
import { navbarDropdownItemStyles } from './navbar-dropdown-item.styles';
import { Box, Stack, Typography } from '@mui/material';
import { Products } from '../../../constants/products-constants';

export const enum DropDownItemVariant {
  Success = 'Success',
  Danger = 'Danger',
  Text = 'Text',
}

export const NavbarDropdownItem = ({
  icon,
  header,
  description,
  variant = DropDownItemVariant.Success,
  productVariant,
  iconAlignment = 'center',
}: {
  icon: ReactNode;
  header: ReactNode | string;
  description: ReactNode | string;
  variant?: DropDownItemVariant;
  productVariant?: Products;
  iconAlignment?: string;
}) => {
  const styles = navbarDropdownItemStyles({ variant, productVariant });

  return (
    <Stack direction={'row'} sx={styles.dropdownItemContainer} justifyContent="flex-start" spacing={2}>
      <Stack
        alignItems={'center'}
        justifyContent={iconAlignment}
        sx={styles.dropdownItemIcon}
        className="dropdownItemIcon"
      >
        {icon}
      </Stack>

      <Box>
        <Typography className="dropdownItemHeader" sx={styles.dropdownItemHeader}>
          {header}
        </Typography>
        <Typography className="dropdownItemDescription" sx={styles.dropdownItemDescription}>
          {description}
        </Typography>
      </Box>
    </Stack>
  );
};
