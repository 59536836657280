import { DropDownItemVariant } from './navbar-dropdown-item';
import { SxStyleType } from '../../../types/theme-types';
import { Products } from '../../../constants/products-constants';

type RuleNames = 'dropdownItemHeader' | 'dropdownItemContainer' | 'dropdownItemDescription' | 'dropdownItemIcon';

interface UseStyleProps {
  hasSeparator?: boolean;
  variant?: DropDownItemVariant;
  productVariant?: Products;
}

const getDropdownItemContainerBgHoverColor = (variant?: DropDownItemVariant) => {
  switch (variant) {
    case DropDownItemVariant.Text:
      return 'rgba(0, 0, 0, 0.04)';
    case DropDownItemVariant.Danger:
      return 'redLighter';
    case DropDownItemVariant.Success:
      return 'greenLight';
    default:
      return 'rgba(0, 0, 0, 0.04)';
  }
};

export const navbarDropdownItemStyles = ({ variant, productVariant }: UseStyleProps): SxStyleType<RuleNames> => ({
  dropdownItemContainer: {
    padding: '12px',
    transition: '0.3s',
    width: '100%',
    '&:hover': {
      bgcolor: getDropdownItemContainerBgHoverColor(variant),
    },

    '&:hover .dropdownItemHeader': {
      color: 'primary.main',
    },
    '&:hover .dropdownItemDescription': {
      color: 'textDark',
    },
    '&:hover .dropdownItemIcon': {
      color: 'primary.main',
    },
  },

  dropdownItemIcon: {
    marginTop: '4px',
    color: 'textDark',
  },

  dropdownItemHeader: {
    color: productVariant === Products.Showcase ? 'textDark' : 'rgba(0,0,0,0.65)',
    fontSize: '14px',
    lineHeight: '22px',
    whiteSpace: 'normal',
    fontWeight: 500,
  },

  dropdownItemDescription: {
    color: productVariant === Products.Showcase ? 'gray300' : 'rgb(159, 166, 173)',
    fontSize: '12px',
    lineHeight: '18px',
    whiteSpace: 'normal',
    fontWeight: 500,
  },
});
