import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from 'features/slices/eventSlice'
import './LinkedinTemplate.scss'
import plus from 'assets/images/plus.svg'
import upload from 'assets/images/imageupload.svg'
import uploaded from 'assets/images/uploaded.svg'
import closeimage from 'assets/images/closeimage.svg'

interface LinkedinTemplateProps {
  setLinkedinData: React.Dispatch<React.SetStateAction<TemplateType>>
  linkedinData: TemplateType
  linkedinDataModified: boolean
  setLinkedinDataModified: React.Dispatch<React.SetStateAction<boolean>>
}

const LinkedinTemplate: React.FC<LinkedinTemplateProps> = ({
  setLinkedinData,
  linkedinData,
  setLinkedinDataModified,
}) => {
  const [isUpload, setIsUpload] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isId, setIsId] = useState(false)
  const currentEvent = useSelector(getCurrentEvent)

  useEffect(() => {
    if (linkedinData.id) setIsId(true)
  }, [linkedinData.id])

  const updateLinkedinData = (updatedData: TemplateType) => {
    setLinkedinData(updatedData)
    setLinkedinDataModified(true)
  }

  const closeImage = () => {
    setIsUpload(false)
    setIsLoaded(false)
    updateLinkedinData({
      ...linkedinData,
      photo: '',
      imageURL: '',
      imageKey: '',
      imageOriginalName: '',
    })
    setIsId(false)
  }

  const binaryData = linkedinData?.photo ? [linkedinData.photo] : []

  useEffect(() => {
    if (linkedinData.id) {
      setIsId(true)
    }
  }, [linkedinData.id])

  return (
    <div className='linkedin_wrapper'>
      <div className='linkedin_left' data-intercom-target='linkedinEdit'>
        <div className='flex_center'>
          <p className='edit_p'>Edit</p>
        </div>

        <div className='flex_center' style={{ marginTop: '30px' }}>
          <TextField
            value={linkedinData.title}
            onChange={(e) => setLinkedinData({ ...linkedinData, title: e.target.value })}
            placeholder='First Webinar'
            label={'Title'}
            required
            helperText={linkedinData.title.length < 1 ? 'This Field is Required' : ''}
            error={linkedinData.title.length < 1}
            variant='outlined'
            InputLabelProps={{
              style: { color: linkedinData.title.length < 1 ? '#d32f2f' : '#42454B' },
            }}
            sx={{
              width: '100%',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: linkedinData.title.length < 1 ? '#d32f2f' : '#1D4842',
                },
              },
            }}
          />
        </div>

        <div className='flex_center'>
          {isUpload || linkedinData.photo || (isId && linkedinData.imageURL) ? (
            <div className='upload_div'>
              <img src={upload} alt='linkedin' className='uploadedimage' />
              <p
                title={linkedinData?.photo?.name || linkedinData?.imageOriginalName || 'image'}
                style={{
                  marginLeft: '8px',
                  marginRight: '8px',
                  wordBreak: 'break-all',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {linkedinData?.photo?.name || linkedinData?.imageOriginalName || 'image'}
              </p>
              {isLoaded || linkedinData.photo || linkedinData.id ? (
                <img src={uploaded} alt='uploaded' />
              ) : (
                <ClipLoader color='#1D4842' size={20} speedMultiplier={0.3} />
              )}
              {(isLoaded || linkedinData.photo || linkedinData.id) && (
                <button onClick={closeImage} className='close_image'>
                  <img src={closeimage} alt='close' />
                </button>
              )}
            </div>
          ) : (
            <div className='file-card' style={{ height: '220px' }}>
              <div className='file-inputs'>
                <input
                  type='file'
                  accept='image/png, image/gif, image/jpeg, image/png, image/svg'
                  id='image'
                  onChange={(e) => {
                    if (e.target.files) {
                      setLinkedinData({ ...linkedinData, photo: e.target.files[0] })
                      setIsUpload(true)
                      setTimeout(() => {
                        setIsLoaded(true)
                      }, 3000)
                    }
                  }}
                />

                <button>
                  <img src={plus} style={{ width: '30px' }} alt='iconplus' />
                </button>
              </div>
              <p className='upload'>Upload Media</p>
              <p className='info'>.JPEG .PNG .JPG up to 3 MB</p>
            </div>
          )}
        </div>
        <div className='flex_start'>
          <p style={{ marginTop: '10px' }}>registration_short_link</p>
        </div>
      </div>
      <div className='linkedin_right' data-intercom-target='linkedinPreview'>
        <div className='flex_center'>
          <p className='edit_p'>Preview</p>
        </div>
        <div className='url_share_page noborder' style={{ marginTop: '80px' }}>
          <div className='flex_center'>
            {(() => {
              if (linkedinData.id && !isUpload && linkedinData.imageURL)
                return (
                  <img src={linkedinData.imageURL} alt='linkedin_photo' className='share_image' />
                )

              if (linkedinData.photo)
                return (
                  <img
                    src={URL.createObjectURL(new Blob(binaryData, { type: 'application/zip' }))}
                    className='share_image'
                    alt='image_preview'
                  />
                )
            })()}
          </div>
          <div className='url_share_page_bottom'>
            <div className='flex_start'>
              <div title={linkedinData.title ?? currentEvent.name} className='urltitle'>
                {linkedinData.title ?? currentEvent.name}
              </div>
            </div>
            <div className='flex_start'>
              <p className='url_text'>boost.banzai.io • 1 min read</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinkedinTemplate
