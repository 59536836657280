export const ErrorMessage = {
  default: () => 'Something went wrong',
  ImageTypeError: 'Choose on of types JPG, WEBP or PNG',
  ImageSizeError: 'Very big image',
};

export const InternalErrorMessage = {
  NavigationButton: () => 'Use one of [buttonSettings] or [linkSettings] props',
  Dropdown: () => 'Use one of dropdown variants',
};
