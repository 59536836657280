import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { fetchLinkedIn } from 'api/api'

const initialState: TemplateType = {
  title: '',
  photo: '',
  id: '',
  imageURL: '',
  imageKey: '',
  isActive: true,
  imageOriginalName: '',
}

const linkedinSlice = createSlice({
  name: 'linkedin',
  initialState,
  reducers: {
    setLinkedinData: (state, { payload }) => {
      return {
        ...state,
        title: payload.title,
        photo: payload.photo,
        id: payload.id,
        imageURL: payload.imageURL,
        imageKey: payload.imageKey,
        isActive: payload.isActive,
        imageOriginalName: payload.imageOriginalName,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLinkedIn.fulfilled, (state, action) => {
      return {
        ...state,
        title: action.payload.title,
        photo: action.payload.imageOriginalName,
        id: action.payload.id || state.id,
        imageURL: action.payload.imageURL,
        imageKey: action.payload.imageKey,
        isActive: action.payload.isActive,
        imageOriginalName: action.payload.imageOriginalName,
      }
    })
  },
})

export const getLinkedInData = (state: RootState) => state.linkedin

export const { setLinkedinData } = linkedinSlice.actions

export default linkedinSlice.reducer
