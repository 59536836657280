import { SxStyleType } from '../../../types/theme-types';

type RuleNames = 'buttonWrapper';

export const navbarQuestionsDropdownStyles = (): SxStyleType<RuleNames> => ({
  buttonWrapper: {
    width: '100%',
    transition: '0.3s',
    color: 'rgba(0,0,0,0.65)',

    '&:hover': {
      transition: 0,
      color: 'primary.main',
    },
  },
});
