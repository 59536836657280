import BarComponent from 'components/BarComponent/BarComponent'
import './Insights.scss'

const Insights = () => {
  return (
    <div className='insights_wrapper'>
      <BarComponent />
    </div>
  )
}

export default Insights
