import React, { useState, useEffect } from 'react'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import close from 'assets/images/close.svg'
import { useSelector } from 'react-redux'
import { getCurrentEvent, getCurrentRewards } from 'features/slices/eventSlice'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { ParsedToken } from 'firebase/auth'
import { TextField } from '@mui/material'
import logo from 'assets/images/boostlogo.svg'

interface CustomizeEmailDetailsProps {
  expanded: string | false
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  maxHeight: 850,
  bgcolor: 'background.paper',
  borderRadius: 2,
  p: 4,
}

const CustomizeEmailDetails: React.FC<CustomizeEmailDetailsProps> = ({ expanded }) => {
  const [openModal, setOpenModal] = useState(false)
  const [user] = useAuthState(auth)
  const rewards = useSelector(getCurrentRewards)
  const [template, setTemplate] = useState({
    id: '',
    isActive: false,
    subject: '',
    text: '',
    logoPath: '',
    fromName: '',
  })
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const currentEvent = useSelector(getCurrentEvent)

  const { id } = useParams()
  const BACKEND_API = process.env.REACT_APP_BACKEND_API
  const [tokenResult, setTokenResult] = useState<ParsedToken>()

  useEffect(() => {
    const getUserFromFirebase = async () => {
      const idTokenResult = await user?.getIdTokenResult()
      setTokenResult(idTokenResult)
    }
    getUserFromFirebase()

    const fetchEmail = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events/event/${id}/email-invitation-templates/`,
      )
      setTemplate({
        ...template,
        text: data?.result[0]?.text,
        isActive: data?.result[0]?.isActive ?? true,
        subject: data?.result[0]?.subject ?? 'Share the event with Boost',
        id: data?.result[0]?.id,
        logoPath: currentEvent?.logoPath,
        fromName: data?.result[0]?.fromName ?? tokenResult?.claims?.companyName,
      })
    }

    fetchEmail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSend = async () => {
    const { data } = await axios.post(
      `${BACKEND_API}/events/event/${id}/email-invitation-templates/email-invitation-template`,
      {
        subject: template.subject,
        text: template.text,
        isActive: true,
        fromName: template.fromName,
      },
      {
        headers: {
          Authorization: `Bearer ${await user?.getIdToken(true)}`,
        },
      },
    )
    setTemplate(data)
  }

  return (
    <AccordionDetails
      sx={{ paddingTop: '50px' }}
      className={expanded ? 'bd_top_gray' : ''}
      data-intercom-target='emailPopup'
    >
      <div className='flex_between'>
        <p className='instant'>Invitation</p>
        <div className='switch' style={{ display: 'flex', alignItems: 'center' }}>
          <button className='edit_email' onClick={handleOpenModal} data-intercom-target='editEmail'>
            Edit
          </button>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <div
                className='flex_between'
                style={{
                  borderBottom: '1px solid #DEE4E8',
                  paddingBottom: '20px',
                  position: 'sticky',
                }}
              >
                <p className='modal_p' style={{ marginLeft: '165px' }}>
                  Edit Email - Invitation
                </p>
                <button className='close' type='button' data-intercom-target='emailClose'>
                  <img src={close} onClick={handleCloseModal} alt='close' />
                </button>
              </div>
              <div
                className='flex_center'
                style={{
                  position: 'sticky',
                  alignItems: 'center',
                  height: '100px',
                  marginTop: '0px',
                  borderBottom: '1px solid #DEE4E8',
                }}
              >
                <figure className='logo_div_second'>
                  {template?.logoPath?.length > 0 ? (
                    <img src={template.logoPath} alt='logo' className='brand_logo' />
                  ) : (
                    <img
                      src={logo}
                      alt='logo'
                      style={{
                        maxWidth: '105px',
                        maxHeight: '31px',
                        width: 'auto',
                        height: 'auto',
                      }}
                    />
                  )}
                </figure>
              </div>
              <div
                className='modal_main'
                style={{
                  width: '100%',
                  overflowY: 'scroll',
                  height: '520px',
                  marginTop: '20px',
                  paddingTop: '10px',
                }}
              >
                <TextField
                  id='outlined-basic'
                  label='Subject'
                  data-intercom-target='emailSubject'
                  required
                  error={template?.subject.length < 1}
                  variant='outlined'
                  defaultValue={template?.subject}
                  helperText={template?.subject.length < 1 ? 'This Field is Required' : ''}
                  onChange={(e) => setTemplate({ ...template, subject: e.target.value })}
                  InputLabelProps={{
                    style: { color: template.subject.length < 1 ? '#d32f2f' : '#42454B' },
                  }}
                  sx={{
                    width: '100%',
                    borderRadius: '4px',
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: template.subject.length < 1 ? '#d32f2f' : '#1D4842',
                      },
                    },
                  }}
                />

                <TextField
                  id='outlined-basic'
                  label='From Name'
                  variant='outlined'
                  data-intercom-target='emailFromName'
                  error={template?.fromName?.length < 1}
                  required
                  helperText={template?.fromName?.length < 1 ? 'This field is required' : ''}
                  defaultValue={template?.fromName}
                  onChange={(e) => setTemplate({ ...template, fromName: e.target.value })}
                  InputLabelProps={{
                    style: { color: template?.fromName?.length < 1 ? '#d32f2f' : '#42454B' },
                  }}
                  sx={{
                    marginTop: '30px',
                    width: '100%',
                    borderRadius: '4px',
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: template?.fromName?.length < 1 ? '#d32f2f' : '#1D4842',
                      },
                    },
                  }}
                />

                <div className='flex_start' style={{ marginTop: '25px' }}>
                  <p className='email_p'>
                    Hi <mark> &#91; First Name &#93;</mark>
                  </p>
                </div>
                <div className='flex_start'>
                  <p className='email_p'>
                    Thank you for registering for {''} <span>{currentEvent.name}.</span>
                  </p>
                </div>

                <TextField
                  id='outlined-basic'
                  label='Body'
                  data-intercom-target='emailBody'
                  variant='outlined'
                  multiline
                  rows={4}
                  defaultValue={template?.text}
                  onChange={(e) => setTemplate({ ...template, text: e.target.value })}
                  InputLabelProps={{
                    style: { color: '#42454B' },
                  }}
                  sx={{
                    marginTop: '30px',
                    marginBottom: '5px',
                    width: '100%',
                    borderRadius: '4px',
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: '#1D4842',
                      },
                    },
                  }}
                />
                <div className='flex_start'>
                  <p style={{ marginBottom: '20px', marginTop: '0px' }} className='nolink'>
                    Please don&apos;t link to the registration page in your custom content not to
                    lose track of the number of shares.
                  </p>
                </div>

                <div className='flex_start' style={{ marginTop: '5px' }}>
                  <p className='bold'>
                    Share the event with your exclusive link - click the button now!
                  </p>
                </div>

                <div className='flex_start' style={{ marginTop: 20 }}>
                  <button className='share_event_btn'>SHARE EVENT</button>
                </div>

                {rewards.length > 0 ? (
                  <div className='flex_start'>
                    <p className='bold'>Invite Your Network and Earn Rewards:</p>
                  </div>
                ) : (
                  ''
                )}

                {rewards?.map((item) => {
                  return (
                    <div key={item.id} className='reward_div'>
                      <p className='reward_div_p'> {`Name: ${item.name}`} </p>
                      <p className='reward_div_p'> {`Points: ${item.points}`} </p>
                      <p className='reward_div_p'> {`Description: ${item.description}`} </p>
                    </div>
                  )
                })}
                <div className='flex_start'>
                  <p className='bold thanks'>Thanks!</p>
                </div>

                <div className='line_thanks'></div>
              </div>

              <div className='divv' style={{ position: 'sticky' }}>
                <div className='flex_center' style={{ paddingTop: '10px' }}>
                  <p className='email_p' style={{ textAlign: 'center' }}>
                    &#169; Banzai International, Inc. | 435 Ericksen Ave, Suite 250, Bainbridge
                    Island, WA 98110
                    <br /> Need help? Email support@banzai.io
                  </p>
                </div>
                <div className='flex_end'>
                  <button
                    data-intercom-target='emailSave'
                    className='btn_save'
                    onClick={() => {
                      if (template.fromName.length >= 1 && template.subject.length > 0) {
                        handleSend()
                        handleCloseModal()
                      }
                    }}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </AccordionDetails>
  )
}

export default CustomizeEmailDetails
