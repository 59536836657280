import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebase'

const BACKEND_API = process.env.REACT_APP_BACKEND_API

export const fetchEvents: any = createAsyncThunk('/events', async () => {
  const [user] = useAuthState(auth)
  const { data } = await axios.get(`${BACKEND_API}/events?filter[archived][eq]=false`, {
    headers: {
      Authorization: `Bearer ${await user?.getIdToken(true)}`,
    },
  })
  return data.result
})

export const fetchRegistrants: any = createAsyncThunk('/registrants', async (id: string) => {
  const { data } = await axios.get(`${BACKEND_API}/events/event/${id}/promoters`)
  return data.result
})

export const fetchLinkedIn = createAsyncThunk('linkedin/fetchData', async (id: string) => {
  const { data } = await axios.get(
    `${BACKEND_API}/events/event/${id}/socials/linked-in-share-templates`,
  )
  return data.result[0]
})

export const fetchFacebook = createAsyncThunk('facebook/fetchData', async (id: string) => {
  const { data } = await axios.get(
    `${BACKEND_API}/events/event/${id}/socials/facebook-share-templates`,
  )
  return data.result[0]
})

export const fetchTwitter = createAsyncThunk('twitter/fetchData', async (id: string) => {
  const { data } = await axios.get(
    `${BACKEND_API}/events/event/${id}/socials/twitter-share-templates`,
  )
  return data.result[0]
})
export const fetchGmail = createAsyncThunk('gmail/fetchData', async (id: string) => {
  const { data } = await axios.get(
    `${BACKEND_API}/events/event/${id}/socials/gmail-share-templates`,
  )
  return data.result[0]
})

// Refactor to use Axios interceptors to set common headers for all requests
export const fetchReminderState = async (id: string, token: string) => {
  const { data } = await axios.get(`${BACKEND_API}/events/event/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return data
}

export const updateReminderSettings = async (id: string, isActive: boolean, token: string) => {
  await axios.patch(
    `${BACKEND_API}/events/event/${id}`,
    { reminderNotifications: isActive },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}
