import plus from 'assets/images/plus.svg'
import upload from 'assets/images/imageupload.svg'
import uploaded from 'assets/images/uploaded.svg'
import { ClipLoader } from 'react-spinners'
import closeimage from 'assets/images/closeimage.svg'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from 'features/slices/eventSlice'
import { TextField } from '@mui/material'

interface GmailTemplateProps {
  setGmailData: React.Dispatch<React.SetStateAction<GmailTemplateType>>
  gmailData: GmailTemplateType
  setGmailDataModified: React.Dispatch<React.SetStateAction<boolean>>
}

const GmailTemplate: React.FC<GmailTemplateProps> = ({
  setGmailData,
  gmailData,
  setGmailDataModified,
}) => {
  const [isUpload, setIsUpload] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isId, setIsId] = useState(false)
  const currentEvent = useSelector(getCurrentEvent)

  const handleGmailDataChange = (newData: GmailTemplateType) => {
    setGmailData(newData)
    setGmailDataModified(true)
  }

  const closeImage = () => {
    setIsUpload(false)
    handleGmailDataChange({
      ...gmailData,
      photo: '',
      imageURL: '',
      imageKey: '',
      imageOriginalName: '',
    })
    setIsId(false)
  }

  const binaryData = gmailData?.photo ? [gmailData.photo] : []

  useEffect(() => {
    if (gmailData.id) setIsId(true)
  }, [gmailData.id])

  const isSubjectRequired = gmailData.subject.length < 1
  const isTitleRequired = gmailData.title.length < 1

  const textColor = isSubjectRequired ? '#d32f2f' : '#42454B'

  return (
    <div className='linkedin_wrapper'>
      <div className='linkedin_left'>
        <div className='flex_center'>
          <p className='edit_p'>Edit</p>
        </div>
        <div className='flex_center' style={{ marginTop: '30px' }}>
          <TextField
            defaultValue={gmailData.subject}
            onChange={(e) => setGmailData({ ...gmailData, subject: e.target.value })}
            placeholder='First Webinar'
            label={'Subject'}
            required
            helperText={isSubjectRequired ?? 'This Field is Required'}
            error={isSubjectRequired}
            variant='outlined'
            InputLabelProps={{
              style: { color: textColor },
            }}
            sx={{
              width: '100%',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: textColor,
                },
              },
            }}
          />
        </div>
        <div className='flex_center' style={{ marginTop: '30px' }}>
          <TextField
            defaultValue={gmailData.title}
            onChange={(e) => setGmailData({ ...gmailData, title: e.target.value })}
            placeholder='First Webinar'
            label={'Title'}
            required
            helperText={isTitleRequired ?? 'This Field is Required'}
            error={isTitleRequired}
            variant='outlined'
            InputLabelProps={{
              style: { color: isTitleRequired ? '#d32f2f' : '#42454B' },
            }}
            sx={{
              width: '100%',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: isTitleRequired ? '#d32f2f' : '#1D4842',
                },
              },
            }}
          />
        </div>
        <div className='flex_start'>
          <p style={{ marginTop: '10px' }}>registration_short_link</p>
        </div>
        <div className='flex_start'>
          <p className='body'>Body</p>
        </div>
        <div className='flex_center'>
          {isUpload || gmailData.photo || (isId && gmailData.imageURL) ? (
            <div className='upload_div'>
              <img src={upload} alt='gmail' />
              <p
                title={gmailData?.photo?.name || gmailData?.imageOriginalName || 'image'}
                style={{
                  marginLeft: '8px',
                  marginRight: '8px',
                  wordBreak: 'break-all',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {gmailData?.photo?.name || gmailData?.imageOriginalName || 'image'}
              </p>
              {isLoaded || gmailData.photo || gmailData.id ? (
                <img src={uploaded} alt='uploaded' />
              ) : (
                <ClipLoader color='#1D4842' size={20} speedMultiplier={0.3} />
              )}
              {isLoaded ||
                ((gmailData?.photo || gmailData.id) && (
                  <button onClick={closeImage} className='close_image'>
                    <img src={closeimage} alt='close' />
                  </button>
                ))}
            </div>
          ) : (
            <div className='file-card' style={{ height: '220px' }}>
              <div className='file-inputs'>
                <input
                  type='file'
                  id='image'
                  accept='image/png, image/gif, image/jpeg, image/png, image/svg'
                  onChange={(e) => {
                    if (e.target.files) {
                      setGmailData({ ...gmailData, photo: e.target.files[0] })
                      setIsUpload(true)
                      setTimeout(() => {
                        setIsLoaded(true)
                      }, 3000)
                    }
                  }}
                />

                <button>
                  <img src={plus} style={{ width: '30px' }} alt='iconplus' />
                </button>
              </div>
              <p className='upload'>Upload Media</p>
              <p className='info'>.JPEG .PNG .JPG up to 3 MB</p>
            </div>
          )}
        </div>

        <div className='flex_center' style={{ marginTop: '30px' }}>
          <TextField
            multiline
            rows={5}
            defaultValue={gmailData.content}
            onChange={(e) => setGmailData({ ...gmailData, content: e.target.value })}
            placeholder="e.g. The industry's saaviest marketers use webinars to engage with their audience and convert.

              Learn how to become a pro from expert @ashley-levesque @ Webinars That Convert.

              Event Date: Mar. 3rd
              Event Time: 11a ET / 8a PT
              #make-marketing-human #webinars #demio"
            label={'Content'}
            variant='outlined'
            InputLabelProps={{
              style: { color: '#42454B' },
            }}
            sx={{
              width: '100%',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#1D4842',
                },
              },
            }}
          />
        </div>
        <div className='flex_start'>
          <p className='nolink'>
            To avoid inaccurate tracking of your promoters’ shares, please do not include any links
            to your registration page in your custom content
          </p>
        </div>
      </div>
      <div className='linkedin_right'>
        <div className='flex_center'>
          <p className='edit_p'>Preview</p>
        </div>
        <p className='preview_text twitter_preview_text'>{`Subject: ${gmailData.subject ?? ''}`}</p>
        <p className='preview_text'>{gmailData.content}</p>
        <div className='url_share_page' style={{ marginTop: '60px' }}>
          <div className='flex_center'>
            {(() => {
              if (gmailData.id && !isUpload && gmailData.imageURL)
                return <img src={gmailData.imageURL} alt='gmail_photo' className='share_image' />
              if (gmailData.photo)
                return (
                  <img
                    src={URL.createObjectURL(new Blob(binaryData, { type: 'application/zip' }))}
                    className='share_image'
                    alt='image_preview'
                  />
                )
            })()}
          </div>
          <div className='url_share_page_bottom'>
            <div className='flex_start'>
              <div title={gmailData.title ?? currentEvent.name} className='urltitle'>
                {gmailData.title ?? currentEvent.name}
              </div>
            </div>
            <div className='flex_start'>
              <p className='url_text'>boost.banzai.io • 1 min read</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GmailTemplate
