import { SxStyleType } from '../../../types/theme-types';

export const navbarAvatarDropdownStyles = () => ({
  avatarContainer: {
    borderRadius: '100%',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'textDarker',
    bgcolor: 'gray100',
    width: '2.5rem',
    flexShrink: 0,
    height: '2.5rem',
    '& > span': {
      color: 'textDarker',
      lineHeight: 1,
      fontWeight: 700,
      fontSize: '1rem',
    },

    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.04) 0px 4px 8px',
    },
  },
  avatarImage: {
    borderRadius: '100%',
  },
});

type RuleNames = 'contentWrapper' | 'username' | 'companyName';

export const navbarDropdownButtonUseStyles = (): SxStyleType<RuleNames> => ({
  contentWrapper: {
    transition: '0.3s',
    width: '100%',
    maxWidth: '220px',
    color: 'textDark',
    transitionDuration: 0,
    '&:hover .userName': {
      color: 'teal500',
    },
    '&:hover .companyName': {
      color: 'textDark',
    },

    '&:hover .profileImageBox': {
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 4px 8px',
    },
  },

  username: {
    fontSize: '16px',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
    transition: '0.3s',
  },

  companyName: {
    transition: '0.3s',
    fontSize: '14px',
    lineHeight: '22px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'gray300',
  },
});
