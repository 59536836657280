import { ReactNode } from 'react';

import { dropdownItemStyles } from './dropdown-item.styles';
import { Box, SxProps } from '@mui/material';
import { DropDownItemVariant } from '../../containers/navbar/navbar-dropdown-item/navbar-dropdown-item';

export const DropdownItem = ({
  children,
  hasSeparator,
  variant = DropDownItemVariant.Success,
  sx,
}: {
  children: ReactNode | string;
  hasSeparator?: boolean;
  variant?: DropDownItemVariant;
  sx?: SxProps;
}) => {
  const styles = dropdownItemStyles({ hasSeparator, variant });

  const allStyles = Object.assign({}, styles.dropdownItemContainer, sx);

  return <Box sx={allStyles}>{children}</Box>;
};
