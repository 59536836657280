import * as React from 'react'
import { useEffect, useRef, useState, useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { Box, Divider, Grid, Tooltip } from '@mui/material'
import room from 'assets/images/room.svg'
import arrowdown from 'assets/images/arrow_down.svg'
import CustomAccordion from 'components/CustomAccordion/CustomAccordion'
import DropdownSelect from 'components/DropdownSelect/DropdownSelect'

import CustomImageUpload from 'components/CustomImageUpload/CustomImageUpload'
import dots from '../../assets/images/dotsicon.svg'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { CustomizationProgress } from 'components/CustomizationProgress/CustomizationProgress'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import SharePage from 'components/SharePage/SharePage'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from 'features/slices/eventSlice'
import axios from 'axios'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import CustomColorPicker from 'components/CustomColorPicker/CustomColorPicker'
import { ClipLoader } from 'react-spinners'
import './CustomizeModal.scss'
import { ButtonComponent } from '../../ui-kit/components/primary-button/primary-button'

interface CustomizeModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void
  isOpen: string | false
}

const BACKEND_API = process.env.REACT_APP_BACKEND_API

const selectedValuesInitial = {
  headerAlignment: '',
  theme: '',
  componentAlignment: '',
  photo: { name: null },
  backgroundImageURL: '',
  logo: { name: null },
  logoURL: '',
  opacity: '',
  elementColorScheme: '',
  backgroundColorScheme: '',
  primaryFontSize: '',
  primaryFontColorScheme: '',
  secondaryFontSize: '',
  secondaryFontColorScheme: '',
}

const CustomizeModal: React.FC<CustomizeModalProps> = ({ open, setOpen, isOpen, handleOpen }) => {
  const [selectedValues, setSelectedValues] =
    useState<CustomizationTemplateType>(selectedValuesInitial)

  const [popoverOpen, setPopoverOpen] = useState(false)
  const [headerPopoverOpen, setHeaderPopoverOpen] = useState(false)

  const [isUploaded, setIsUploaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [user] = useAuthState(auth)
  const [changed, setChanged] = useState(false)

  const popoverRef = useRef<HTMLDivElement>(null)
  const headerPopoverRef = useRef<HTMLDivElement>(null)

  const binaryData: Uint8Array[] = []
  const logoBinaryData: Uint8Array[] = []

  const currentEvent = useSelector(getCurrentEvent)

  if (selectedValues.photo) {
    binaryData.push(selectedValues.photo)
  }
  if (selectedValues.logo) {
    logoBinaryData.push(selectedValues.logo)
  }

  const handleSave = async () => {
    setIsLoading(true)
    const { data } = await axios.patch(
      `${BACKEND_API}/events/event/${currentEvent.id}/event-customization/${currentEvent?.customization?.id}`,
      {
        headerAlignment: selectedValues.headerAlignment,
        opacity: selectedValues.opacity ?? '',
        componentAlignment: selectedValues.componentAlignment,
        elementColorScheme: selectedValues.elementColorScheme,
        logo: selectedValues.logo ?? '',
        backgroundImage: selectedValues.photo,
        backgroundColorScheme: selectedValues.backgroundColorScheme,
        primaryFontSize: selectedValues.primaryFontSize,
        primaryFontColorScheme: selectedValues.primaryFontColorScheme,
        secondaryFontSize: selectedValues.secondaryFontSize,
        secondaryFontColorScheme: selectedValues.secondaryFontColorScheme,
      },
      {
        headers: {
          Authorization: `Bearer ${await user?.getIdToken(true)}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    )

    const getCustomization = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events/event/${currentEvent.id}/event-customization/${currentEvent?.customization?.id}`,
        {
          headers: {
            Authorization: `Bearer ${await user?.getIdToken(true)}`,
          },
        },
      )

      setSelectedValues({
        ...selectedValues,
        headerAlignment: data?.headerAlignment ?? 'Center',
        componentAlignment: data?.componentAlignment ?? 'Horizontal',
        backgroundImageURL: data?.backgroundImageURL ?? '',
        opacity: data?.opacity ?? '',
        theme:
          data?.backgroundImageURL?.length > 0
            ? 'Background Image'
            : data?.backgroundColorScheme?.length > 0
            ? 'Gradient'
            : 'Boost Basic',
        elementColorScheme: data?.elementColorScheme ?? '#1D4842',
        logoURL: data?.logoURL ?? '',
        backgroundColorScheme: data?.backgroundColorScheme,
        primaryFontSize: data?.primaryFontSize ?? '24px',
        primaryFontColorScheme: data?.primaryFontColorScheme ?? '#000000',
        secondaryFontSize: data?.secondaryFontSize ?? '16px',
        secondaryFontColorScheme: data?.secondaryFontColorScheme ?? '#000000',
        photo: { name: data?.backgroundImageOriginalName },
      })
    }

    setOpen(false)

    if (data) {
      await getCustomization()
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (changed && selectedValues.theme === 'Boost Basic') {
      setSelectedValues((prev) => ({
        ...prev,
        photo: '',
        backgroundImageURL: '',
        backgroundColorScheme: '',
        opacity: '100%',
      }))
    }
    if (changed && selectedValues.theme === 'Gradient') {
      setSelectedValues((prev) => ({
        ...prev,
        photo: '',
        backgroundImageURL: '',
        backgroundColorScheme: '#707070',
        opacity: '100%',
      }))
    }
  }, [changed, selectedValues.theme])

  useEffect(() => {
    const getCustomization = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events/event/${currentEvent.id}/event-customization/${currentEvent?.customization?.id}`,
        {
          headers: {
            Authorization: `Bearer ${await user?.getIdToken(true)}`,
          },
        },
      )

      setSelectedValues({
        ...selectedValues,
        headerAlignment: data?.headerAlignment ?? 'Center',
        componentAlignment: data?.componentAlignment ?? 'Horizontal',
        backgroundImageURL: data?.backgroundImageURL ?? '',
        opacity: data?.opacity ?? '100%',
        theme:
          data?.backgroundImageURL?.length >= 0
            ? 'Background Image'
            : data?.backgroundColorScheme?.length > 0
            ? 'Gradient'
            : 'Boost Basic' ?? '',
        elementColorScheme: data?.elementColorScheme ?? '#1D4842',
        logoURL: data?.logoURL ?? '',
        backgroundColorScheme: data?.backgroundColorScheme,
        primaryFontSize: data?.primaryFontSize ?? '24px',
        primaryFontColorScheme: data?.primaryFontColorScheme ?? '#000000',
        secondaryFontSize: data?.secondaryFontSize ?? '16px',
        secondaryFontColorScheme: data?.secondaryFontColorScheme ?? '#000000',
        photo: { name: data?.backgroundImageOriginalName },
      })
    }
    getCustomization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closePopover = useCallback(
    (e: MouseEvent) => {
      if (
        popoverRef.current &&
        popoverOpen &&
        typeof popoverRef.current.contains === 'function' &&
        !popoverRef.current.contains(e.target as HTMLElement)
      ) {
        setPopoverOpen(false)
      }
    },
    [popoverOpen],
  )

  const closeHeaderPopover = (e: MouseEvent) => {
    if (
      headerPopoverRef.current &&
      typeof headerPopoverRef.current.contains === 'function' &&
      !headerPopoverRef.current.contains(e.target as HTMLElement)
    ) {
      setHeaderPopoverOpen(false)
    }
  }

  const handleRemoveImage = () => {
    setIsUploaded(false)
    setPopoverOpen(false)
    setSelectedValues((prev) => ({
      ...prev,
      photo: '',
      backgroundImageURL: '',
      theme: 'Background Image',
    }))
  }

  const handleRemoveLogoImage = () => {
    setHeaderPopoverOpen(false)
    setSelectedValues((prev) => ({
      ...prev,
      logo: '',
      logoURL: '',
    }))
  }

  const mapper = () => {
    if (isUploaded) {
      return (
        <div className='loadingdiv'>
          <CustomizationProgress ready={isUploaded} />
          <Tooltip title={selectedValues?.photo?.name}>
            <p>{selectedValues?.photo?.name}</p>
          </Tooltip>
          <button onClick={() => setPopoverOpen((prev) => !prev)}>
            <img src={dots} alt='' />
          </button>
        </div>
      )
    }

    if (
      (selectedValues?.theme === 'Background Image' &&
        selectedValues?.photo?.name?.length <= 0 &&
        selectedValues?.backgroundImageURL?.length <= 0) ||
      (selectedValues?.theme === 'Background Image' && selectedValues?.photo?.name === null) ||
      (selectedValues?.theme === 'Background Image' && selectedValues?.photo === '')
    ) {
      return (
        <>
          <Box sx={{ marginBottom: '20px' }}>
            <CustomImageUpload
              setSelectedValues={setSelectedValues}
              selectedValues={selectedValues}
              setIsUploaded={setIsUploaded}
              title={'Background Image:'}
            />
          </Box>
          <CustomSelect setSelectedValues={setSelectedValues} selectedValues={selectedValues} />
        </>
      )
    }

    if (selectedValues?.backgroundImageURL?.length > 0) {
      return (
        <>
          <p style={{ fontSize: '14px', color: '#000000', marginTop: '15px' }}>Background Image:</p>
          <div className='image_container'>
            <img
              style={{ width: '100%', height: 'auto' }}
              src={selectedValues?.backgroundImageURL}
              alt=''
            />
            <button onClick={() => setPopoverOpen((prev) => !prev)}>
              <img src={dots} alt='' />
            </button>
            {popoverOpen && (
              <div className='popover' ref={popoverRef} onClick={() => handleRemoveImage()}>
                <DeleteOutlineIcon fontSize='medium' sx={{ marginLeft: '16px' }} color='error' />
                <p>Delete Image</p>
              </div>
            )}
          </div>
          <CustomSelect setSelectedValues={setSelectedValues} selectedValues={selectedValues} />
        </>
      )
    }
    if (selectedValues?.photo?.name) {
      return (
        <>
          <p style={{ fontSize: '14px', color: '#000000', marginTop: '15px' }}>Background Image:</p>
          <div className='image_container'>
            <img
              style={{ width: '100%', height: 'auto' }}
              src={URL.createObjectURL(new Blob(binaryData, { type: 'application/zip' }))}
              alt=''
            />
            <button onClick={() => setPopoverOpen((prev) => !prev)}>
              <img src={dots} alt='' />
            </button>
            {popoverOpen && (
              <div className='popover' ref={popoverRef} onClick={() => handleRemoveImage()}>
                <DeleteOutlineIcon fontSize='medium' sx={{ marginLeft: '16px' }} color='error' />
                <p>Delete Image</p>
              </div>
            )}
          </div>
          <CustomSelect setSelectedValues={setSelectedValues} selectedValues={selectedValues} />
        </>
      )
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', closePopover)
    document.addEventListener('mousedown', closeHeaderPopover)

    return () => {
      document.removeEventListener('mousedown', closePopover)
      document.removeEventListener('mousedown', closeHeaderPopover)
    }
  }, [closePopover])

  return (
    <Dialog
      fullScreen
      sx={{ width: '100%', height: '100vh', padding: 0 }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='responsive-dialog-title'
    >
      {isLoading ? (
        <Grid
          container
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}
        >
          <ClipLoader color='#1D4842' size={40} speedMultiplier={1.2} />
        </Grid>
      ) : (
        <DialogContent sx={{ width: '100%', height: '100%', padding: 0 }}>
          <div className='dialog'>
            <div className='dialog_left'>
              <p className='dialog_left_title'>Editor</p>
              <Divider />
              <Grid item sx={{ padding: '20px 30px' }}>
                <ButtonComponent
                  sx={{
                    color: 'white',
                    backgroundColor: 'black',
                    height: '40px',
                    variant: 'contained',
                    width: '100%',
                    '&:hover': {
                      backgroundColor: 'black',
                      opacity: 0.7,
                      transition: '0.3s all',
                    },
                  }}
                  onClick={handleSave}
                >
                  SAVE & CLOSE
                </ButtonComponent>
              </Grid>

              <Divider />
              <CustomAccordion
                text={'Template'}
                panel='panel1'
                isOpen={isOpen}
                handleOpen={handleOpen}
                mainImage={room}
                arrow={arrowdown}
              >
                <Grid item sx={{ width: '100%' }}>
                  <DropdownSelect
                    title={'Header Alignment:'}
                    values={['Left', 'Center', 'Right']}
                    selectedValues={selectedValues}
                    setSelectedValues={setSelectedValues}
                    setChanged={setChanged}
                  />
                </Grid>

                <DropdownSelect
                  title={'Theme:'}
                  values={['Boost Basic', 'Gradient', 'Background Image']}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  setChanged={setChanged}
                />

                {mapper()}

                <DropdownSelect
                  title={'Component Alignment:'}
                  values={['Horizontal', 'Vertical']}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  setChanged={setChanged}
                />
              </CustomAccordion>
              <Divider></Divider>
              <CustomAccordion
                text={'Branding'}
                panel='panel2'
                isOpen={isOpen}
                handleOpen={handleOpen}
                mainImage={room}
                arrow={arrowdown}
              >
                <CustomColorPicker
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  title={'Element Color Scheme:'}
                />

                <div className='line2' style={{ marginTop: '15px' }}></div>

                {selectedValues?.logoURL?.length <= 0 && selectedValues?.logo?.length <= 0 && (
                  <Box sx={{ marginTop: '20px' }}>
                    <CustomImageUpload
                      setSelectedValues={setSelectedValues}
                      selectedValues={selectedValues}
                      setIsUploaded={setIsUploaded}
                      title={'Header Logo:'}
                    />
                  </Box>
                )}

                {selectedValues?.logoURL?.length > 0 ? (
                  <>
                    <p style={{ fontSize: '14px', color: '#000000', marginTop: '25px' }}>
                      Header Logo:
                    </p>
                    <Grid item sx={{ width: '100%' }}>
                      <div className='image_container'>
                        <img
                          style={{ width: '100%', height: 'auto' }}
                          src={selectedValues?.logoURL}
                          alt=''
                        />
                        <button onClick={() => setHeaderPopoverOpen((prev) => !prev)}>
                          <img src={dots} alt='' />
                        </button>
                        {headerPopoverOpen && (
                          <div
                            className='popover'
                            ref={headerPopoverRef}
                            onClick={() => handleRemoveLogoImage()}
                          >
                            <DeleteOutlineIcon
                              fontSize='medium'
                              sx={{ marginLeft: '16px' }}
                              color='error'
                            />
                            <p>Delete Image</p>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </>
                ) : (
                  selectedValues?.logo?.name && (
                    <>
                      <p
                        style={{
                          fontSize: '14px',
                          color: '#000000',
                          marginTop: '25px',
                        }}
                      >
                        Header Logo:
                      </p>
                      <div className='image_container'>
                        <img
                          style={{ width: '100%', height: 'auto' }}
                          src={URL.createObjectURL(
                            new Blob(logoBinaryData, { type: 'application/zip' }),
                          )}
                          alt=''
                        />
                        <button onClick={() => setHeaderPopoverOpen((prev) => !prev)}>
                          <img src={dots} alt='' />
                        </button>
                        {headerPopoverOpen && (
                          <div
                            className='popover'
                            ref={headerPopoverRef}
                            onClick={() => handleRemoveLogoImage()}
                          >
                            <DeleteOutlineIcon
                              fontSize='medium'
                              sx={{ marginLeft: '16px' }}
                              color='error'
                            />
                            <p>Delete Image</p>
                          </div>
                        )}
                      </div>
                    </>
                  )
                )}

                <div className='line2' style={{ marginTop: '15px' }}></div>
                {selectedValues?.theme === 'Gradient' && (
                  <>
                    <Box sx={{ marginTop: '20px' }}>
                      <CustomColorPicker
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                        title={'Background Color Scheme:'}
                      />
                    </Box>
                    <div className='line2' style={{ marginTop: '15px' }}></div>
                  </>
                )}

                <DropdownSelect
                  title={'Primary Font Size:'}
                  values={['13px', '16px', '24px']}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  setChanged={setChanged}
                />

                <CustomColorPicker
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  title={'Primary Font Color Scheme:'}
                />

                <DropdownSelect
                  title={'Secondary Font Size:'}
                  values={['13px', '16px', '24px']}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  setChanged={setChanged}
                />
                <CustomColorPicker
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  title={'Secondary Font Color Scheme:'}
                />
              </CustomAccordion>
              <div className='line2'></div>
            </div>

            <div className='dialog_right'>
              <SharePage
                headerAlignment={selectedValues?.headerAlignment}
                componentAlignment={selectedValues?.componentAlignment}
                photo={selectedValues?.photo}
                opacity={selectedValues?.opacity}
                backgroundImageURL={selectedValues?.backgroundImageURL}
                logo={selectedValues?.logo}
                logoURL={selectedValues?.logoURL}
                elementColorScheme={selectedValues?.elementColorScheme}
                backgroundColorScheme={selectedValues?.backgroundColorScheme}
                primaryFontSize={selectedValues?.primaryFontSize}
                primaryFontColorScheme={selectedValues?.primaryFontColorScheme}
                secondaryFontSize={selectedValues?.secondaryFontSize}
                secondaryFontColorScheme={selectedValues?.secondaryFontColorScheme}
              />
            </div>
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default CustomizeModal
