import { ImageLazyLoader } from '../../../components/image-lazy-loader/image-lazy-loader';

import { navbarAvatarDropdownStyles, navbarDropdownButtonUseStyles } from './navbar-avatar-dropdown.styles';

import { PrimaryDropdownVariant } from '../../../components/primary-dropdown/primary-dropdown-types';
import { DropDownItemVariant, NavbarDropdownItem } from '../navbar-dropdown-item/navbar-dropdown-item';

import { Box, Stack, Typography } from '@mui/material';
import { ShowcaseAuthenticatedRoutePath } from '../../../constants/route-constants';
import { UserType } from '../../../types/user-types';
import { ProfileIcon } from '../../../icons/profile-icon';
import { SettingsIcon } from '../../../icons/settings-icon';
import { DollarIcon } from '../../../icons/dollar-icon';
import { SpeakerIcon } from '../../../icons/speaker-icon';
import { ExternalLinkIcon } from '../../../icons/external-link-icon';
import { LogoutIcon } from '../../../icons/logout-icon';
import { DropdownItem } from '../../../components/dropdown-item/dropdown-item';
import { PrimaryDropdown } from '../../../components/primary-dropdown/primary-dropdown';
import { Products } from '../../../constants/products-constants';

interface AvatarProps {
  user: UserType;
}

const AvatarPicture = ({ user }: AvatarProps) => {
  const styles = navbarAvatarDropdownStyles();

  if (user?.photoURL) {
    return (
      <Box sx={{ flexShrink: 0 }} className="profileImageBox">
        <ImageLazyLoader width={40} height={40} src={user.photoURL} alt={user?.displayName || ''} />
      </Box>
    );
  }

  const userNameFirstLetter = user?.displayName && user.displayName[0].toUpperCase();

  return (
    <Stack
      className="profileImageBox"
      direction={'row'}
      alignItems="center"
      justifyContent="center"
      sx={styles.avatarContainer}
    >
      <span>{userNameFirstLetter}</span>
    </Stack>
  );
};

const Profile = ({ productVariant }: { productVariant?: Products }) => {
  return (
    <NavbarDropdownItem
      variant={productVariant === Products.Boost ? DropDownItemVariant.Text : DropDownItemVariant.Success}
      icon={<ProfileIcon />}
      header={'Profile'}
      description={'Update your personal details.'}
    />
  );
};

const Settings = ({ productVariant }: { productVariant?: Products }) => {
  return (
    <NavbarDropdownItem
      variant={productVariant === Products.Boost ? DropDownItemVariant.Text : DropDownItemVariant.Success}
      icon={<SettingsIcon />}
      header={'Settings'}
      description={'Manage your account options.'}
    />
  );
};

const Referrals = ({ productVariant }: { productVariant?: Products }) => {
  return (
    <DropdownItem
      variant={productVariant === Products.Boost ? DropDownItemVariant.Text : DropDownItemVariant.Success}
      hasSeparator
    >
      <Stack direction={'row'} style={{ gap: '0 16px' }} alignItems="center">
        <DollarIcon />
        <span>Demio Fan Club Referrals</span>
      </Stack>
    </DropdownItem>
  );
};

const ProductUpdates = ({ productVariant }: { productVariant?: Products }) => {
  return (
    <DropdownItem variant={productVariant === Products.Boost ? DropDownItemVariant.Text : DropDownItemVariant.Success}>
      <Stack direction={'row'} style={{ gap: '0 16px' }} alignItems="center">
        <SpeakerIcon />
        <span>Product Updates</span>

        <span style={{ marginLeft: '4px' }}>
          <ExternalLinkIcon />
        </span>
      </Stack>
    </DropdownItem>
  );
};

const Logout = () => {
  return (
    <DropdownItem hasSeparator variant={DropDownItemVariant.Danger}>
      <Stack direction={'row'} alignItems="center" sx={{ gap: '0 16px' }}>
        <LogoutIcon />
        <span>Logout</span>
      </Stack>
    </DropdownItem>
  );
};

const getNavbarDropdownOptions = ({ demioUrl, productVariant }: { demioUrl: string; productVariant: Products }) => [
  {
    id: 0,
    link: `${demioUrl}/manage/settings/profile`,
    content: <Profile productVariant={productVariant} />,
    variant: PrimaryDropdownVariant.Link,
  },
  {
    id: 1,
    link: `${demioUrl}/manage/settings/general`,
    content: <Settings productVariant={productVariant} />,
    variant: PrimaryDropdownVariant.Link,
  },
  {
    id: 2,
    link: `${demioUrl}/manage/referral-program`,
    content: <Referrals productVariant={productVariant} />,
    variant: PrimaryDropdownVariant.Link,
  },
  {
    id: 3,
    link: 'https://updates.demio.com/',
    content: <ProductUpdates productVariant={productVariant} />,
    variant: PrimaryDropdownVariant.Link,
  },
  {
    id: 4,
    link: ShowcaseAuthenticatedRoutePath.Logout(),
    content: <Logout />,
    variant: PrimaryDropdownVariant.Link,
  },
];

const NavbarAvatarDropdownButtonContent = ({ user }: { user: UserType }) => {
  const styles = navbarDropdownButtonUseStyles();

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={styles.contentWrapper}>
      <AvatarPicture user={user} />

      <Stack direction="column" alignItems="start" justifyContent="flex-start">
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '120px' }}>
          <Typography component="p" sx={styles.username} className="userName">
            {user?.displayName}
          </Typography>
        </div>

        <Typography component="span" sx={styles.companyName} className="companyName">
          {user?.claims?.companyName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export function NavbarAvatarDropdown({
  user,
  demioUrl,
  productVariant,
}: {
  user: UserType;
  demioUrl: string;
  productVariant: Products;
}) {
  return (
    <PrimaryDropdown
      buttonContent={<NavbarAvatarDropdownButtonContent user={user} />}
      variant={PrimaryDropdownVariant.Link}
      contentTopSpace={92}
      wrapperSx={{ width: '280px' }}
      bottomLineDistance={20}
      options={getNavbarDropdownOptions({ demioUrl, productVariant })}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    />
  );
}
