import './Footer.scss'

const Footer = () => {
  return (
    <footer className='footer'>
      <ul>
        <li onClick={() => window.open('https://www.demio.com/terms-and-conditions')}>
          Terms and Conditions
        </li>
      </ul>
    </footer>
  )
}

export default Footer
