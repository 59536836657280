import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import iconmodal from 'assets/images/iconmodal.svg'

interface MissingFieldsModalProps {
  open: boolean
  onClose: () => void
}

const MissingFieldsModal: React.FC<MissingFieldsModalProps> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 480,
          height: 184,
          padding: '50px',
          bgcolor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}
      >
        <div className='flex_start'>
          <img src={iconmodal} alt='iconmodal' />
          <p
            style={{
              marginLeft: '20px',
              color: '#2C3336',
              fontSize: '16px',
              fontWeight: '400',
              fontFamily: 'Lato, sans-serif',
            }}
          >
            Missing required fields
          </p>
        </div>

        <Typography
          id='modal-modal-description'
          sx={{
            marginLeft: '45px',
            color: '#2C3336',
            fontSize: '14px',
            marginTop: '8px',
            fontWeight: '400',
            fontFamily: 'Lato, sans-serif',
          }}
        >
          There are missed required fields in active social platforms. Please check and try again.
        </Typography>

        <div className='flex_end' style={{ marginTop: '25px' }}>
          <button className='cancel' onClick={onClose}>
            Cancel
          </button>

          <button className='confirm' onClick={onClose} style={{ margin: '0px 10px' }}>
            Confirm
          </button>
        </div>
      </Box>
    </Modal>
  )
}

export default MissingFieldsModal
