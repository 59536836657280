import disabledfacebook from 'assets/images/disabledfacebook.svg'
import disabledgmail from 'assets/images/disabledgmail.svg'
import disabledlinkedin from 'assets/images/disabledlinkedin.svg'
import disabledtwitter from 'assets/images/disabledtwitter.svg'
import disclamericon from 'assets/images/disclamer_icon.svg'
import emptyshare from 'assets/images/empty_share.svg'
import facebookshow from 'assets/images/facebookshow.svg'
import fingerdown from 'assets/images/finger_down.svg'
import gmailshow from 'assets/images/gmailicon.svg'
import linkedinshow from 'assets/images/linkedinshow.svg'
import twittershow from 'assets/images/twittershow.svg'
import axios from 'axios'
import Reward from 'components/Reward/Reward'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import bg from '../../assets/images/bgshare.svg'
import './Share.scss'

interface SharePageProps {
  headerAlignment: string
  componentAlignment: string
  photo: any
  opacity: string
  backgroundImageURL: string
  logo: any
  logoURL: string
  elementColorScheme: string
  backgroundColorScheme: string
  primaryFontSize: string
  primaryFontColorScheme: string
  secondaryFontSize: string
  secondaryFontColorScheme: string
}

const gmailInitialState = {
  content: '',
  photo: '',
  subject: '',
  title: '',
  isActive: true,
}
const socialInitialState = { content: '', photo: '', title: '', isActive: true }
const BACKEND_API = process.env.REACT_APP_BACKEND_API

const SharePage: React.FC<SharePageProps> = ({
  headerAlignment,
  componentAlignment,
  photo,
  opacity,
  backgroundImageURL,
  logo,
  logoURL,
  elementColorScheme,
  backgroundColorScheme,
  primaryFontSize,
  primaryFontColorScheme,
  secondaryFontSize,
  secondaryFontColorScheme,
}) => {
  const [rewards, setRewards] = useState<Array<RewardType>>([])

  const [linkedin, setLinkedin] = useState(socialInitialState)
  const [gmail, setGmail] = useState(gmailInitialState)
  const [twitter, setTwitter] = useState(socialInitialState)
  const [facebook, setFacebook] = useState(socialInitialState)

  const [eventForPreview, setEventForPreview] = useState({
    name: '',
    logoPath: '',
    archived: false,
  })
  const [event, setEvent] = useState<SharePageEventType>()
  const [upcomingReward, setUpcomingReward] = useState<RewardType>()

  const [currentReward, setCurrentReward] = useState<RewardType | null>()
    const [searchParams, _] = useSearchParams() //eslint-disable-line

  const token = searchParams.get('boost_unique_code')
  const { id } = useParams()

  useEffect(() => {
    const fetchByToken = async () => {
      const { data } = await axios.get(`${BACKEND_API}/events/event-details-token/${token}`)

      setEvent(data)
      setRewards(data.rewards)

      setLinkedin({
        ...linkedin,
        content: data.linkedInShareTemplate?.content,
        photo: data.linkedInShareTemplate?.imageURL,
        title: data.linkedInShareTemplate?.title,
        isActive: data?.linkedInShareTemplate?.isActive,
      })
      setFacebook({
        ...facebook,
        content: data.facebookShareTemplate?.content,
        photo: data.facebookShareTemplate?.imageURL,
        title: data.facebookShareTemplate?.title,
        isActive: data.facebookShareTemplate?.isActive,
      })

      setTwitter({
        ...twitter,
        content: data.twitterShareTemplate?.content,
        photo: data.twitterShareTemplate?.imageURL,
        title: data.twitterShareTemplate?.title,
        isActive: data.twitterShareTemplate?.isActive,
      })

      setGmail({
        ...gmail,
        content: data.gmailShareTemplate?.content,
        photo: data.gmailShareTemplate?.imageURL,
        subject: data.gmailShareTemplate?.subject,
        title: data.gmailShareTemplate?.title,
        isActive: data.gmailShareTemplate?.isActive,
      })
    }

    const fetchReward = async () => {
      const { data } = await axios.get(`${BACKEND_API}/events/event/${id}/rewards`)
      setRewards(data.result)
    }

    const fetchSingleEvent = async () => {
      const { data } = await axios.get(`${BACKEND_API}/events/event/${id}`)

      setEventForPreview({
        ...eventForPreview,
        name: data?.name,
        logoPath: data?.logoPath,
        archived: data?.archived,
      })
    }

    const fetchLinkedin = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events/event/${id}/socials/linked-in-share-templates`,
      )

      setLinkedin({
        ...linkedin,
        content: data?.result[0]?.content,
        photo: data?.result[0]?.imageURL,
        title: data?.result[0]?.title,
        isActive: data?.result[0]?.isActive,
      })
    }
    const fetchFacebook = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events/event/${id}/socials/facebook-share-templates`,
      )

      if (data) {
        setFacebook({
          ...facebook,
          title: data?.result[0]?.title,
          content: data?.result[0]?.content,
          photo: data?.result[0]?.imageURL,
          isActive: data?.result[0]?.isActive,
        })
      }
    }
    const fetchTwitter = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events/event/${id}/socials/twitter-share-templates`,
      )

      setTwitter({
        ...twitter,
        title: data?.result[0]?.title,
        content: data?.result[0]?.content,
        isActive: data?.result[0]?.isActive,
        photo: data?.result[0]?.imageURL,
      })
    }
    const fetchGmail = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events/event/${id}/socials/gmail-share-templates`,
      )

      setGmail({
        ...gmail,
        title: data?.result[0]?.title,
        content: data?.result[0]?.content,
        photo: data?.result[0]?.imageURL,
        subject: data?.result[0]?.subject,
        isActive: data?.result[0]?.isActive,
      })
    }
    if (token) {
      fetchByToken()
    } else {
      fetchFacebook()
      fetchTwitter()
      fetchGmail()
      fetchLinkedin()
      fetchReward()
      fetchSingleEvent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let newCurrentReward = null
    for (const reward of rewards) {
      if (
        (reward?.points || 0) > (newCurrentReward?.points || 0) &&
        (event?.__eventToPromoter__[0].referralsCount || 0) >= (reward?.points || 0)
      ) {
        newCurrentReward = reward
      }
    }
    setCurrentReward(newCurrentReward)
    let upcomingReward = undefined
    for (const reward of rewards) {
      if (upcomingReward === undefined) {
        upcomingReward = reward
      } else if (
        (upcomingReward.points || 0) <= (event?.__eventToPromoter__[0].referralsCount || 0) &&
        (reward.points || 0) > (upcomingReward.points || 0)
      ) {
        upcomingReward = reward
      } else if (
        (upcomingReward.points || 0) > (event?.__eventToPromoter__[0].referralsCount || 0) &&
        (reward.points || 0) < (upcomingReward.points || 0) &&
        (reward.points || 0) > (event?.__eventToPromoter__[0].referralsCount || 0)
      ) {
        upcomingReward = reward
      }
    }

    setUpcomingReward(upcomingReward)
  }, [event?.__eventToPromoter__, rewards])

  const greyedOut = rewards.filter(
    (item) =>
      (100 * (event?.__eventToPromoter__[0].referralsCount || 0)) / (item.points || 0) > 100 &&
      currentReward?.id !== item.id,
  )
  const notGreyedOut = rewards.filter(
    (item) =>
      (100 * (event?.__eventToPromoter__[0].referralsCount || 0)) / (item.points || 0) <= 100 ||
      currentReward?.id === item.id,
  )
  const greyedOutAsc = [...greyedOut].sort((a, b) => ((a.points || 0) < (b.points || 0) ? -1 : 1))
  const notGreyedOutAsc = [...notGreyedOut].sort((a, b) =>
    (a.points || 0) < (b.points || 0) ? -1 : 1,
  )

  const socialPhoto = () => {
    if (linkedin?.photo) {
      return linkedin?.photo
    } else if (facebook?.photo) {
      return facebook?.photo
    } else if (gmail?.photo) {
      return gmail?.photo
    } else if (twitter?.photo) {
      return twitter?.photo
    } else return ' '
  }

  const binaryData: any = []
  const headerBinaryData: any = []

  if (logo) {
    headerBinaryData.push(logo)
  }

  if (photo) {
    binaryData.push(photo)
  }

  const [width, setWidth] = useState<number>(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  return (
    <>
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1 maximum-scale=1.0 user-scalable=no'
        />
      </Helmet>

      <div className='share_wrapper_overlay'>
        {backgroundImageURL?.length > 0 ? (
          <div
            className='background'
            style={{
              opacity:
                opacity === '100%'
                  ? '100%'
                  : opacity === '50%'
                  ? '50%'
                  : opacity === '20%'
                  ? '20%'
                  : '',
            }}
          >
            <img src={backgroundImageURL} alt='' />
          </div>
        ) : photo.name?.length > 0 ? (
          <div
            className='background'
            style={{
              opacity:
                opacity === '100%'
                  ? '100%'
                  : opacity === '50%'
                  ? '50%'
                  : opacity === '20%'
                  ? '20%'
                  : '',
            }}
          >
            <img
              src={URL.createObjectURL(new Blob(binaryData, { type: 'application/zip' }))}
              alt=''
            />
          </div>
        ) : backgroundColorScheme?.length > 0 ? (
          <div
            className='background'
            style={{
              backgroundColor: backgroundColorScheme,
            }}
          ></div>
        ) : (
          <div
            className='background'
            style={{
              opacity:
                opacity === '100%'
                  ? '100%'
                  : opacity === '50%'
                  ? '50%'
                  : opacity === '20%'
                  ? '20%'
                  : '',
            }}
          >
            <img src={bg} alt='' />
          </div>
        )}

        <div className={rewards?.length > 0 ? 'share_wrapper' : 'share_wrapper empty_wrapper'}>
          <div
            className='block'
            style={{
              padding: isMobile ? '0px' : '50px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: isMobile
                ? '90%'
                : componentAlignment === 'Horizontal' && rewards?.length > 0
                ? '80%'
                : '60%',
              minWidth: isMobile ? '' : '910px',
            }}
          >
            <div
              className='share_logo'
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: '16px',
                alignItems: isMobile
                  ? 'center'
                  : headerAlignment === 'Center'
                  ? 'center'
                  : headerAlignment === 'Left'
                  ? 'flex-start'
                  : headerAlignment === 'Right'
                  ? 'flex-end'
                  : isMobile && componentAlignment === 'Vertical'
                  ? 'Center'
                  : 'Center',
              }}
            >
              {logo?.name?.length > 0 ? (
                <img
                  src={URL.createObjectURL(new Blob(headerBinaryData, { type: 'application/zip' }))}
                  alt=''
                />
              ) : logoURL.length > 0 ? (
                <img src={logoURL} alt='' />
              ) : (
                ''
              )}
              <p
                title={event?.name ? event.name : eventForPreview.name}
                className='event_name_share'
                style={{
                  fontSize: isMobile ? '20px' : primaryFontSize,
                  color: primaryFontColorScheme,
                  marginTop: '20px',
                }}
              >
                {event?.name ? event.name : eventForPreview.name}
              </p>
            </div>

            <div
              className={'banner'}
              style={{
                display: 'flex',
                marginTop: '30px',
                justifyContent: 'center',
                alignItems: rewards?.length > 0 ? 'center' : 'flex-start',
                flexDirection: isMobile
                  ? 'column'
                  : componentAlignment === 'Horizontal' && rewards?.length > 0
                  ? 'row'
                  : componentAlignment === 'Vertical' && rewards?.length <= 0
                  ? 'column-reverse'
                  : componentAlignment === 'Horizontal' && rewards.length <= 0
                  ? 'column-reverse'
                  : componentAlignment === 'Vertical'
                  ? 'column'
                  : isMobile
                  ? 'column'
                  : 'row',
              }}
            >
              {eventForPreview.archived || event?.archived ? (
                <div className='emptystate'>
                  <img style={{ width: '436px', height: '193px' }} src={emptyshare} alt='' />
                  <p>Event expired</p>
                  <span>The event is no longer available.</span>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      marginTop:
                        componentAlignment === 'Vertical' && rewards?.length > 0 ? '20px' : '0px',
                      minWidth: isMobile ? '290px' : '452px',
                    }}
                    className={
                      (linkedin?.photo && linkedin.isActive) ||
                      (facebook?.photo && facebook.isActive) ||
                      (twitter?.photo && twitter.isActive) ||
                      (gmail?.photo && gmail.isActive)
                        ? 'banner_left isphoto'
                        : 'banner_left'
                    }
                  >
                    {(linkedin?.photo && linkedin.isActive) ||
                    (facebook?.photo && facebook.isActive) ||
                    (twitter?.photo && twitter.isActive) ||
                    (gmail?.photo && gmail.isActive) ? (
                      <img src={socialPhoto()} alt='' />
                    ) : (
                      ''
                    )}
                    <p
                      className='banner_left_invite'
                      style={{
                        fontSize: isMobile ? '24px' : primaryFontSize,
                        color: primaryFontColorScheme,
                      }}
                    >
                      Share this event!
                    </p>
                    <p
                      className='banner_left_choose'
                      style={{
                        fontSize: isMobile ? '14px' : secondaryFontSize,
                        color: secondaryFontColorScheme,
                      }}
                    >
                      Choose where to share with <br /> your community
                      <img style={{ width: '24px', height: '20px' }} src={fingerdown} alt='' />
                    </p>
                    <div className='banner_left_buttons'>
                      {linkedin.isActive === true ? (
                        <LinkedinShareButton
                          style={{
                            width: '48px',
                            height: '48PX',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
                            color: 'white',
                            borderRadius: '30px',
                            marginRight: '15px',
                          }}
                          disabledStyle={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#F0F2F4',
                            color: '#9FA6AD',
                            border: '1px solid #DEE4E8',
                            borderRadius: '30px',
                            cursor: 'inherit',
                            fontWeight: '700',
                            opacity: '1',
                          }}
                          className={token ? 'linkedin-share' : 'nohover'}
                          disabled={Boolean(id)}
                          url={`${BACKEND_API}/events/event/${event?.id}/socials/linkedin-share-templates/linkedin-share-template/${event?.linkedInShareTemplate?.id}/page?token=boost_${token}_linkedin`}
                        >
                          <img
                            style={{ width: '28px', height: '28px' }}
                            src={token ? linkedinshow : disabledlinkedin}
                            alt='linkedinshare'
                          />
                        </LinkedinShareButton>
                      ) : (
                        ''
                      )}
                      {facebook?.isActive === true && (
                        <FacebookShareButton
                          style={{
                            width: '48px',
                            height: '48PX',
                            display: 'flex',
                            marginRight: '15px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
                            color: 'white',
                            borderRadius: '30px',
                          }}
                          disabledStyle={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#F0F2F4',
                            color: '#9FA6AD',
                            border: '1px solid #DEE4E8',
                            borderRadius: '30px',
                            cursor: 'inherit',
                            fontWeight: '700',
                            opacity: '1',
                          }}
                          className={token ? 'facebook-share' : 'nohover'}
                          disabled={Boolean(id)}
                          url={`${BACKEND_API}/events/event/${event?.id}/socials/facebook-share-templates/facebook-share-template/${event?.facebookShareTemplate?.id}/page?token=boost_${token}_facebook`}
                        >
                          <img
                            style={{ width: '28px', height: '28px' }}
                            src={token ? facebookshow : disabledfacebook}
                            alt='facebookshare'
                          />
                        </FacebookShareButton>
                      )}
                      {gmail?.isActive === true && (
                        <EmailShareButton
                          body={`${event?.gmailShareTemplate?.content ?? ''} \n\n`}
                          subject={event?.gmailShareTemplate?.subject ?? ''}
                          style={{
                            width: '48px',
                            height: '48PX',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '15px',
                            justifyContent: 'center',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
                            color: 'white',
                            borderRadius: '30px',
                          }}
                          disabledStyle={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#F0F2F4',
                            color: '#9FA6AD',
                            border: '1px solid #DEE4E8',
                            borderRadius: '30px',
                            cursor: 'inherit',
                            fontWeight: '700',
                            opacity: '1',
                          }}
                          className={token ? 'email-share' : 'nohover'}
                          disabled={Boolean(id)}
                          url={`${BACKEND_API}/events/event/${event?.id}/socials/gmail-share-templates/gmail-share-template/${event?.gmailShareTemplate?.id}/page?token=boost_${token}_email`}
                        >
                          <img
                            style={{ width: '28px', height: '28px' }}
                            src={token ? gmailshow : disabledgmail}
                            alt='emailshare'
                          />
                        </EmailShareButton>
                      )}
                      {twitter?.isActive === true && (
                        <TwitterShareButton
                          style={{
                            width: '48px',
                            height: '48PX',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '15px',
                            justifyContent: 'center',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
                            color: 'white',
                            borderRadius: '30px',
                          }}
                          disabledStyle={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#F0F2F4',
                            color: '#9FA6AD',
                            border: '1px solid #DEE4E8',
                            borderRadius: '30px',
                            cursor: 'inherit',
                            fontWeight: '700',
                            opacity: '1',
                          }}
                          className={token ? 'twitter-share' : 'nohover'}
                          disabled={Boolean(id)}
                          url={`${BACKEND_API}/events/event/${event?.id}/socials/twitter-share-templates/twitter-share-template/${event?.twitterShareTemplate?.id}/page?token=boost_${token}_twitter`}
                        >
                          <img
                            style={{ width: '28px', height: '28px' }}
                            src={token ? twittershow : disabledtwitter}
                            alt='linkedinshare'
                          />
                        </TwitterShareButton>
                      )}
                    </div>
                  </div>
                  <div
                    className='banner_right'
                    style={{
                      height: rewards.length > 0 ? '614px' : '106px',
                      overflowY: isMobile ? 'visible' : 'auto',
                      minWidth: isMobile ? '290px' : '452px',
                      marginTop:
                        componentAlignment === 'Vertical' && rewards?.length > 0
                          ? '20px'
                          : rewards?.length > 0 && componentAlignment === 'Horizontal' && isMobile
                          ? '20px'
                          : rewards?.length <= 0 && isMobile
                          ? '20px'
                          : '0px',
                      marginBottom: rewards?.length <= 0 ? '20px' : 0,
                      marginLeft:
                        componentAlignment === 'Horizontal' && rewards.length > 0 && !isMobile
                          ? '20px'
                          : '0px',
                    }}
                  >
                    <div className='banner_right_score'>
                      <div className='banner_right_score_left'>
                        <p
                          className='banner_right_score_left_headline'
                          style={{
                            fontSize: isMobile ? '24px' : primaryFontSize,
                            color: primaryFontColorScheme,
                          }}
                        >
                          Your Score
                        </p>
                        <p
                          className='banner_right_score_left_track'
                          style={{
                            fontSize: isMobile ? '10px' : secondaryFontSize,
                            color: secondaryFontColorScheme,
                          }}
                        >
                          Track your progress here and boost the event&apos;s <br /> success by
                          inviting your network!
                        </p>
                      </div>
                      <div className='banner_right_score_right'>
                        <div className='flower_numbers'>
                          {!rewards.length ? (
                            <div className='emptyrewards'>
                              <p className='flower_numbers'>
                                {`${event?.__eventToPromoter__[0].referralsCount || 0} `}
                              </p>
                              <p
                                style={{
                                  fontSize: '12px',
                                  color: '#22262B',
                                }}
                              >
                                Registrants
                              </p>
                            </div>
                          ) : (
                            <p className='flower_numbers'>
                              {`${event?.__eventToPromoter__[0].referralsCount || 0}/${
                                upcomingReward?.points || 0
                              }`}
                            </p>
                          )}
                        </div>
                        <p className='reward_p_share'>{upcomingReward?.name}</p>
                      </div>
                    </div>
                    {rewards?.length > 0 && (
                      <>
                        <div className='rewardstop'>
                          <p
                            className='rewards'
                            style={{
                              fontSize: isMobile ? '24px' : primaryFontSize,
                              color: primaryFontColorScheme,
                            }}
                          >
                            Rewards
                          </p>
                          <p
                            className='rewards_small'
                            style={{
                              fontSize: isMobile ? '10px' : secondaryFontSize,
                              color: secondaryFontColorScheme,
                            }}
                          >
                            Only the gift corresponding to the highest score you have collected will
                            be awarded.
                          </p>
                        </div>

                        <div className='reward_banner'>
                          <div
                            className='flex_start'
                            style={{
                              marginTop: '10px',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            {notGreyedOutAsc.map((item: RewardType) => {
                              return (
                                <Reward
                                  key={item.id}
                                  description={item.description || ''}
                                  primaryFontSize={primaryFontSize}
                                  isMobile={isMobile}
                                  secondaryFontSize={secondaryFontSize}
                                  primaryFontColorScheme={primaryFontColorScheme}
                                  secondaryFontColorScheme={secondaryFontColorScheme}
                                  imageURL={item.imageURL || ''}
                                  name={item.name || ''}
                                  points={item.points || 0}
                                  elementColorScheme={elementColorScheme ?? ''}
                                  referralsCount={event?.__eventToPromoter__[0].referralsCount || 0}
                                  isCurrentActive={currentReward?.id === item.id}
                                />
                              )
                            })}
                            {greyedOutAsc.map((item: RewardType) => {
                              return (
                                <Reward
                                  key={item.id}
                                  isMobile={isMobile}
                                  description={item.description || ''}
                                  primaryFontColorScheme={primaryFontColorScheme}
                                  secondaryFontColorScheme={secondaryFontColorScheme}
                                  imageURL={item.imageURL || ''}
                                  name={item.name || ''}
                                  secondaryFontSize={secondaryFontSize}
                                  points={item.points || 0}
                                  primaryFontSize={primaryFontSize}
                                  elementColorScheme={elementColorScheme ?? ''}
                                  referralsCount={event?.__eventToPromoter__[0].referralsCount || 0}
                                  isCurrentActive={currentReward?.id === item.id}
                                />
                              )
                            })}
                          </div>
                        </div>

                        <div className='disclamer_div'>
                          <img src={disclamericon} alt='disclamer icon' />
                          <p
                            className='disclamer'
                            style={{
                              color: secondaryFontColorScheme,
                            }}
                          >
                            Banzai and Demio provide the Boost rewards program as a service to event
                            hosts and their users. Your event host is responsible for verifying your
                            eligibility for and providing rewards. If you have questions about your
                            rewards, please contact your event host.
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SharePage
