import axios from 'axios'

const BACKEND_API = process.env.REACT_APP_BACKEND_API

export async function updateLinkedinData(id: string, linkedinData: TemplateType, token: string) {
  if (id) {
    await axios.patch(
      `${BACKEND_API}/events/event/${id}/socials/linked-in-share-templates/linked-in-share-template/${id}`,
      { photo: linkedinData.photo || '' },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    await axios.patch(
      `${BACKEND_API}/events/event/${id}/socials/linked-in-share-templates/linked-in-share-template/${id}`,
      { isActive: linkedinData.isActive, title: linkedinData.title },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  } else {
    await axios.post(
      `${BACKEND_API}/events/event/${id}/socials/linked-in-share-templates/linked-in-share-template`,
      {
        title: linkedinData.title,
        photo: linkedinData.photo,
        isActive: linkedinData.isActive ? null : linkedinData.isActive,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }
}

export async function updateFacebookData(id: string, facebookData: TemplateType, token: string) {
  if (id) {
    await axios.patch(
      `${BACKEND_API}/events/event/${id}/socials/facebook-share-templates/facebook-share-template/${id}`,
      { photo: facebookData.photo || '' },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    await axios.patch(
      `${BACKEND_API}/events/event/${id}/socials/facebook-share-templates/facebook-share-template/${id}`,
      {
        isActive: facebookData.isActive,
        title: facebookData.title,
        content: facebookData.content,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  } else {
    await axios.post(
      `${BACKEND_API}/events/event/${id}/socials/facebook-share-templates/facebook-share-template`,
      {
        content: facebookData.content,
        photo: facebookData.photo,
        title: facebookData.title,
        isActive: facebookData.isActive ? null : facebookData.isActive,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }
}

export async function updateTwitterData(id: string, twitterData: TemplateType, token: string) {
  if (id) {
    await axios.patch(
      `${BACKEND_API}/events/event/${id}/socials/twitter-share-templates/twitter-share-template/${id}`,
      { photo: twitterData.photo || '' },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    await axios.patch(
      `${BACKEND_API}/events/event/${id}/socials/twitter-share-templates/twitter-share-template/${id}`,
      {
        content: twitterData.content,
        title: twitterData.title,
        isActive: twitterData.isActive,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  } else {
    await axios.post(
      `${BACKEND_API}/events/event/${id}/socials/twitter-share-templates/twitter-share-template`,
      {
        content: twitterData.content,
        photo: twitterData.photo,
        title: twitterData.title,
        isActive: twitterData.isActive ? null : twitterData.isActive,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }
}

export async function updateGmailData(id: string, gmailData: GmailTemplateType, token: string) {
  if (id) {
    await axios.patch(
      `${BACKEND_API}/events/event/${id}/socials/gmail-share-templates/gmail-share-template/${id}`,
      { photo: gmailData.photo || '' },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    await axios.patch(
      `${BACKEND_API}/events/event/${id}/socials/gmail-share-templates/gmail-share-template/${id}`,
      {
        subject: gmailData.subject,
        content: gmailData.content,
        title: gmailData.title,
        isActive: gmailData.isActive,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  } else {
    await axios.post(
      `${BACKEND_API}/events/event/${id}/socials/gmail-share-templates/gmail-share-template`,
      {
        subject: gmailData.subject,
        content: gmailData.content,
        photo: gmailData.photo,
        title: gmailData.title,
        isActive: gmailData.isActive ? null : gmailData.isActive,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }
}
