export function EventsIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M19.0623 13.75H8.02051C7.77187 13.75 7.53341 13.8488 7.3576 14.0246C7.18178 14.2004 7.08301 14.4389 7.08301 14.6875V17.8125C7.08301 18.0611 7.18178 18.2996 7.3576 18.4754C7.53341 18.6512 7.77187 18.75 8.02051 18.75H19.0623C19.3109 18.75 19.5494 18.6512 19.7252 18.4754C19.901 18.2996 19.9998 18.0611 19.9998 17.8125V14.6875C19.9998 14.4389 19.901 14.2004 19.7252 14.0246C19.5494 13.8488 19.3109 13.75 19.0623 13.75ZM19.0623 1.25H8.02051C7.77187 1.25 7.53341 1.34877 7.3576 1.52459C7.18178 1.7004 7.08301 1.93886 7.08301 2.1875V5.3125C7.08301 5.56114 7.18178 5.7996 7.3576 5.97541C7.53341 6.15123 7.77187 6.25 8.02051 6.25H19.0623C19.3109 6.25 19.5494 6.15123 19.7252 5.97541C19.901 5.7996 19.9998 5.56114 19.9998 5.3125V2.1875C19.9998 1.93886 19.901 1.7004 19.7252 1.52459C19.5494 1.34877 19.3109 1.25 19.0623 1.25ZM19.0623 7.5H8.02051C7.77187 7.5 7.53341 7.59877 7.3576 7.77459C7.18178 7.9504 7.08301 8.18886 7.08301 8.4375V11.5625C7.08301 11.8111 7.18178 12.0496 7.3576 12.2254C7.53341 12.4012 7.77187 12.5 8.02051 12.5H19.0623C19.3109 12.5 19.5494 12.4012 19.7252 12.2254C19.901 12.0496 19.9998 11.8111 19.9998 11.5625V8.4375C19.9998 8.18886 19.901 7.9504 19.7252 7.77459C19.5494 7.59877 19.3109 7.5 19.0623 7.5Z"
        fill="currentColor"
      />
      <path
        d="M4.8957 7.5H0.9375C0.68886 7.5 0.450403 7.59877 0.274587 7.77459C0.098772 7.9504 0 8.18886 0 8.4375L0 11.5625C0 11.8111 0.098772 12.0496 0.274587 12.2254C0.450403 12.4012 0.68886 12.5 0.9375 12.5H4.8957C5.14434 12.5 5.3828 12.4012 5.55862 12.2254C5.73443 12.0496 5.8332 11.8111 5.8332 11.5625V8.4375C5.8332 8.18886 5.73443 7.9504 5.55862 7.77459C5.3828 7.59877 5.14434 7.5 4.8957 7.5ZM4.8957 1.25H0.9375C0.68886 1.25 0.450403 1.34877 0.274587 1.52459C0.098772 1.7004 0 1.93886 0 2.1875L0 5.3125C0 5.56114 0.098772 5.7996 0.274587 5.97541C0.450403 6.15123 0.68886 6.25 0.9375 6.25H4.8957C5.14434 6.25 5.3828 6.15123 5.55862 5.97541C5.73443 5.7996 5.8332 5.56114 5.8332 5.3125V2.1875C5.8332 1.93886 5.73443 1.7004 5.55862 1.52459C5.3828 1.34877 5.14434 1.25 4.8957 1.25ZM4.8957 13.75H0.9375C0.68886 13.75 0.450403 13.8488 0.274587 14.0246C0.098772 14.2004 0 14.4389 0 14.6875L0 17.8125C0 18.0611 0.098772 18.2996 0.274587 18.4754C0.450403 18.6512 0.68886 18.75 0.9375 18.75H4.8957C5.14434 18.75 5.3828 18.6512 5.55862 18.4754C5.73443 18.2996 5.8332 18.0611 5.8332 17.8125V14.6875C5.8332 14.4389 5.73443 14.2004 5.55862 14.0246C5.3828 13.8488 5.14434 13.75 4.8957 13.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
