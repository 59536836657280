import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

const initialState: UserType = {
  accessToken: '',
}
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
})

export const getUser = (state: RootState) => state.user

export default userSlice.reducer
