import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import eventReducer from './slices/eventSlice'
import linkedinReducer from './slices/templates/linkedinSlice'
import facebookReducer from './slices/templates/facebookSlice'
import twitterReducer from './slices/templates/twitterSlice'
import gmailReducer from './slices/templates/gmailSlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    events: eventReducer,
    linkedin: linkedinReducer,
    facebook: facebookReducer,
    twitter: twitterReducer,
    gmail: gmailReducer,
  },
})

export default store

export type RootState = ReturnType<typeof store.getState>
