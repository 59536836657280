import CustomizeEmail from 'components/CustomizeEmail/CustomizeEmail'
import CustomizeIncentives from 'components/CustomizeIncentives/CustomizeIncentives'
import { useState } from 'react'
import './Customize.scss'
import CustomizeTab from 'components/CustomizeTab/CustomizeTab'
import CustomizeTemplates from 'components/Templates/Templates'

const Customize: React.FC = () => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) =>
    setExpanded(isExpanded ? panel : false)

  return (
    <div className='customize_wrapper'>
      <div className='container'>
        <CustomizeEmail handleChange={handleChange} expanded={expanded} />
        <CustomizeIncentives handleChange={handleChange} expanded={expanded} />
        <CustomizeTab handleChange={handleChange} expanded={expanded} />
        <CustomizeTemplates handleChange={handleChange} expanded={expanded} />
      </div>
    </div>
  )
}

export default Customize
