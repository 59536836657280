export const getExternalLinks = (demioUrl: string) => ({
  Demio: `${demioUrl}/manage/dashboard`,
  Schedule: `${demioUrl}/manage/dashboard`,
  Events: `${demioUrl}/manage/events`,
  DemioSupportMail: 'mailto:support@demio.com',
  DemioLogin: `${demioUrl}/login`,
  BoostPage: `${demioUrl}/manage/banzai-integration/boost`,
  ShowcasePage: `${demioUrl}/manage/banzai-integration/showcase`,
  MainPage: '/',
  BoostMainPage: `/boost`,
});
