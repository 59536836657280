import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { createTheme, ThemeProvider } from '@mui/material'

interface Props {
  referralsCount: number
  points: number
  colors: string
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#fff',
    },
  },
})

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; points: number; colors: string },
) {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          alignSelf: 'flex-start',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginTop: '5px',
            marginBottom: '5px',
          }}
        >
          <Typography variant='body2' color={'#22262B'}>
            {`${props.value.toFixed(2)}%`}
          </Typography>
          <Typography
            variant='body2'
            color={'#22262B'}
            sx={{ fontFamily: 'Reader', fontSize: '13' }}
          >
            {`${props.points} Registrants`}
          </Typography>
        </Box>

        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            variant='determinate'
            style={{ color: '#fff' }}
            sx={{
              padding: '10px',
              borderRadius: '16px',
              backgroundColor: '#EFF3F6',

              '.MuiLinearProgress-bar': {
                backgroundColor: props.colors,
              },
            }}
            {...props}
          />
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const Progress: React.FC<Props> = ({ referralsCount, points, colors }) => {
  let value = 0
  if (referralsCount !== 0) {
    const currentPercents = (100 * referralsCount) / points
    value = currentPercents < 100 ? currentPercents : 100
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <LinearProgressWithLabel value={value} points={points} colors={colors} />
      </Box>
    </>
  )
}

export default Progress
