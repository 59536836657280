import { useEffect } from 'react'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const DEMIO = process.env.REACT_APP_DEMIO ?? ''

export function LogoutPage() {
  const [user, loading] = useAuthState(auth)
  useEffect(() => {
    auth.signOut()
  }, [])

  useEffect(() => {
    if (!user) {
      window.location.href = `${DEMIO}`
    }
  }, [user, loading])

  return null
}
