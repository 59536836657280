import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import emailIcon from 'assets/images/emailicon.svg'
import '../Customize/Customize.scss'

import CustomizeEmailDetails from './CutomizeEmailDetails'
import CustomizeEmailReminder from './CustomizeReminder'

import { useFlag } from '@unleash/proxy-client-react'
import { FeatureFlagNames } from 'helper/FeatureFlagNames'

interface CustomizeEmailProps {
  handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void
  expanded: string | false
}

const CustomizeEmail: React.FC<CustomizeEmailProps> = ({ handleChange, expanded }) => {
  const isEventReminderEnabled = useFlag(FeatureFlagNames.EVENT_REMINDER)

  const accordionClass = expanded === 'panel1' ? 'bd_top' : 'accordionhover'

  return (
    <Accordion
      className={accordionClass}
      sx={{ width: '100%', paddingTop: '12px', paddingBottom: '12px' }}
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
      data-intercom-target='email'
    >
      <AccordionSummary aria-controls='panel1bh-content' id='panel1bh-header'>
        <div className='flex_start'>
          <img src={emailIcon} alt='email_icon' className='email_icon' />
          <div className='customize_flex_column'>
            <p className='acordion_heading'>Email</p>
            <p className='acordion_about'>Customize the emails</p>
          </div>
        </div>
        <div className='flex_end'>
          {expanded === 'panel1' && <button className='done'>DONE</button>}
        </div>
      </AccordionSummary>

      <CustomizeEmailDetails expanded={expanded} />
      {isEventReminderEnabled && <CustomizeEmailReminder expanded={expanded} />}
    </Accordion>
  )
}

export default CustomizeEmail
