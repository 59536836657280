import { IconProps } from '../types/icon-types';

export function ProfileIcon({ color = 'currentColor' }: IconProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="demio-icon "
      style={{ fontSize: '20px' }}
    >
      <path
        opacity="0.4"
        d="M24 .75C11.156.75.75 11.156.75 24S11.156 47.25 24 47.25 47.25 36.844 47.25 24 36.844.75 24 .75zm0 9a8.25 8.25 0 110 16.501A8.25 8.25 0 0124 9.75zM24 42a17.966 17.966 0 01-13.734-6.394C12.028 32.287 15.478 30 19.5 30c.226.001.45.036.666.103 2.483.863 5.185.863 7.668 0 .216-.067.44-.102.666-.103 4.022 0 7.472 2.288 9.234 5.606A17.965 17.965 0 0124 42z"
        fill={color}
      ></path>
      <path
        d="M24 26.25a8.25 8.25 0 100-16.501 8.25 8.25 0 000 16.501zM28.5 30c-.226.001-.45.036-.666.103a11.688 11.688 0 01-7.668 0A2.294 2.294 0 0019.5 30c-4.022 0-7.472 2.288-9.234 5.606a17.95 17.95 0 0027.468 0C35.972 32.287 32.522 30 28.5 30z"
        fill={color}
      ></path>
    </svg>
  );
}
