import star from 'assets/images/star.svg'
import Progress from 'components/Progress/Progress'
import filledstar from 'assets/images/filledstar.svg'
import gift from 'assets/images/gift.svg'
import './Reward.scss'

interface RewardProps {
  description: string
  name: string
  imageURL: string
  points: number
  referralsCount: number
  isCurrentActive: boolean
  elementColorScheme: string
  primaryFontSize: string
  secondaryFontSize: string
  primaryFontColorScheme: string
  secondaryFontColorScheme: string
  isMobile: boolean
}
const Reward: React.FC<RewardProps> = ({
  description,
  name,
  imageURL,
  referralsCount,
  points,
  isCurrentActive,
  elementColorScheme,
  primaryFontSize,
  secondaryFontSize,
  primaryFontColorScheme,
  secondaryFontColorScheme,
  isMobile,
}) => {
  const faded = (100 * referralsCount) / points > 100 && !isCurrentActive

  return (
    <div className={faded ? 'reward_wrapper grayed' : 'reward_wrapper'}>
      <div className='reward_wrapper_left'>
        {isCurrentActive ? (
          <img src={filledstar} alt='star' className='star' />
        ) : (
          <img src={star} alt='star' className='star' />
        )}
        <div className='reward_left'>
          <div className='reward_img'>
            {imageURL ? (
              <img src={imageURL} alt='award' />
            ) : (
              <div className='empty_reward_image' style={{ backgroundColor: '#1D4842' }}>
                <img style={{ width: '33px', height: '31px' }} src={gift} alt='' />
              </div>
            )}
          </div>
        </div>
        <div className='reward_right'>
          <p
            className='reward_name'
            style={{ fontSize: isMobile ? '24px' : primaryFontSize, color: primaryFontColorScheme }}
          >
            {name}
          </p>
          <p
            className='reward_desc'
            style={{
              fontSize: isMobile ? '10px' : secondaryFontSize,
              color: secondaryFontColorScheme,
            }}
          >
            {description}
          </p>
        </div>
      </div>
      <div className='reward_wrapper_right'>
        <Progress
          referralsCount={referralsCount}
          points={points}
          colors={elementColorScheme ?? ''}
        />
      </div>
    </div>
  )
}

export default Reward
