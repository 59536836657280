import { SxStyleType } from '../../../types/theme-types';

type RuleNames = 'navbarWrapper';

export const navbarUseStyles = (): SxStyleType<RuleNames> => ({
  navbarWrapper: {
    height: '90px',
    boxSizing: 'border-box',
  },
});
