import demioLogo from '../../../assets/img/logo/demio-logo.png';
import { getExternalLinks } from '../../../constants/link-constants';
import { NavbarAvatarDropdown } from '../navbar-avatar-dropdown/navbar-avatar-dropdown';
import { ReactNode } from 'react';
import { Stack } from '@mui/material';
import { UserType } from '../../../types/user-types';
import { ImageLazyLoader } from '../../../components/image-lazy-loader/image-lazy-loader';
import { NavbarProductsDropdown } from '../navbar-products-dropdown/navbar-products-dropdown';
import { NavbarQuestionsDropdown } from '../navbar-questions-dropdown/navbar-questions-dropdown';
import { ScheduleIcon } from '../../../icons/schedule-icon';
import { EventsIcon } from '../../../icons/events-icon';
import { navbarUseStyles } from './navbar-container.styles';
import { NavigationButton } from '../../../components/navigation-button/navigation-button';
import { Products } from '../../../constants/products-constants';
import { SkeletonLayout } from '../../../components/layouts/skeleton-layout/skeleton-layout';
import { Separator } from '../../../components/separator/separator';
import { BoostLogo } from '../../../icons/boost-icon';
import { Link as ReactRouterLink } from 'react-router-dom';

interface NavbarLinkContentWrapperProps {
  icon: ReactNode;
  text: string;
}

const NavbarLinkContentWrapper = ({ icon, text }: NavbarLinkContentWrapperProps) => {
  return (
    <Stack direction={'row'} alignItems={'center'} sx={{ gap: '0 8px' }}>
      {icon}
      <span>{text}</span>
    </Stack>
  );
};

const NavbarContainerLogo = ({ productVariant }: { productVariant: Products }) => {
  switch (productVariant) {
    case Products.Boost:
      return <BoostLogo />;
    case Products.Showcase:
      return <ImageLazyLoader style={{ maxWidth: '150px' }} src={demioLogo} alt="Demio" />;
    default:
      return null;
  }
};

export const NavbarContainer = ({
  user,
  productVariant,
  demioUrl,
}: {
  user: UserType;
  productVariant: Products;
  demioUrl: string;
}) => {
  const styles = navbarUseStyles();
  const externalLinks = getExternalLinks(demioUrl);

  const brandUrl = productVariant === Products.Showcase ? externalLinks.Demio : externalLinks.BoostMainPage;

  return (
    <>
      <SkeletonLayout>
        <Stack alignItems="center" direction="row" justifyContent="space-between" sx={styles.navbarWrapper}>
          <Stack direction="row" alignItems="center" spacing={6}>
            <ReactRouterLink to={brandUrl}>
              <NavbarContainerLogo productVariant={productVariant} />
            </ReactRouterLink>

            <NavigationButton linkSettings={{ externalLink: true, path: externalLinks.Schedule }}>
              <NavbarLinkContentWrapper icon={<ScheduleIcon />} text="Schedule" />
            </NavigationButton>

            <NavigationButton linkSettings={{ externalLink: true, path: externalLinks.Events }}>
              <NavbarLinkContentWrapper icon={<EventsIcon />} text="Events" />
            </NavigationButton>
          </Stack>

          <Stack direction="row" alignItems="center" sx={{ height: '100%' }}>
            <NavbarProductsDropdown
              demioUrl={demioUrl}
              productVariant={productVariant}
              isTrial={user?.claims.isTrial ?? true}
            />
            {productVariant === Products.Showcase && <NavbarQuestionsDropdown />}
            <NavbarAvatarDropdown productVariant={productVariant} demioUrl={demioUrl} user={user} />
          </Stack>
        </Stack>
      </SkeletonLayout>
      <Separator />
    </>
  );
};
