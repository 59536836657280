import { SxStyleType } from '../../types/theme-types';

type RuleNames = 'separator';

interface SeparatorStyleProps {
  color?: string;
  topSpace?: number;
  bottomSpace?: number;
}

export const separatorStyles = ({ color, topSpace, bottomSpace }: SeparatorStyleProps): SxStyleType<RuleNames> => ({
  separator: {
    width: '100%',
    marginTop: `${topSpace}px`,
    marginBottom: `${bottomSpace}px`,
    bgcolor: color ?? 'gray100',
  },
});
