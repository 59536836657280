import { ComponentWithChildren } from '../../../types/component-types';

import { Box } from '@mui/material';
import { skeletonLayoutStyles } from './skeleton-layout.styles';

export const SkeletonLayout = ({ children }: ComponentWithChildren) => {
  const styles = skeletonLayoutStyles();

  return (
    <Box sx={styles.skeletonContainer}>
      <Box sx={styles.skeletonWrapper}>
        <Box sx={styles.skeletonContentWrapper}>{children}</Box>
      </Box>
    </Box>
  );
};
