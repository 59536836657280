import { User } from 'firebase/auth'
import { UserType } from '../ui-kit/types/user-types'

export const getUserFullInfo = async (user: User): Promise<UserType> => {
  if (user) {
    const idTokenResult = await user?.getIdTokenResult()
    const claimDataFromToken = idTokenResult?.claims

    const claims = {
      companyName: claimDataFromToken?.companyName,
      companyId: claimDataFromToken?.companyId,
      isTrial: claimDataFromToken?.isTrial,
      subscription: claimDataFromToken?.subscription,
      email: claimDataFromToken?.email,
      auth_time: claimDataFromToken?.auth_time,
      demioId: claimDataFromToken?.demioId,
      intercomUserHash: claimDataFromToken?.intercomUserHash,
      name: claimDataFromToken?.name,
    }

    return {
      ...user,
      claims,
    }
  }

  return user
}
