import { useEffect, useState } from 'react'
import upload from 'assets/images/imageupload.svg'
import uploaded from 'assets/images/uploaded.svg'
import plus from 'assets/images/plus.svg'
import { ClipLoader } from 'react-spinners'
import closeimage from 'assets/images/closeimage.svg'
import './FacebookTemplate.scss'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from 'features/slices/eventSlice'
import { TextField } from '@mui/material'

interface FacebookTemplateProps {
  setFacebookData: React.Dispatch<React.SetStateAction<TemplateType>>
  facebookData: TemplateType
  setFacebookDataModified: React.Dispatch<React.SetStateAction<boolean>>
}

const FacebookTemplate: React.FC<FacebookTemplateProps> = ({
  setFacebookData,
  facebookData,
  setFacebookDataModified,
}) => {
  const [isUpload, setIsUpload] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isId, setIsId] = useState(false)
  const currentEvent = useSelector(getCurrentEvent)

  const updateFacebookData = (updatedData: TemplateType) => {
    setFacebookData(updatedData)
    setFacebookDataModified(true)
  }

  const closeImage = () => {
    setIsUpload(false)
    updateFacebookData({
      ...facebookData,
      photo: '',
      imageURL: '',
      imageKey: '',
      imageOriginalName: '',
    })
    setIsId(false)
  }

  const binaryData = facebookData?.photo ? [facebookData.photo] : []

  useEffect(() => {
    if (facebookData.id) {
      setIsId(true)
    }
  }, [facebookData.id])

  return (
    <div className='linkedin_wrapper'>
      <div className='linkedin_left'>
        <div className='flex_center'>
          <p className='edit_p'>Edit</p>
        </div>

        <div className='flex_center' style={{ marginTop: '30px' }}>
          <TextField
            defaultValue={facebookData.title}
            onChange={(e) => setFacebookData({ ...facebookData, title: e.target.value })}
            placeholder='First Webinar'
            label={'Title'}
            required
            helperText={facebookData.title.length < 1 ? 'This Field is Required' : ''}
            error={facebookData.title.length < 1}
            variant='outlined'
            InputLabelProps={{
              style: { color: facebookData.title.length < 1 ? '#d32f2f' : '#42454B' },
            }}
            sx={{
              width: '100%',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: facebookData.title.length < 1 ? '#d32f2f' : '#1D4842',
                },
              },
            }}
          />
        </div>

        <div className='flex_center' style={{ marginTop: '30px' }}>
          <TextField
            multiline
            rows={5}
            defaultValue={facebookData.content}
            onChange={(e) => setFacebookData({ ...facebookData, content: e.target.value })}
            placeholder="e.g. The industry's saaviest marketers use webinars to engage with their audience and convert.

              Learn how to become a pro from expert @ashley-levesque @ Webinars That Convert.

              Event Date: Mar. 3rd
              Event Time: 11a ET / 8a PT
              #make-marketing-human #webinars #demio"
            label={'Content'}
            variant='outlined'
            InputLabelProps={{
              style: { color: '#42454B' },
            }}
            sx={{
              width: '100%',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#1D4842',
                },
              },
            }}
          />
        </div>
        <div className='flex_start'>
          <p className='nolink'>
            To avoid inaccurate tracking of your promoters’ shares, please do not include any links
            to your registration page in your custom content
          </p>
        </div>
        <div className='flex_center'>
          {isUpload || facebookData.photo || (isId && facebookData.imageURL) ? (
            <div className='upload_div'>
              <img src={upload} alt='uploaded' />
              <p
                title={facebookData?.photo?.name || facebookData?.imageOriginalName || 'image'}
                style={{
                  marginLeft: '8px',
                  marginRight: '8px',
                  wordBreak: 'break-all',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {facebookData?.photo?.name || facebookData?.imageOriginalName || 'image'}
              </p>
              {isLoaded || facebookData.photo || facebookData.id ? (
                <img src={uploaded} alt='uploaded' />
              ) : (
                <ClipLoader color='#1D4842' size={20} speedMultiplier={0.3} />
              )}
              {isLoaded || facebookData.photo || facebookData.id ? (
                <button onClick={closeImage} className='close_image'>
                  <img src={closeimage} alt='close' />
                </button>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className='file-card' style={{ height: '220px' }}>
              <div className='file-inputs'>
                <input
                  type='file'
                  accept='image/png, image/gif, image/jpeg, image/png, image/svg'
                  id='image'
                  onChange={(e) => {
                    if (e.target.files) {
                      setFacebookData({ ...facebookData, photo: e.target.files[0] })
                      setIsUpload(true)
                      setTimeout(() => {
                        setIsLoaded(true)
                      }, 3000)
                    }
                  }}
                />

                <button>
                  <img src={plus} style={{ width: '30px' }} alt='iconplus' />
                </button>
              </div>
              <p className='upload'>Upload Media</p>
              <p className='info'>.JPEG .PNG .JPG up to 3 MB</p>
            </div>
          )}
        </div>
      </div>
      <div className='linkedin_right'>
        <div className='flex_center'>
          <p className='edit_p'>Preview</p>
        </div>
        <div className='url_share_page' style={{ marginTop: '80px' }}>
          <div className='flex_center'>
            {(() => {
              if (facebookData.id && !isUpload && facebookData.imageURL)
                return <img src={facebookData.imageURL} alt='fb_photo' className='share_image' />
              if (facebookData.photo)
                return (
                  <img
                    className='share_image'
                    src={URL.createObjectURL(new Blob(binaryData, { type: 'application/zip' }))}
                    alt='image_preview'
                  />
                )
            })()}
          </div>
          <div className='url_share_page_bottom'>
            <div className='flex_start'>
              <div title={facebookData.title ?? currentEvent.name} className='urltitle'>
                {facebookData.title ?? currentEvent.name}
              </div>
            </div>
            <div className='flex_start'>
              <p title={facebookData.content} className='url_text'>
                {facebookData.content}
              </p>
            </div>
            <div className='flex_start'>
              <p className='url_text'>boost.banzai.io • 1 min read</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FacebookTemplate
