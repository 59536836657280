const DEMIO = process.env.REACT_APP_DEMIO ?? ''

const ROUTES = {
  BOOST: '/boost',
  TEAM: '/team',
  SETTINGS: '/settings',
  EVENTSYNC: '/demio-event-sync',
  LOGOUT: '/logout',
  SHARE: '/share',
  DEMIO: DEMIO,
}

export default ROUTES
