import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Event from 'components/Event/Event'
import { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { auth } from '../../firebase'
import iconinfo from 'assets/images/iconinfo.svg'
import { useAuthState } from 'react-firebase-hooks/auth'
import Footer from 'components/Footer/Footer'
import { ClipLoader } from 'react-spinners'
import expired from 'assets/images/redexpired.svg'
import './EventsList.scss'

const BACKEND_API = process.env.REACT_APP_BACKEND_API

const EventsList = () => {
  const [filter, setFilter] = useState('All Events')
  const [active, setActive] = useState('/upcoming')
  const [archived, setArchived] = useState<Array<EventType>>([])
  const [upcoming, setUpcoming] = useState<Array<EventType>>([])
  const [user, loading] = useAuthState(auth)
  const [expiredBool, setExpiredBool] = useState<null | boolean | string>(false)

  useEffect(() => {
    const isExpired = JSON.parse(localStorage.getItem('unsubscribed') || '')
    setExpiredBool(isExpired)
  }, [])

  useEffect(() => {
    if (loading) return
    localStorage.setItem('option', '/audience')
    const fetchUpcoming = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events?filter[archived][eq]=false&sort[event.createdDate]=DESC`,
        {
          headers: {
            Authorization: `Bearer ${await user?.getIdToken(true)}`,
          },
        },
      )
      setUpcoming(data.result)
    }

    const fetchArchived = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events?filter[archived][eq]=true&sort[event.createdDate]=DESC`,
        {
          headers: {
            Authorization: `Bearer ${await user?.getIdToken(true)}`,
          },
        },
      )
      setArchived(data.result)
    }
    fetchUpcoming()
    fetchArchived()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const handleChange = (event: SelectChangeEvent) => setFilter(event.target.value as string)

  const getFilteredList = () => {
    if (!filter) {
      return upcoming
    } else if (filter === 'All Events') {
      return upcoming
    }
    return upcoming.filter((item) => item.status === filter)
  }

  const filteredList = useMemo(getFilteredList, [filter, upcoming])

  return (
    <>
      <div className='eventslist_wrapper'>
        {upcoming.length > 0 ? (
          <>
            <div className='container'>
              {expiredBool && (
                <div className='expired'>
                  <img src={expired} alt='expired icon' />
                  <p>
                    Your Boost account is disabled due to trial expiration or cancellation of your
                    subscription. You may activate your account by contacting our
                    <a href='mailto:boost@banzai.io'> support team.</a>
                  </p>
                </div>
              )}

              <div className='flex_start'>
                <p className='events'>Events</p>
                <p
                  onClick={() => {
                    if (expiredBool) {
                      return null
                    } else {
                      setActive('/upcoming')
                    }
                  }}
                  style={{ marginLeft: '65px' }}
                  className={active === '/upcoming' ? 'list_navlink activelist' : 'list_navlink'}
                >
                  Upcoming
                </p>
                <p
                  onClick={() => {
                    if (expiredBool) {
                      return null
                    } else {
                      setActive('/archived')
                    }
                  }}
                  className={active === '/archived' ? 'list_navlink activelist' : 'list_navlink'}
                >
                  Archived
                </p>
              </div>
              <div className='line'></div>
              {active === '/upcoming' && (
                <div className='flex_start marginTop30'>
                  <FormControl sx={{ minWidth: 300 }}>
                    <Select
                      disabled={Boolean(expiredBool)}
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={filter}
                      onChange={handleChange}
                    >
                      <MenuItem value={'All Events'}>All Events</MenuItem>
                      <MenuItem value={'Launched'}>Launched</MenuItem>
                      <MenuItem value={'Paused'}>Paused</MenuItem>
                      <MenuItem value={'Not Started'}>Not Launched</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
            <div className='container'>
              {active === '/archived' && (
                <div className='demio_line'>
                  <img src={iconinfo} alt='' />
                  <p>
                    Please unarchive events from Demio. You can still view all event details by
                    clicking on the event in Boost.
                  </p>
                </div>
              )}

              <div className='eventslist_events' data-intercom-target='eventsList'>
                {active === '/upcoming' &&
                  filteredList?.map((item: EventType) => {
                    return (
                      <Event
                        unsubscribed={expiredBool}
                        archived={item.archived}
                        demioId={item.demioId}
                        id={item.id}
                        onDemand={item.onDemand}
                        pastSessions={item.pastSessionsCount}
                        registrationURL={item.registrationURL}
                        type={item.type}
                        upcomingSessions={item.upcomingSessionsCount}
                        name={item.name}
                        key={item.id}
                        status={item.status}
                      />
                    )
                  })}
                {active === '/archived' &&
                  archived?.map((item: EventType) => {
                    return (
                      <Event
                        unsubscribed={expiredBool}
                        archived={item.archived}
                        demioId={item.demioId}
                        id={item.id}
                        onDemand={item.onDemand}
                        pastSessions={item.pastSessionsCount}
                        registrationURL={item.registrationURL}
                        type={item.type}
                        upcomingSessions={item.upcomingSessionsCount}
                        name={item.name}
                        key={item.id}
                        status={item.status}
                      />
                    )
                  })}
              </div>
            </div>
            <Footer />
          </>
        ) : (
          <div className='flex_center' style={{ marginTop: '200px' }}>
            <ClipLoader color='#1D4842' size={20} speedMultiplier={1.2} />
          </div>
        )}
      </div>
    </>
  )
}

export default EventsList
