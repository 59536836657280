import React, { ChangeEvent } from 'react'
import { Button, Grid, TextField, Typography } from '@mui/material'
import plus from 'assets/images/plus.svg'

const getStyles = () => ({
  fileCard: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
    borderRadius: '10px',
    width: '100%',
    border: '2px dashed #b9bfc6',
    backgroundColor: '#f1f1f1',
  },

  fileInputs: {
    position: 'relative',
  },

  input: {
    position: 'relative',
    textAlign: 'right',
    opacity: '0',
    zIndex: '2',
    cursor: 'pointer',
    height: '46px',
    maxWidth: '200px',
  },

  button: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '1.1rem',
    cursor: 'pointer',
    borderRadius: '4px',
    border: 'none',
    outline: 'none',
    transition: '0.3s all',
  },

  upload: {
    fontFamily: ' Reader, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#6c737a',
  },
  info: {
    fontFamily: 'Reader, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '15px',
    textAlign: 'center',
    color: '#838b97',
  },
})

interface CustomImageUploadProps {
  setSelectedValues: React.Dispatch<React.SetStateAction<CustomizationTemplateType>>
  selectedValues: CustomizationTemplateType
  setIsUploaded: React.Dispatch<React.SetStateAction<boolean>>
  title: string
}

const CustomImageUpload: React.FC<CustomImageUploadProps> = ({
  setSelectedValues,
  setIsUploaded,
  selectedValues,
  title,
}) => {
  const styles = getStyles()

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsUploaded(true)
    switch (title) {
      case 'Background Image:':
        setSelectedValues({ ...selectedValues, photo: event.target.files?.[0] })
        break
      case 'Header Logo:':
        setSelectedValues({ ...selectedValues, logo: event.target.files?.[0] })
        break
      default:
        break
    }

    setTimeout(() => {
      setIsUploaded(false)
    }, 1500)

    if (event?.target?.value) {
      event.target.value = ''
    }
  }

  return (
    <>
      <Typography sx={{ fontSize: '14px', marginTop: '30px' }}>{title}</Typography>
      <Grid container sx={styles.fileCard}>
        <Grid item sx={styles.fileInputs}>
          <TextField
            sx={styles.input}
            type='file'
            inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
            onChange={onFileChange}
          ></TextField>
          <Button sx={styles.button}>
            <img src={plus} style={{ width: '30px' }} alt='iconplus' />
          </Button>
        </Grid>
        <Typography sx={styles.upload}>Upload Media</Typography>
        <Typography sx={styles.info}>.JPEG .PNG .JPG up to 3 MB</Typography>
      </Grid>
    </>
  )
}

export default CustomImageUpload
