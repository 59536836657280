import incentivesicon from 'assets/images/incentivesicon.svg'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Accordion from '@mui/material/Accordion'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import union from 'assets/images/plusinc.svg'
import plus from 'assets/images/plus.svg'
import close from 'assets/images/close.svg'
import logo from 'assets/images/boostlogo.svg'
import { REGEXP } from 'constants/regexp'
import axios from 'axios'
import EditReward from 'components/EditReward/EditReward'
import { ClipLoader } from 'react-spinners'
import uploaded from 'assets/images/uploaded.svg'
import upload from 'assets/images/imageupload.svg'
import closeimage from 'assets/images/closeimage.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentRewards, setCurrentRewards } from 'features/slices/eventSlice'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { TextField } from '@mui/material'

interface CustomizeIncentivesProps {
  handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void
  expanded: string | false
}

type FormValues = {
  name: string
  description: string
  image: string
  points: string
}

const BACKEND_API = process.env.REACT_APP_BACKEND_API

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  bgcolor: 'background.paper',
  borderRadius: 2,
  p: 4,
}

const CustomizeIncentives: React.FC<CustomizeIncentivesProps> = ({ handleChange, expanded }) => {
  const [openModal, setOpenModal] = useState(false)
  const [isUpload, setIsUpload] = useState(false)
  const [isLoaded, setIsloaded] = useState(false)
  const [image, setImage] = useState<{ name: string | null }>()
  const [disabled, setDisabled] = useState(false)
  const [user] = useAuthState(auth)

  const dispatch = useDispatch()
  const { id } = useParams()

  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<FormValues>({
    mode: 'onBlur',
  })

  useEffect(() => {
    const fetchReward = async () => {
      const { data } = await axios.get(`${BACKEND_API}/events/event/${id}/rewards`)
      dispatch(setCurrentRewards(data.result))
    }
    fetchReward()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rewards = useSelector(getCurrentRewards)

  const closeImage = () => {
    setIsUpload(false)
    setImage({ name: '' })
  }

  const onSave = async (formData: FormValues) => {
    setDisabled(true)
    const { data } = await axios.post(
      `${BACKEND_API}/events/event/${id}/rewards/reward`,
      {
        description: formData.description,
        photo: image,
        name: formData.name,
        points: formData.points,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${await user?.getIdToken(true)}`,
        },
      },
    )
    const rewardsToUpdaterewards = [...rewards, data]
    dispatch(setCurrentRewards(rewardsToUpdaterewards))
    setIsUpload(false)
    setIsloaded(false)
    reset({ name: '', description: '', points: '' })
    setImage({ name: null })
    handleCloseModal()
    setDisabled(false)
  }

  useEffect(() => {
    const fetchReward = async () => {
      const { data } = await axios.get(`${BACKEND_API}/events/event/${id}/rewards`)
      dispatch(setCurrentRewards(data.result))
    }
    fetchReward()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Accordion
      className={expanded === 'panel2' ? 'bd_top' : 'accordionhover'}
      sx={{
        width: '100%',
        paddingTop: '12px',
        paddingBottom: '12px',
        marginTop: '30px',
      }}
      expanded={expanded === 'panel2'}
      onChange={handleChange('panel2')}
      data-intercom-target='rewards'
    >
      <AccordionSummary aria-controls='panel2bh-content' id='panel2bh-header'>
        <div className='flex_start'>
          <img src={incentivesicon} alt='emailicon' style={{ width: '36px', height: '36px' }} />
          <div className='flex_column' style={{ marginLeft: '30px' }}>
            <p className='acordion_heading'>Rewards</p>
            <p className='acordion_about'>Customize the details</p>
          </div>
        </div>
        <div className='flex_end'>
          {expanded === 'panel2' ? <button className='done'>DONE</button> : ''}
        </div>
      </AccordionSummary>

      {rewards.map((item: RewardType) => {
        return (
          <AccordionDetails
            key={item.id}
            sx={{
              paddingTop: '30px',
              paddingBottom: '30px',
              display: 'flex',
              alignItems: 'center',
            }}
            className={expanded ? 'bd_top_gray' : ''}
          >
            <EditReward rewardId={item.id || ''} name={item.name || ''} />
          </AccordionDetails>
        )
      })}

      <AccordionDetails sx={{ paddingTop: '50px' }} className={expanded ? 'bd_top_gray' : ''}>
        <div className='flex_end'>
          <button
            className='add_incentive'
            onClick={handleOpenModal}
            type='button'
            data-intercom-target='addReward'
          >
            <img src={union} alt='plus' /> ADD NEW REWARD
          </button>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <div
                className='flex_between'
                style={{ borderBottom: '1px solid #DEE4E8', paddingBottom: '20px' }}
              >
                <p className='modal_p'>Edit Reward</p>
                <button className='close' type='button' data-intercom-target='rewardExit'>
                  <img src={close} alt='close' onClick={handleCloseModal} />
                </button>
              </div>
              <div className='flex_center'>
                <figure className='logo_div_second'>
                  <img src={logo} alt='logo' style={{ width: '120px', height: '90px' }} />
                </figure>
              </div>
              <div className='flex_center'>
                <form className='form_incentive' onSubmit={handleSubmit(onSave)}>
                  <div className='flex_center'>
                    <Controller
                      name={'name'}
                      control={control}
                      rules={{
                        required: 'This Field is required',
                        pattern: {
                          value: REGEXP.REWARD_NAME,
                          message: 'Title should contain up to 19 characters',
                        },
                      }}
                      render={({ field: { onChange }, fieldState: { error } }) => (
                        <TextField
                          data-intercom-target='rewardName'
                          onChange={onChange}
                          placeholder='Enter the Reward Name'
                          label={'Reward Name'}
                          error={!!error}
                          variant='outlined'
                          helperText={error ? error.message : null}
                          InputLabelProps={{
                            style: { color: error ? '#d32f2f' : '#42454B' },
                          }}
                          sx={{
                            width: '100%',
                            borderRadius: '4px',
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: error ? '#d32f2f' : '#1D4842',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className='flex_center' style={{ marginTop: '30px' }}>
                    <Controller
                      name={'description'}
                      control={control}
                      rules={{
                        required: 'This Field is required',
                        pattern: {
                          value: REGEXP.REWARD_DESC,
                          message: 'Description should contain up to 40 characters',
                        },
                      }}
                      render={({ field: { onChange }, fieldState: { error } }) => (
                        <TextField
                          onChange={onChange}
                          data-intercom-target='rewardDescription'
                          placeholder='Reward Description'
                          error={!!error}
                          label={'Description'}
                          variant='outlined'
                          helperText={error ? error.message : null}
                          InputLabelProps={{
                            style: { color: error ? '#d32f2f' : '#42454B' },
                          }}
                          sx={{
                            width: '100%',
                            borderRadius: '4px',
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: error ? '#d32f2f' : '#1D4842',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </div>

                  {isUpload ? (
                    <div className='upload_div'>
                      <img src={upload} alt='reward' />
                      <p
                        title={image?.name ?? ''}
                        style={{
                          marginLeft: '8px',
                          marginRight: '8px',
                          wordBreak: 'break-all',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {image?.name}
                      </p>
                      {isLoaded ? (
                        <img src={uploaded} alt='uploaded' />
                      ) : (
                        <ClipLoader color='#1D4842' size={20} speedMultiplier={0.3} />
                      )}
                      {isLoaded ? (
                        <button onClick={closeImage} className='close_image'>
                          <img src={closeimage} alt='close' />
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <div className='flex_center'>
                      <div className='file-card'>
                        <div className='file-inputs'>
                          <input
                            data-intercom-target='rewardImage'
                            type='file'
                            accept='image/png, image/gif, image/jpeg, image/png, image/svg'
                            {...register('image', {
                              onChange: (e) => {
                                setIsUpload(true)
                                if (e.target.files) {
                                  setImage(e.target.files[0])
                                }
                                setTimeout(() => {
                                  setIsloaded(true)
                                }, 3000)
                              },
                            })}
                          />

                          <button>
                            <img src={plus} style={{ width: '30px' }} alt='iconplus' />
                          </button>
                        </div>
                        <p className='upload'>Upload Media</p>
                        <p className='info'>.JPEG .PNG up to 3 MB</p>
                      </div>
                      <p className='error'>{errors?.image && `${errors?.image.message}`}</p>
                    </div>
                  )}

                  <div className='flex_center' style={{ marginTop: '30px' }}>
                    <Controller
                      name={'points'}
                      control={control}
                      rules={{
                        required: 'This Field is required',
                        pattern: {
                          value: REGEXP.POINTS,
                          message: 'Max. number of points is 50,000.',
                        },
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          onChange={onChange}
                          placeholder='Enter the Points'
                          data-intercom-target='rewardPoints'
                          label={'Points'}
                          error={!!error}
                          variant='outlined'
                          type='number'
                          onInput={() => {
                            value = Math.max(0, parseInt(value)).toString().slice(0, 2)
                          }}
                          helperText={error ? error.message : null}
                          InputLabelProps={{
                            style: { color: error ? '#d32f2f' : '#42454B' },
                          }}
                          sx={{
                            width: '100%',
                            borderRadius: '4px',
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: error ? '#d32f2f' : '#1D4842',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className='flex_start' style={{ marginTop: '10px' }}>
                    <p className='point'>1 point is 1 registration</p>
                  </div>
                  <div className='flex_end'>
                    {disabled ? (
                      <button type='submit' className='btn_save' disabled>
                        <ClipLoader color='#FFFFFF' size={20} speedMultiplier={0.3} />
                      </button>
                    ) : (
                      <button type='submit' className='btn_save' data-intercom-target='rewardSave'>
                        SAVE
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </Box>
          </Modal>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default CustomizeIncentives
