import { Box, Tooltip, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { ChromePicker, ColorResult } from 'react-color'
import info from 'assets/images/info_icon.svg'

interface CustomColorPickerProps {
  selectedValues: CustomizationTemplateType
  setSelectedValues: React.Dispatch<React.SetStateAction<CustomizationTemplateType>>
  title: string
}

const CustomColorPicker: React.FC<CustomColorPickerProps> = ({
  selectedValues,
  setSelectedValues,
  title,
}) => {
  const [color, setColor] = useState('#000000')
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const picker = useRef<HTMLDivElement>(null)

  const closePicker = (e: Event) => {
    const target = e.target as HTMLElement

    if (picker.current?.contains(target)) {
      return
    }
    setDisplayColorPicker(false)
  }

  const handleToggleColorPicker = () => {
    setDisplayColorPicker((prev) => !prev)
  }
  const onChange = (color: ColorResult) => {
    switch (title) {
      case 'Element Color Scheme:':
        setColor(color.hex)
        setSelectedValues((prev) => ({
          ...prev,
          elementColorScheme: color.hex,
        }))
        break
      case 'Background Color Scheme:':
        setColor(color.hex)
        setSelectedValues((prev) => ({
          ...prev,
          backgroundColorScheme: color.hex,
        }))
        break
      case 'Primary Font Color Scheme:':
        setColor(color.hex)
        setSelectedValues((prev) => ({
          ...prev,
          primaryFontColorScheme: color.hex,
        }))
        break
      case 'Secondary Font Color Scheme:':
        setColor(color.hex)
        setSelectedValues((prev) => ({
          ...prev,
          secondaryFontColorScheme: color.hex,
        }))
        break
      default:
        break
    }
  }

  const backgroundColorSetter = () => {
    switch (title) {
      case 'Element Color Scheme:':
        return selectedValues.elementColorScheme
      case 'Background Color Scheme:':
        return selectedValues.backgroundColorScheme
      case 'Primary Font Color Scheme:':
        return selectedValues.primaryFontColorScheme
      case 'Secondary Font Color Scheme:':
        return selectedValues.secondaryFontColorScheme
      default:
        return color
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', closePicker)

    return () => {
      document.removeEventListener('mousedown', closePicker)
    }
  }, [])

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginTop: title === 'Element Color Scheme:' ? '0px' : '30px',
        }}
      >
        <Typography>{title}</Typography>{' '}
        {title === 'Element Color Scheme:' && (
          <Tooltip title='This applies only if you add rewards to this event.'>
            <img style={{ marginLeft: '5px', marginBottom: '3px' }} alt='info' src={info}></img>
          </Tooltip>
        )}
      </Box>

      <div
        className='colorPicker'
        style={{
          width: '150px',
          height: '47px',
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: '20px',
        }}
      >
        <div
          className='color'
          style={{
            width: '47px',
            backgroundColor: backgroundColorSetter() ?? color,
            border: '1px solid #D6D6D6',
            borderRight: 'none',
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
          }}
          onClick={() => handleToggleColorPicker()}
        ></div>

        <div
          className='colorCode'
          style={{
            width: '103px',
            backgroundColor: '#ffff',
            color: '#000000a6',
            fontSize: '13px',
            border: '1px solid #D6D6D6',
            borderRadius: '0px 5px 5px 0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {backgroundColorSetter() ?? color}
        </div>
      </div>

      {displayColorPicker && (
        <div className='picker' ref={picker}>
          <ChromePicker disableAlpha={true} color={color} onChange={onChange} />
        </div>
      )}
    </>
  )
}

export default CustomColorPicker
