import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { fetchGmail } from 'api/api'

const initialState: GmailTemplateType = {
  title: '',
  content: '',
  photo: '',
  subject: '',
  id: '',
  imageURL: '',
  imageKey: '',
  isActive: true,
  imageOriginalName: '',
}

const gmailSlice = createSlice({
  name: 'gmail',
  initialState,
  reducers: {
    setGmailData: (state, { payload }) => {
      return {
        ...state,
        title: payload.title,
        content: payload.content,
        photo: payload.photo,
        subject: payload.subject,
        id: payload.id,
        imageURL: payload.imageURL,
        imageKey: payload.imageKey,
        isActive: payload.isActive,
        imageOriginalName: payload.imageOriginalName,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGmail.fulfilled, (state, action) => {
      return {
        ...state,
        title: action.payload.title,
        content: action.payload.content,
        photo: action.payload.imageOriginalName,
        subject: action.payload.subject,
        id: action.payload.id,
        imageURL: action.payload.imageURL,
        imageKey: action.payload.imageKey,
        isActive: action.payload.isActive,
        imageOriginalName: action.payload.imageOriginalName,
      }
    })
  },
})

export const getGmailData = (state: RootState) => state.gmail

export const { setGmailData } = gmailSlice.actions

export default gmailSlice.reducer
