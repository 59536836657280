import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import iconmodal from 'assets/images/iconmodal.svg'

interface ChoosePlatformModalProps {
  open: boolean
  onClose: () => void
}

const ChoosePlatformModal: React.FC<ChoosePlatformModalProps> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 480,
          height: 164,
          padding: '50px',
          bgcolor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}
      >
        <div className='flex_start'>
          <img src={iconmodal} alt='iconmodal' />
          <p
            style={{
              marginLeft: '20px',
              color: '#2C3336',
              fontSize: '16px',
              fontWeight: '400',
              fontFamily: 'Lato, sans-serif',
            }}
          >
            Choose social platform
          </p>
        </div>

        <Typography
          id='modal-modal-description'
          sx={{
            marginLeft: '45px',
            color: '#2C3336',
            fontSize: '14px',
            marginTop: '8px',
            fontWeight: '400',
            fontFamily: 'Lato, sans-serif',
          }}
        >
          At least one social media platform is required.
        </Typography>

        <div className='flex_center' style={{ marginTop: '25px' }}>
          <button className='confirm' onClick={onClose}>
            Got it
          </button>
        </div>
      </Box>
    </Modal>
  )
}

export default ChoosePlatformModal
