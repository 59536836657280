import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { REGEXP } from 'constants/regexp'
import close from 'assets/images/close.svg'
import logo from 'assets/images/boostlogo.svg'
import plus from 'assets/images/plus.svg'
import axios from 'axios'
import { ClipLoader } from 'react-spinners'
import uploaded from 'assets/images/uploaded.svg'
import upload from 'assets/images/imageupload.svg'
import closeimage from 'assets/images/closeimage.svg'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentRewards, setCurrentRewards } from 'features/slices/eventSlice'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { TextField } from '@mui/material'

type FormValues = {
  name: string
  description: string
  image: string
  points: string
}

interface EditRewardProps {
  rewardId: string
  name: string
}

const BACKEND_API = process.env.REACT_APP_BACKEND_API

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  bgcolor: 'background.paper',
  borderRadius: 2,
  p: 4,
}

const EditReward: React.FC<EditRewardProps> = ({ rewardId, name }) => {
  const [openModal, setOpenModal] = useState(false)
  const [reward, setReward] = useState<RewardType>()
  const [isUpload, setIsUpload] = useState(false)
  const [isLoaded, setIsloaded] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [image, setImage] = useState<string>()
  const [isId, setIsId] = useState(false)

  const [user] = useAuthState(auth)
  const dispatch = useDispatch()
  const rewards = useSelector(getCurrentRewards)
  const { id } = useParams()

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<FormValues>({
    mode: 'onBlur',
  })

  useEffect(() => {
    const fetchReward = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events/event/${id}/rewards/reward/${rewardId}`,
      )
      setReward(data)
      setImage(data.imageOriginalName)
    }
    fetchReward()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (reward?.id) {
      setIsId(true)
    }
  }, [reward?.id])

  const handleOpenModal = () => setOpenModal(true)

  const closeImage = () => {
    setIsId(false)
    setIsUpload(false)
    setIsloaded(false)
    setReward({ ...reward, imageURL: '' })
    setImage('')
  }

  const handleCloseModal = () => setOpenModal(false)

  const onEdit = async (data: FormValues) => {
    setLoadingSave(true)
    const response = await axios.patch(
      `${BACKEND_API}/events/event/${id}/rewards/reward/${rewardId}`,
      {
        description: data.description,
        name: data.name,
        photo: image || '',
        points: data.points,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${await user?.getIdToken(true)}`,
        },
      },
    )
    if (response) {
      setLoadingSave(false)
      reset()
      handleCloseModal()
      const { data } = await axios.get(`${BACKEND_API}/events/event/${id}/rewards`)
      dispatch(setCurrentRewards(data.result))
    }
  }

  const handleDeleteReward = async () => {
    await axios.delete(`${BACKEND_API}/events/event/${id}/rewards/reward/${rewardId}`, {
      headers: {
        Authorization: `Bearer ${await user?.getIdToken(true)}`,
      },
    })
    let rewardsToUpdate = [...rewards]
    rewardsToUpdate = rewardsToUpdate.filter((reward) => reward.id !== rewardId)
    dispatch(setCurrentRewards(rewardsToUpdate))
  }

  return (
    <div className='flex_between'>
      <p className='instant'>{name}</p>
      <div className='flex_end'>
        <button type='button' onClick={handleOpenModal} className='edit_email'>
          Edit
        </button>
        <button
          type='button'
          onClick={handleDeleteReward}
          className='edit_email'
          style={{ marginLeft: '10px' }}
        >
          Delete
        </button>
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div
            className='flex_between'
            style={{ borderBottom: '1px solid #DEE4E8', paddingBottom: '40px' }}
          >
            <p className='modal_p'>Edit Reward</p>
            <button className='close' type='button'>
              <img src={close} alt='close' onClick={handleCloseModal} />
            </button>
          </div>
          <div className='flex_center'>
            <figure className='logo_div_second'>
              <img src={logo} alt='logo' style={{ width: '130px', height: '90px' }} />
            </figure>
          </div>
          <div className='flex_center'>
            <form className='form_incentive' onSubmit={handleSubmit(onEdit)}>
              <div className='flex_center'>
                <Controller
                  defaultValue={reward?.name}
                  name={'name'}
                  control={control}
                  rules={{
                    required: 'This Field is required',
                    pattern: {
                      value: REGEXP.REWARD_NAME,
                      message: 'Title should contain up to 19 characters',
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      onChange={onChange}
                      placeholder='Enter the Reward Name'
                      value={value}
                      label={'Reward Name'}
                      error={!!error}
                      defaultValue={reward?.name}
                      variant='outlined'
                      helperText={error ? error.message : null}
                      InputLabelProps={{
                        style: { color: error ? '#d32f2f' : '#42454B' },
                      }}
                      sx={{
                        width: '100%',
                        borderRadius: '4px',
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: error ? '#d32f2f' : '#1D4842',
                          },
                        },
                      }}
                    />
                  )}
                />
              </div>

              <div className='flex_center' style={{ marginTop: '30px' }}>
                <Controller
                  defaultValue={reward?.description}
                  name={'description'}
                  control={control}
                  rules={{
                    required: 'This Field is required',
                    pattern: {
                      value: REGEXP.REWARD_DESC,
                      message: 'Description should contain up to 40 characters',
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      onChange={onChange}
                      placeholder='Reward Description'
                      value={value}
                      error={!!error}
                      defaultValue={reward?.description}
                      label={'Description'}
                      variant='outlined'
                      helperText={error ? error.message : null}
                      InputLabelProps={{
                        style: { color: error ? '#d32f2f' : '#42454B' },
                      }}
                      sx={{
                        width: '100%',
                        borderRadius: '4px',
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: error ? '#d32f2f' : '#1D4842',
                          },
                        },
                      }}
                    />
                  )}
                />
              </div>
              {isUpload || reward?.imageURL || isId ? (
                <div className='upload_div'>
                  <img src={upload} alt='reward' className='uploadedimage' />
                  <p style={{ marginLeft: '8px', marginRight: '8px', wordBreak: 'break-all' }}>
                    {'Image'}
                  </p>
                  {isLoaded || reward?.imageURL || reward?.id ? (
                    <img src={uploaded} alt='uploaded' />
                  ) : (
                    <ClipLoader color='#1D4842' size={20} speedMultiplier={0.3} />
                  )}
                  {isLoaded || reward?.imageURL || reward?.id ? (
                    <button onClick={closeImage} type='button' className='close_image'>
                      <img src={closeimage} alt='close' />
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <div className='flex_center'>
                  <div className='file-card'>
                    <div className='file-inputs'>
                      <input
                        type='file'
                        accept='image/png, image/gif, image/jpeg, image/png, image/svg'
                        {...register('image', {
                          onChange: (e) => {
                            setIsUpload(true)
                            if (e.target.files) {
                              setImage(e.target.files[0])
                            }
                            setTimeout(() => {
                              setIsloaded(true)
                            }, 3000)
                          },
                        })}
                      />

                      <button>
                        <img src={plus} style={{ width: '30px' }} alt='iconplus' />
                      </button>
                    </div>
                    <p className='upload'>Upload Media</p>
                    <p className='info'>.JPEG .PNG up to 3 MB</p>
                  </div>
                  <p className='error'>{errors?.image && `${errors?.image.message}`}</p>
                </div>
              )}

              <div className='flex_center' style={{ marginTop: '30px' }}>
                <Controller
                  name={'points'}
                  defaultValue={String(reward?.points)}
                  control={control}
                  rules={{
                    required: 'This Field is required',
                    pattern: {
                      value: REGEXP.POINTS,
                      message: 'Max. number of points is 50,000.',
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      onChange={onChange}
                      placeholder='Enter the Points'
                      value={value}
                      label={'Points'}
                      error={!!error}
                      defaultValue={reward?.points}
                      variant='outlined'
                      type='number'
                      onInput={() => {
                        value = Math.max(0, parseInt(value)).toString().slice(0, 2)
                      }}
                      helperText={error ? error.message : null}
                      InputLabelProps={{
                        style: { color: error ? '#d32f2f' : '#42454B' },
                      }}
                      sx={{
                        width: '100%',
                        borderRadius: '4px',
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: error ? '#d32f2f' : '#1D4842',
                          },
                        },
                      }}
                    />
                  )}
                />
              </div>

              <div className='flex_start' style={{ marginTop: '10px' }}>
                <p className='point'>1 point is 1 registration</p>
              </div>
              <div className='flex_end'>
                <button disabled={loadingSave} type='submit' className='btn_save'>
                  {loadingSave ? (
                    <ClipLoader color='#FFFFFF' size={20} speedMultiplier={0.3} />
                  ) : (
                    'Save'
                  )}
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default EditReward
