import BarComponent from 'components/BarComponent/BarComponent'
import './Registrants.scss'

const Registrants = () => {
  return (
    <div className='registrants_wrapper'>
      <BarComponent />
    </div>
  )
}

export default Registrants
