import axios from 'axios'
import { useEffect, useState } from 'react'
import AuthToken from 'helper/Storage'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { signInWithCustomToken } from 'firebase/auth'
import { auth } from '../../firebase'
import loadingtopright from 'assets/images/right_1.svg'
import loadingBottom from 'assets/images/No_shadow2.svg'
import loading1 from 'assets/images/loading_1.svg'
import icon1 from 'assets/images/icon1.svg'
import icon2 from 'assets/images/icon2.svg'
import { useAuthState } from 'react-firebase-hooks/auth'
import icon3 from 'assets/images/icon3.svg'
import day from 'assets/images/14day.svg'
import icon4 from 'assets/images/icon4.svg'
import { CircularStatic } from 'components/CircularWithLabel/CircularWithLabel'
import path from 'assets/images/path.svg'
import Footer from 'components/Footer/Footer'
import './Loading.scss'

const BACKEND_API = process.env.REACT_APP_BACKEND_API

const Loading = () => {
  const navigate = useNavigate()

  const [ready, setReady] = useState(false)
  const [unsubscribed, setUnsubscribed] = useState<null | boolean | string>(null)
  const [show, setShow] = useState<boolean>(true)
  const [alreadySynced, setAlreadySynced] = useState<null | boolean>(null)
  const [demioIntegrationId, setDemioIntegrationId] = useState<null | string>(null)

  const [searchParams] = useSearchParams()
  const [user] = useAuthState(auth)
  const demioToken = searchParams.get('token')

  const handleNavigate = () => {
    if (ready) {
      navigate('/events')
    }
  }

  const userLogin = async (data: TokenType) => {
    try {
      const { user }: any = await signInWithCustomToken(auth, data.token)
      AuthToken.set('access_token', user.accessToken)
    } catch (error) {
      alert(error)
    }
  }

  useEffect(() => {
    const checkSync = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/integrations/demio-integrations/event-sync?demioToken=${demioToken}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await user?.getIdToken(true)}`,
          },
        },
      )
      setDemioIntegrationId(data.demioIntegrationId)
      setUnsubscribed(data.unsubscribed)
      localStorage.setItem('unsubscribed', `${data.unsubscribed}`)
      setAlreadySynced(data.integrated)
    }

    checkSync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onStartTrial = async () => {
    axios.patch(
      `${BACKEND_API}/integrations/demio-integrations/demio/${demioIntegrationId}`,
      {
        demioIsNotified: true,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await user?.getIdToken(true)}`,
        },
      },
    )
    handleNavigate()
  }

  useEffect(() => {
    if (ready) {
      setTimeout(() => {
        setShow(false)
      }, 1500)
    }
  }, [ready])

  useEffect(() => {
    if (alreadySynced === null || unsubscribed === null) {
      return
    }

    const handleSync = async () => {
      const { data } = await axios.post(
        `${BACKEND_API}/integrations/demio-integrations/event-sync`,
        { demioToken: demioToken },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await user?.getIdToken(true)}`,
          },
        },
      )
      setDemioIntegrationId(data.demioIntegrationId)
      await userLogin(data)
      if (data.token) {
        setReady(true)
      }

      if (alreadySynced || unsubscribed) {
        navigate('/events')
      }
    }
    handleSync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadySynced, unsubscribed])

  return (
    <>
      {!alreadySynced && alreadySynced !== null && unsubscribed === false ? (
        <div className='loading_wrapper'>
          {show ? (
            <div className='loaderr'>
              <CircularStatic ready={ready} />
              {ready ? (
                <p className='loaderr_p'>Your free trial is ready</p>
              ) : (
                <p className='loaderr_p'>Your free trial is almost ready</p>
              )}

              <img
                className='loaderr_close'
                src={path}
                alt='close'
                onClick={() => setShow(false)}
              />
            </div>
          ) : (
            ''
          )}

          <div className='container'>
            <div className='loading_top'>
              <div className='loading_top_left'>
                <p className='loading_heading'>
                  Boost Registrations.
                  <br /> Award promoters. <br /> Drive engagement.
                </p>
                <p className='boost_p'>
                  Boost is your new favorite marketing colleague.
                  <br /> Through automated social proof and word of mouth,
                  <br /> your registrants become your promoters - amplifying
                  <br /> your message, and boosting your results.
                </p>
              </div>
              <div className='loading_top_right'>
                <img className='loading_top_right_big' src={loadingtopright} alt='boost' />
                <img className='loading_top_right_free' src={day} alt='free' />
                <img className='loading_top_right_1' src={icon1} alt='eventicon' />
                <img className='loading_top_right_2' src={icon2} alt='eventicon' />
                <img className='loading_top_right_3' src={icon3} alt='eventicon' />
                <img className='loading_top_right_4' src={icon4} alt='eventicon' />
              </div>
            </div>
            <div className='banner_end'>
              <p className='banner_end_big'>Get Started Free</p>
              <p className='banner_end_small'>
                By activating this Boost trial I accept the terms and conditions
                <a
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: '#ffff',
                    marginLeft: '5px',
                  }}
                  target={'_blank'}
                  rel={'noreferrer'}
                  href={'https://www.demio.com/terms-and-conditions'}
                >
                  [Terms and Conditions]
                </a>
                . I acknowledge that my Boost trial will convert to a paid plan after 14 days, and I
                understand that I have the right to cancel without payment before the end of my
                trial.
              </p>

              <button
                disabled={!ready}
                onClick={onStartTrial}
                className={ready ? 'starttrial' : 'starttrial_disabled'}
              >
                START TRIAL
              </button>
            </div>
            <div className='loading_middle'>
              <div className='loading_middle_left'>
                <img src={loading1} alt='loading_image' />
              </div>
              <div className='loading_middle_right'>
                <p className='loading_heading'>Drive registrants through....registrants.</p>
                <p className='boost_p_2'>
                  No, that&apos;s not a typo. With Boost, your registrants become your promotion
                  ambassadors - amplifying your message via social media and increasing your sign
                  ups. Customize the promotion content for Facebook, LinkedIn, Twitter, and Email,
                  making it easy for your registrants to endorse your content - with the click of a
                  button.
                </p>
              </div>
            </div>
            <div className='long_banner' onClick={onStartTrial}>
              <p> 14-Day Free Trial! 🚀</p>
            </div>
            <div className='pricing_banner'>
              <p>Pricing</p>
            </div>
            <p className='only'>
              Only available for purchase with a Demio plan. Pricing corresponds with your Demio
              plan.
            </p>

            <div className='line_info'>
              <div className='div long'>
                <p>Plan</p>
              </div>
              <div className='div'>
                <p>Room Size</p>
              </div>
              <div className='div'>
                <p>Monthly</p>
              </div>
              <div className='div'>
                <p>Annual</p>
              </div>
            </div>

            <div className='line_info2'>
              <div className='div long cream_bg'>
                <p className='greenp'>Starter</p>
              </div>
              <div className='div grey_bg'>
                <p className='black'>50</p>
              </div>
              <div className='div  black aa cream_bg '>
                <p className='greenp'>$19</p>
              </div>
              <div className='div aa  cream_bg'>
                <p className='greenp'>$149</p>
              </div>
            </div>

            <div className='secondtype'>
              <div className='secondtype_div1 cream_bg'>
                <p>Growth</p>
              </div>
              <div className='secondtype_div2'>
                <div className='secondtype_div2_line'>
                  <div className='secondtype_div2_line_div grey_bg'>
                    <p className='black'> 150</p>
                  </div>
                  <div className='secondtype_div2_line_div aa cream_bg'>
                    <p className='greenp'>$39</p>
                  </div>
                  <div className='secondtype_div2_line_div cream_bg'>
                    <p className='greenp'>$269</p>
                  </div>
                </div>
                <div className='secondtype_div2_line'>
                  <div className='secondtype_div2_line_div grey_bg'>
                    <p className='black'>500</p>
                  </div>
                  <div className='secondtype_div2_line_div cream_bg'>
                    <p className='greenp'>$79</p>
                  </div>
                  <div className='secondtype_div2_line_div cream_bg'>
                    <p className='greenp'>$659</p>
                  </div>
                </div>
                <div className='secondtype_div2_line'>
                  <div className='secondtype_div2_line_div grey_bg'>
                    <p className='black'>1000</p>
                  </div>
                  <div className='secondtype_div2_line_div cream_bg cream_bg'>
                    <p className='greenp'>$119</p>
                  </div>
                  <div className='secondtype_div2_line_div cream_bg'>
                    <p className='greenp'>$999</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='secondtype2'>
              <div className='secondtype2_div1 cream_bg'>
                <p>Premium</p>
              </div>
              <div className='secondtype2_div2'>
                <div className='secondtype2_div2_line'>
                  <div className='secondtype2_div2_line_div grey_bg'>
                    <p className='black'>500</p>
                  </div>
                  <div className='secondtype2_div2_line_div disabled_na empty_bg'>
                    <p>N/A</p>
                  </div>
                  <div className='secondtype2_div2_line_div cream_bg'>
                    <p className='greenp'>$1000</p>
                  </div>
                </div>
                <div className='secondtype2_div2_line'>
                  <div className='secondtype2_div2_line_div grey_bg'>
                    <p className='black'>1000</p>
                  </div>
                  <div className='secondtype2_div2_line_div disabled_na empty_bg'>
                    <p>N/A</p>
                  </div>
                  <div className='secondtype2_div2_line_div cream_bg'>
                    <p className='greenp'>$1300</p>
                  </div>
                </div>
                <div className='secondtype2_div2_line'>
                  <div className='secondtype2_div2_line_div grey_bg'>
                    <p className='black'>1500</p>
                  </div>
                  <div className='secondtype2_div2_line_div disabled_na empty_bg'>
                    <p>N/A</p>
                  </div>
                  <div className='secondtype2_div2_line_div cream_bg'>
                    <p className='greenp'>$1650</p>
                  </div>
                </div>
                <div className='secondtype2_div2_line'>
                  <div className='secondtype2_div2_line_div grey_bg'>
                    <p className='black'>3000</p>
                  </div>
                  <div className='secondtype2_div2_line_div disabled_na empty_bg'>
                    <p>N/A</p>
                  </div>
                  <div className='secondtype2_div2_line_div cream_bg'>
                    <p className='greenp'>$2650</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='loading_bottom'>
              <div className='loading_bottom_right'>
                <p className='loading_heading'>
                  Incentivize your promoters with rewards that matter to them
                </p>
                <p className='boost_p_2'>
                  Show the love for your promoters by rewarding them for the signups they bring in.
                  Create as <br /> many rewards as you&apos;d like, customize them all,
                  <br /> and decide how you&apos;d like the rewards to be <br /> received.
                  <br /> <br /> Running a partner webinar? Gift a lead list after your partner
                  brings in a certain number of registrants. <br /> <br /> Hosting a product launch?
                  Highest promoter <br /> gets a shiny new iPad.
                  <br /> <br /> Managing a ton of sponsors? Set up a ton of gifts!
                </p>
              </div>
              <div className='loading_bottom_left'>
                <img src={loadingBottom} alt='bottom_image' />
              </div>
            </div>
            <div className='banner_end'>
              <p className='banner_end_big'>Get Started Free</p>
              <p className='banner_end_small'>
                By activating this Boost trial I accept the terms and conditions
                <a
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: '#ffff',
                    marginLeft: '5px',
                  }}
                  target={'_blank'}
                  rel={'noreferrer'}
                  href={'https://www.demio.com/terms-and-conditions'}
                >
                  [Terms and Conditions]
                </a>
                . I acknowledge that my Boost trial will convert to a paid plan after 14 days, and I
                understand that I have the right to cancel without payment before the end of my
                trial.
              </p>

              <button
                disabled={!ready}
                onClick={onStartTrial}
                className={ready ? 'starttrial' : 'starttrial_disabled'}
              >
                START TRIAL
              </button>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default Loading
