import Insights from 'components/Insights/Insights'
import Registrants from 'components/Registrants/Registrants'
import { useState } from 'react'

import './Activity.scss'

const Activity = () => {
  const [active, setActive] = useState('/registrants')

  return (
    <div className='activity_wrapper'>
      <div className='flex_start'>
        <p
          onClick={() => setActive('/registrants')}
          className={active === '/registrants' ? 'list_navlink activelist ' : 'list_navlink'}
        >
          Registrants
        </p>
      </div>
      <div className='flex_center' style={{ marginTop: '100px' }}>
        {active === '/insights' ? <Insights /> : <Registrants />}
      </div>
    </div>
  )
}

export default Activity
