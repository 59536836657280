import { IconProps } from '../types/icon-types';

interface ShowcaseIconProps extends IconProps {
  size?: number;
}

export function ShowcaseIcon({ size = 20 }: ShowcaseIconProps) {
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.23 266.55">
      <g>
        <path
          fill="#024942"
          d="m246.39,62.3c-1.78-1.28-4.08-1.61-6.14-.9l-154.29,52.82c-2.75.94-4.59,3.52-4.59,6.42v139.13c0,2.19,1.06,4.25,2.84,5.52,1.17.83,2.55,1.26,3.94,1.26.74,0,1.48-.12,2.2-.36l154.29-52.82c2.75-.94,4.59-3.52,4.59-6.42V67.82c0-2.19-1.06-4.25-2.84-5.52Zm-10.73,139.8l-140.72,48.18v-124.79l140.72-48.18v124.79Z"
        />

        <path
          fill="#024942"
          d="m146.14,177.62c.69,0,1.39-.11,2.09-.33l12.94-4.18v13.6c0,3.75,3.04,6.78,6.78,6.78s6.78-3.04,6.78-6.78v-17.98l17.11-5.53c3.56-1.15,5.52-4.97,4.37-8.54-1.15-3.56-4.96-5.51-8.54-4.37l-12.94,4.18v-13.6c0-3.75-3.04-6.78-6.78-6.78s-6.78,3.04-6.78,6.78v17.98l-17.11,5.53c-3.56,1.15-5.52,4.97-4.37,8.54.93,2.87,3.59,4.7,6.45,4.7Z"
        />
        <path
          fill="#024942"
          d="m221.74,45.28c-1.22-3.55-5.09-5.43-8.61-4.22L58.84,93.88c-2.75.94-4.59,3.52-4.59,6.42v139.13c0,3.75,3.04,6.78,6.78,6.78s6.78-3.04,6.78-6.78V105.14l149.7-51.25c3.54-1.22,5.43-5.07,4.22-8.61Z"
        />
        <path
          fill="#024942"
          d="m194.61,24.93c-1.22-3.55-5.08-5.43-8.61-4.22L31.71,73.53c-2.75.94-4.59,3.52-4.59,6.42v139.13c0,3.75,3.04,6.78,6.78,6.78s6.78-3.04,6.78-6.78V84.8l149.7-51.25c3.54-1.22,5.43-5.07,4.22-8.61Z"
        />
        <path
          fill="#024942"
          d="m167.49,4.59c-1.22-3.55-5.08-5.43-8.61-4.22L4.59,53.19c-2.75.94-4.59,3.52-4.59,6.42v139.13c0,3.75,3.04,6.78,6.78,6.78s6.78-3.04,6.78-6.78V64.45L163.26,13.2c3.54-1.22,5.43-5.07,4.22-8.61Z"
        />
      </g>
    </svg>
  );
}
