import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { fetchEvents, fetchRegistrants } from 'api/api'

const initialState: EventsState = {
  events: [],
  registrants: [],
  currentEvent: {
    archived: false,
    demioId: 0,
    id: 0,
    onDemand: false,
    status: '',
    pastSessions: 0,
    registrationURL: '',
    type: '',
    upcomingSessions: 0,
    name: '',
    logoPath: '',
    customization: {
      componentAlignment: '',
      gradient: '',
      headerAlignment: '',
      id: '',
      imageKey: '',
      imageOriginalName: '',
      imageURL: '',
      updatedDate: '',
      opacity: '',
    },
  },
  currentRewards: [],
}
const eventSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setStatus: (state, { payload }) => {
      state.currentEvent.status = payload
    },

    setCurrentEvent: (state, { payload }) => {
      state.currentEvent = payload
    },
    setCurrentRewards: (state, { payload }) => {
      state.currentRewards = payload
    },
  },
  extraReducers: {
    [fetchEvents.fulfilled]: (state, { payload }) => {
      state.events = payload
    },
    [fetchRegistrants.fulfilled]: (state, { payload }) => {
      state.registrants = payload
    },
  },
})

export const getEvents = (state: RootState) => state.events.events
export const getRegistrants = (state: RootState) => state.events.registrants
export const getCurrentEvent = (state: RootState) => state.events.currentEvent
export const getCurrentRewards = (state: RootState) => state.events.currentRewards

export const { setStatus, setCurrentEvent, setCurrentRewards } = eventSlice.actions

export default eventSlice.reducer
