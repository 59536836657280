import { DropDownItemVariant } from '../../containers/navbar/navbar-dropdown-item/navbar-dropdown-item';

import { SxStyleType } from '../../types/theme-types';

interface DropdownItemStyleProps {
  hasSeparator?: boolean;
  variant: DropDownItemVariant;
}

type RuleName = 'dropdownItemContainer';

const getDropdownItemContainerHoverStyles = (variant?: DropDownItemVariant) => {
  switch (variant) {
    case DropDownItemVariant.Text:
      return { bgcolor: 'rgba(0, 0, 0, 0.04)', color: 'primary.main' };
    case DropDownItemVariant.Danger:
      return { bgcolor: 'redLighter', color: 'redLight' };
    case DropDownItemVariant.Success:
      return { bgcolor: 'greenLight', color: 'primary.main' };
    default:
      return 'rgba(0, 0, 0, 0.04)';
  }
};

export const dropdownItemStyles = ({ hasSeparator, variant }: DropdownItemStyleProps): SxStyleType<RuleName> => ({
  dropdownItemContainer: {
    padding: '12px 16px',
    fontSize: '14px',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
    borderTopWidth: hasSeparator ? '1px' : '',
    borderTopStyle: hasSeparator ? 'solid' : '',
    borderTopColor: hasSeparator ? 'gray100' : '',
    color: 'textDark',
    '&:hover': getDropdownItemContainerHoverStyles(variant),
  },
});
