import { FeatureProperty } from './unleashEnums'
import { ParsedToken } from 'firebase/auth'
import { useEffect } from 'react'

type ContextUpdaterFunction = (contextUpdate: Record<string, unknown>) => void

export function useUnleashContextUpdater(
  token: ParsedToken | undefined,
  updateContext: ContextUpdaterFunction,
) {
  useEffect(() => {
    const shouldUseCompanyName = false
    const selectedFeatureProperty = shouldUseCompanyName
      ? FeatureProperty.CompanyId
      : FeatureProperty.DemioId
    const contextUpdateData = {
      userId: token?.claims[selectedFeatureProperty],
    }
    updateContext(contextUpdateData)
  }, [updateContext, token?.claims])
}
