import React, { useState, useEffect, useCallback } from 'react'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useParams } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { ClipLoader } from 'react-spinners'
import { auth } from '../../firebase'
import { fetchReminderState, updateReminderSettings } from 'api/api'
import './style.scss'

interface CustomizeEmailReminderProps {
  expanded: string | false
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 506,
  bgcolor: 'background.paper',
  borderRadius: 2,
}

const reminderMessages = {
  off: {
    header: 'Are you sure you want to turn off the reminder?',
    description:
      'By turning off the toggle, the reminder email functionality will be disabled. This means that registrants will not receive a reminder email.',
  },
  on: {
    header: 'Are you sure you want to turn on the reminder?',
    description:
      'Send a promotion reminder email to contacts who have registered more than one week prior to your session. The one-time reminder will be sent exactly 7 days before your session is scheduled to start.',
  },
  moreInfo: {
    header: 'Reminder to Promote Your Session',
    description: '',
  },
}

const CustomizeEmailReminder: React.FC<CustomizeEmailReminderProps> = ({ expanded }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isReminderActive, setIsReminderActive] = useState(false)
  const [user] = useAuthState(auth)
  const { id } = useParams()
  const [modalHeader, setModalHeader] = useState('')
  const [moreInfoClicked, setMoreInfoClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
    setMoreInfoClicked(false)
  }

  const closeModal = () => setIsModalOpen(false)

  const toggleReminder = async () => {
    const token = await getToken()
    const updatedIsReminderActive = !isReminderActive
    setIsReminderActive(updatedIsReminderActive)
    setIsLoading(true)

    if (id && token) {
      try {
        await updateReminderSettings(id, updatedIsReminderActive, token)
      } finally {
        setIsLoading(false)
        closeModal()
      }
    }
  }

  const getToken = async () => user?.getIdToken(true)

  const updateModalHeader = useCallback(() => {
    if (moreInfoClicked) {
      setModalHeader(reminderMessages.moreInfo.header)
    } else if (!isReminderActive) {
      setModalHeader(reminderMessages.on.header)
    } else {
      setModalHeader(reminderMessages.off.header)
    }
  }, [isReminderActive, moreInfoClicked])

  useEffect(() => {
    const fetchData = async () => {
      const token = await getToken()
      if (token && id) {
        const data = await fetchReminderState(id, token)
        setIsReminderActive(data.reminderNotifications)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    updateModalHeader()
  }, [moreInfoClicked, isReminderActive, updateModalHeader])

  const reminderDescription =
    isReminderActive && !moreInfoClicked
      ? reminderMessages.off.description
      : reminderMessages.on.description

  return (
    <AccordionDetails
      sx={{ paddingTop: '50px' }}
      className={expanded ? 'bd_top_gray' : ''}
      data-intercom-target='emailPopup'
    >
      <div className='flex_between'>
        <p className='instant'>Reminder</p>
        <div className='switch'>
          <button
            className='edit_email'
            onClick={() => {
              openModal()
              setMoreInfoClicked(true)
            }}
            data-intercom-target='editEmail'
          >
            More Info
          </button>
          <Modal
            open={isModalOpen}
            onClose={closeModal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={modalStyle}>
              <div className='modal-main'>
                {isLoading ? (
                  <div className='loading-spinner' style={{ textAlign: 'center' }}>
                    <ClipLoader color={'#123abc'} loading={isLoading} size={35} />
                  </div>
                ) : (
                  <>
                    <p className='reminder-message'>{modalHeader}</p>
                    <p className='reminder-description'>{reminderDescription}</p>

                    <br />
                    <div className='flex_end'>
                      <button
                        data-intercom-target='emailSave'
                        className='cancel'
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        data-intercom-target='emailSave'
                        className='reminder-save'
                        onClick={toggleReminder}
                      >
                        {isReminderActive ? 'TURN OFF' : 'TURN ON'}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Box>
          </Modal>

          <div className='toggle-switch-email'>
            <input
              type='checkbox'
              className='toggle-switch-email-checkbox toggle-switch-checkbox'
              name='toggleSwitch'
              checked={isReminderActive}
              id='toggleSwitch'
              onChange={openModal}
            />
            <label className='toggle-switch-email-label toggle-switch-label' htmlFor='toggleSwitch'>
              <span className='toggle-switch-inner toggle-switch-inner-email'></span>
              <span className='toggle-switch-switch toggle-switch-switch-email'></span>
            </label>
          </div>
        </div>
      </div>
    </AccordionDetails>
  )
}

export default CustomizeEmailReminder
