import { SxStyleType } from '../../../types/theme-types';

type RuleNames = 'skeletonWrapper' | 'skeletonContentWrapper' | 'skeletonContainer';

export const skeletonLayoutStyles = (): SxStyleType<RuleNames> => ({
  skeletonContainer: {
    padding: '0 15px',
  },

  skeletonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  skeletonContentWrapper: {
    maxWidth: '71rem',
    width: '100%',
  },
});
