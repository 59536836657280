import ROUTES from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import './Event.scss'

interface EventProps {
  archived: boolean
  demioId: number
  id: number
  onDemand: boolean
  pastSessions: number
  registrationURL: string
  type: string
  upcomingSessions: number
  name: string
  unsubscribed: boolean | string | null
  status: string
}

const Event: React.FC<EventProps> = ({
  id,
  pastSessions,
  upcomingSessions,
  name,
  status,
  type,
  archived,
  unsubscribed,
}) => {
  const navigate = useNavigate()

  return (
    <div
      className={unsubscribed ? 'eventdisabled' : 'event'}
      onClick={() => {
        if (unsubscribed) {
          return null
        } else {
          navigate(`${ROUTES.BOOST}/event/${id}`, {
            state: { page: '/audience', id: id },
          })
        }
      }}
    >
      <div className='event_left'>
        <div className='flex_start'>
          <p className='standard'>{type}</p>
        </div>
        <div className='flex_start'>
          {status === 'Not Started' ? (
            <div className='grey'></div>
          ) : status === 'Launched' ? (
            <div className='green'></div>
          ) : (
            <div className='yellow'></div>
          )}
          <p title={name} className='event_name'>
            {name}
          </p>
        </div>
        <div className='flex_start'>
          <p className='session'>
            {upcomingSessions} {upcomingSessions === 1 ? 'upcoming session' : 'upcoming sessions'} |{' '}
            {pastSessions} {pastSessions === 1 ? 'past session' : 'past sessions'}
          </p>
        </div>
      </div>
      {!archived && (
        <div className='event_right'>
          {status === 'Not Started' ? (
            <button
              className={unsubscribed ? 'setupdisabled' : 'setup'}
              data-intercom-target='setupButton'
              onClick={() => {
                if (unsubscribed) {
                  return null
                } else {
                  navigate(`${ROUTES.BOOST}/event/${id}`, {
                    state: { page: '/audience', id: id },
                  })
                }
              }}
            >
              SET UP
            </button>
          ) : (
            <button
              className={unsubscribed ? 'disabledmanage' : 'manage'}
              onClick={() => {
                if (unsubscribed) {
                  return null
                } else {
                  navigate(`${ROUTES.BOOST}/event/${id}`, {
                    state: { page: '/audience', id: id },
                  })
                }
              }}
            >
              MANAGE
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default Event
