import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './assets/style.scss'
import store from './features/store'

import { FlagProvider } from '@unleash/proxy-client-react'
import { unleashConfig } from './libs/unleash/unleash-config'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <FlagProvider config={unleashConfig}>
      <Provider store={store}>
        <App />
      </Provider>
    </FlagProvider>
  </BrowserRouter>,
)
