import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { useParams } from 'react-router-dom'
import { auth } from '../../firebase'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import FacebookTemplate from 'components/Templates/FacebookTemplate/FacebookTemplate'
import GmailTemplate from 'components/Templates/GmailTemplate/GmailTemplate'
import LinkedinTemplate from './LinkedinTemplate/LinkedinTemplate'
import ChoosePlatformModal from './Modals/ChoosePlatformModal'
import MissingFieldsModal from './Modals/MissingFieldsModal'
import TwitterTemplate from './TwitterTemplate/TwitterTemplate'

import {
  updateFacebookData,
  updateGmailData,
  updateLinkedinData,
  updateTwitterData,
} from './socialMediaApi'

import { fetchFacebook, fetchGmail, fetchLinkedIn, fetchTwitter } from 'api/api'
import { getFacebookData, setFacebookData } from 'features/slices/templates/facebookSlice'
import { getGmailData, setGmailData } from 'features/slices/templates/gmailSlice'
import { getLinkedInData, setLinkedinData } from 'features/slices/templates/linkedinSlice'
import { getTwitterData, setTwitterData } from 'features/slices/templates/twitterSlice'

import { ThunkDispatch } from '@reduxjs/toolkit'
import { RootState } from 'features/store'

import facebookicon from 'assets/images/facebookicon.svg'
import gmailicon from 'assets/images/gmailicon.svg'
import linkedin from 'assets/images/linkedin.svg'
import templateicon from 'assets/images/templateicon.svg'
import twittericon from 'assets/images/twittericon.svg'

import './Templates.scss'

type SocialPlatform = 'linkedin' | 'facebook' | 'twitter' | 'gmail'

interface TemplateProps {
  handleChange?: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void
  expanded?: string | false
}
type MyThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>

const Templates: React.FC<TemplateProps> = ({ handleChange, expanded }) => {
  const [platformModalOpen, setPlatformModalOpen] = useState(false)
  const [isMissingFieldsModalOpen, setMissingFieldsModalOpen] = useState(false)

  const [active, setActive] = useState('/linkedin')
  const [user] = useAuthState(auth)

  const dispatch = useDispatch<MyThunkDispatch>()

  const linkedinData = useSelector(getLinkedInData)
  const facebookData = useSelector(getFacebookData)
  const twitterData = useSelector(getTwitterData)
  const gmailData = useSelector(getGmailData)

  const [linkedinDataModified, setLinkedinDataModified] = useState(false)
  const [facebookDataModified, setFacebookDataModified] = useState(false)
  const [twitterDataModified, setTwitterDataModified] = useState(false)
  const [gmailDataModified, setGmailDataModified] = useState(false)

  const { id } = useParams()

  const openPlatformModal = () => setPlatformModalOpen(true)
  const closePlatformModal = () => setPlatformModalOpen(false)

  const openMissingFieldsModal = () => setMissingFieldsModalOpen(true)
  const closeMissingFieldsModal = () => setMissingFieldsModalOpen(false)

  useEffect(() => {
    if (id) {
      dispatch(fetchLinkedIn(id))
      dispatch(fetchFacebook(id))
      dispatch(fetchTwitter(id))
      dispatch(fetchGmail(id))
    }
  }, [dispatch, id])

  const handleValidate = () => {
    const areAllFieldsEmpty =
      !linkedinData.title ||
      !facebookData.title ||
      !twitterData.title ||
      !gmailData.title ||
      !gmailData.subject

    if (
      (linkedinDataModified || facebookDataModified || twitterDataModified || gmailDataModified) &&
      areAllFieldsEmpty &&
      linkedinData.isActive &&
      facebookData.isActive &&
      twitterData.isActive &&
      gmailData.isActive
    ) {
      openMissingFieldsModal()
    } else {
      updateDataSources()
    }
  }

  const updateDataSources = async () => {
    const token = await user?.getIdToken(true)
    if (!token) return

    const dataToUpdate = [
      { isModified: linkedinDataModified, updateFunction: updateLinkedinData, data: linkedinData },
      { isModified: facebookDataModified, updateFunction: updateFacebookData, data: facebookData },
      { isModified: twitterDataModified, updateFunction: updateTwitterData, data: twitterData },
      { isModified: gmailDataModified, updateFunction: updateGmailData, data: gmailData },
    ]

    for (const item of dataToUpdate) {
      if (item.isModified) {
        await item.updateFunction(item.data.id, item.data as GmailTemplateType, token)
        item.isModified = false
      }
    }
  }

  const handleCheckbox = async (data: TemplateType, platform: SocialPlatform) => {
    const dispatchFunctions = {
      linkedin: setLinkedinData,
      facebook: setFacebookData,
      twitter: setTwitterData,
      gmail: setGmailData,
    }

    const checkboxDataMap = {
      linkedin: setLinkedinDataModified,
      facebook: setFacebookDataModified,
      twitter: setTwitterDataModified,
      gmail: setGmailDataModified,
    }

    const activeCheckboxes = [linkedinData, facebookData, twitterData, gmailData].filter(
      (checkboxData) => checkboxData.isActive,
    )

    if (activeCheckboxes.length === 1 && data.isActive) {
      openPlatformModal()
      return
    }

    const updatedData = {
      ...data,
      isActive: !data.isActive,
    }

    dispatch(dispatchFunctions[platform](updatedData))

    if (checkboxDataMap[platform]) {
      checkboxDataMap[platform](true)
    }
  }

  const getExpandedChangeHandler = (panel: string) => {
    if (handleChange) {
      return handleChange?.(panel)
    }
  }

  return (
    <div className='templates_wrapper'>
      <MissingFieldsModal open={isMissingFieldsModalOpen} onClose={closeMissingFieldsModal} />

      <ChoosePlatformModal open={platformModalOpen} onClose={closePlatformModal} />

      <div className='container'>
        <Accordion
          className={expanded === 'panel4' ? 'bd_top' : 'accordionhover'}
          sx={{ width: '100%', padding: '12px' }}
          expanded={expanded === 'panel4'}
          onChange={getExpandedChangeHandler('panel4')}
        >
          <AccordionSummary
            aria-controls='panel1a-content'
            id='panel1a-header'
            data-intercom-target='customizeSocialSharingOptions'
          >
            <div className='flex_start'>
              <img src={templateicon} alt='emailicon' style={{ width: '36px', height: '36px' }} />
              <div className='flex_column' style={{ marginLeft: '30px' }}>
                <p className='acordion_heading'>Social Sharing Options</p>
                <p className='acordion_about'>Customize sharing options</p>
              </div>
            </div>
            <div className='flex_end'>
              {expanded === 'panel4' && (
                <button onClick={handleValidate} className='done'>
                  DONE
                </button>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails className={expanded ? 'bd_top_gray' : ''} sx={{ padding: '0' }}>
            <div className='flex_between'>
              <div className='left' data-intercom-target='templatesColumn'>
                <div className='flex_center'>
                  <button className='templateicon' onClick={() => setActive('/linkedin')}>
                    <img
                      src={linkedin}
                      id={active === '/linkedin' ? 'activeIcon' : ''}
                      alt='linkedin'
                    />
                    LinkedIn
                  </button>
                  <div className='toggle-switch'>
                    <input
                      type='checkbox'
                      checked={linkedinData.isActive}
                      className='toggle-switch-checkbox'
                      name='toggleSwitchLinkedin'
                      id='toggleSwitchLinkedin'
                      onChange={() => handleCheckbox(linkedinData, 'linkedin')}
                    />
                    <label className='toggle-switch-label' htmlFor='toggleSwitchLinkedin'>
                      <span className='toggle-switch-inner'></span>
                      <span className='toggle-switch-switch'></span>
                    </label>
                  </div>
                </div>
                <div className='flex_center'>
                  <button className='templateicon' onClick={() => setActive('/facebook')}>
                    <img
                      src={facebookicon}
                      id={active === '/facebook' ? 'activeIcon' : ''}
                      alt='facebook'
                    />
                    Facebook
                  </button>
                  <div className='toggle-switch'>
                    <input
                      type='checkbox'
                      checked={facebookData.isActive}
                      className='toggle-switch-checkbox'
                      name='toggleSwitchFacebook'
                      id='toggleSwitchFacebook'
                      onChange={() => handleCheckbox(facebookData, 'facebook')}
                    />

                    <label className='toggle-switch-label' htmlFor='toggleSwitchFacebook'>
                      <span className='toggle-switch-inner'></span>
                      <span className='toggle-switch-switch'></span>
                    </label>
                  </div>
                </div>

                <div className='flex_center'>
                  <button className='templateicon' onClick={() => setActive('/twitter')}>
                    <img
                      src={twittericon}
                      id={active === '/twitter' ? 'activeIcon' : ''}
                      alt='twitter'
                    />
                    Twitter
                  </button>
                  <div className='toggle-switch'>
                    <input
                      type='checkbox'
                      checked={twitterData.isActive}
                      className='toggle-switch-checkbox'
                      name='toggleSwitchTwitter'
                      id='toggleSwitchTwitter'
                      onChange={() => handleCheckbox(twitterData, 'twitter')}
                    />
                    <label className='toggle-switch-label' htmlFor='toggleSwitchTwitter'>
                      <span className='toggle-switch-inner'></span>
                      <span className='toggle-switch-switch'></span>
                    </label>
                  </div>
                </div>
                <div className='flex_center'>
                  <button className='templateicon' onClick={() => setActive('/gmail')}>
                    <img src={gmailicon} id={active === '/gmail' ? 'activeIcon' : ''} alt='gmail' />
                    Email
                  </button>
                  <div className='toggle-switch'>
                    <input
                      type='checkbox'
                      checked={gmailData.isActive}
                      className='toggle-switch-checkbox'
                      name='toggleSwitchEmail'
                      id='toggleSwitchEmail'
                      onChange={() => handleCheckbox(gmailData, 'gmail')}
                    />
                    <label className='toggle-switch-label' htmlFor='toggleSwitchEmail'>
                      <span className='toggle-switch-inner'></span>
                      <span className='toggle-switch-switch'></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='right'>
                {(() => {
                  switch (active) {
                    case '/linkedin':
                      return (
                        <LinkedinTemplate
                          setLinkedinData={(updatedData) => {
                            dispatch(setLinkedinData(updatedData))
                            setLinkedinDataModified(true)
                          }}
                          linkedinData={linkedinData}
                          linkedinDataModified={linkedinDataModified}
                          setLinkedinDataModified={setLinkedinDataModified}
                        />
                      )

                    case '/facebook':
                      return (
                        <FacebookTemplate
                          setFacebookData={(updatedData) => {
                            dispatch(setFacebookData(updatedData))
                            setFacebookDataModified(true)
                          }}
                          facebookData={facebookData}
                          setFacebookDataModified={setFacebookDataModified}
                        />
                      )

                    case '/gmail':
                      return (
                        <GmailTemplate
                          setGmailData={(updatedData) => {
                            dispatch(setGmailData(updatedData))
                            setGmailDataModified(true)
                          }}
                          gmailData={gmailData}
                          setGmailDataModified={setGmailDataModified}
                        />
                      )

                    case '/twitter':
                      return (
                        <TwitterTemplate
                          setTwitterData={(updatedData) => {
                            dispatch(setTwitterData(updatedData))
                            setTwitterDataModified(true)
                          }}
                          twitterData={twitterData}
                          setTwitterDataModified={setTwitterDataModified}
                        />
                      )

                    default:
                      return null
                  }
                })()}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}

export default Templates
