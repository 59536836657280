import { PrimaryDropdown } from '../../../components/primary-dropdown/primary-dropdown';
import { NavbarDropdownItem } from '../navbar-dropdown-item/navbar-dropdown-item';

import {
  DropdownOptionType,
  PrimaryDropdownVariant,
} from '../../../components/primary-dropdown/primary-dropdown-types';

import { navbarQuestionsDropdownStyles } from './navbar-questions-dropdown.styles';

import { Button, Stack, Typography } from '@mui/material';
import { DropdownItem } from '../../../components/dropdown-item/dropdown-item';
import { ChatIcon } from '../../../icons/chat-icon';
import { KnowledgeIcon } from '../../../icons/knowledge-icon';
import { ExternalLinkIcon } from '../../../icons/external-link-icon';
import { UniversityHatIcon } from '../../../icons/university-hat-icon';
import { useIntercom } from 'react-use-intercom';
import { Products } from '../../../constants/products-constants';

const NavbarChat = () => {
  const { show } = useIntercom();
  return (
    <Button
      onClick={show}
      sx={{ width: '100%', textAlign: 'left', padding: 0, display: 'block', textTransform: 'unset', fontSize: '20px' }}
    >
      <NavbarDropdownItem
        productVariant={Products.Showcase}
        icon={<ChatIcon />}
        header={'Chat With Us'}
        description={'We’re available to chat 24/7.'}
      />
    </Button>
  );
};

const NavbarKnowledge = () => {
  return (
    <NavbarDropdownItem
      productVariant={Products.Showcase}
      icon={<KnowledgeIcon />}
      description={'How-to articles about using Demio.'}
      header={
        <>
          Knowledge Base <ExternalLinkIcon />
        </>
      }
    />
  );
};

const DemioUniversity = () => {
  return (
    <NavbarDropdownItem
      icon={<UniversityHatIcon />}
      productVariant={Products.Showcase}
      description={'Learn webinar strategies & tactics.'}
      header={
        <>
          Demio University <ExternalLinkIcon />
        </>
      }
    />
  );
};

const ContactDemio = () => {
  return (
    <>
      <div style={{ backgroundColor: 'rgb(239, 243, 246)', width: '100%', height: '1px' }} />
      <DropdownItem>
        <span className="emailUs" style={{ color: '#9FA6AD', fontWeight: 500 }}>
          Or email us at
        </span>{' '}
        <Typography sx={{ color: 'primary.main' }} component={'span'}>
          support@demio.com
        </Typography>
      </DropdownItem>
    </>
  );
};

const NavbarHelpContentList: DropdownOptionType[] = [
  {
    id: 0,
    content: <NavbarChat />,
    variant: PrimaryDropdownVariant.Standard,
  },
  {
    id: 1,
    link: 'https://help.demio.com/en/',
    content: <NavbarKnowledge />,
    variant: PrimaryDropdownVariant.Link,
  },
  {
    id: 2,
    link: 'https://university.demio.com/',
    content: <DemioUniversity />,
    variant: PrimaryDropdownVariant.Link,
  },
  {
    id: 3,
    link: 'mailto:support@demio.com',
    content: <ContactDemio />,
    variant: PrimaryDropdownVariant.Link,
  },
];

const DropdownButtonContent = () => {
  const styles = navbarQuestionsDropdownStyles();

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={styles.buttonWrapper}>
      <Typography sx={{ fontWeight: 500 }}>Help</Typography>
    </Stack>
  );
};

export function NavbarQuestionsDropdown() {
  const { isOpen: isIntercomOpen } = useIntercom();

  return (
    <PrimaryDropdown
      arrowSettings={{ color: 'currentColor' }}
      variant={PrimaryDropdownVariant.Link}
      contentTopSpace={92}
      wrapperSx={{ width: '280px', padding: 0 }}
      isDropdownOpen={!isIntercomOpen}
      options={NavbarHelpContentList}
      buttonContent={<DropdownButtonContent />}
      bottomLineDistance={30}
      buttonContentWrapperSx={{
        color: 'rgba(0,0,0,0.65)',
        '&:hover': {
          color: 'primary.main',
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    />
  );
}
