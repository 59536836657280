import { IconProps } from '../types/icon-types';

export function DollarIcon({ color = 'currentColor' }: IconProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="demio-icon "
      style={{ fontSize: '20px' }}
    >
      <path
        opacity="0.4"
        d="M10 .313A9.686 9.686 0 00.312 10 9.686 9.686 0 0010 19.688 9.686 9.686 0 0019.688 10 9.686 9.686 0 0010 .312zM10.938 15v.625a.624.624 0 01-.626.625h-.624a.625.625 0 01-.626-.625v-.633a3.47 3.47 0 01-1.812-.59.627.627 0 01-.09-.984l.469-.441c.21-.2.52-.211.77-.063.235.139.503.212.777.211h1.758c.441 0 .8-.41.8-.914 0-.414-.246-.777-.593-.887l-2.82-.855c-1.133-.344-1.923-1.446-1.923-2.68 0-1.535 1.196-2.785 2.665-2.789v-.625a.625.625 0 01.624-.625h.626a.625.625 0 01.624.625v.633a3.47 3.47 0 011.813.59c.34.23.39.707.09.984l-.469.441c-.21.2-.52.211-.77.063a1.52 1.52 0 00-.777-.211H9.066c-.441 0-.8.41-.8.914 0 .414.246.777.593.887l2.813.855c1.133.344 1.922 1.446 1.922 2.68.008 1.535-1.188 2.781-2.656 2.789z"
        fill={color}
      ></path>
      <path
        d="M9.063 5v-.625a.625.625 0 01.624-.625h.626a.625.625 0 01.624.625v.633a3.47 3.47 0 011.813.59c.34.23.39.707.09.984l-.469.441c-.21.2-.52.211-.77.063a1.52 1.52 0 00-.777-.211H9.066c-.441 0-.8.41-.8.914 0 .414.246.777.593.887l2.813.855c1.133.344 1.922 1.446 1.922 2.68.008 1.535-1.188 2.781-2.656 2.789v.625a.625.625 0 01-.626.625h-.625a.625.625 0 01-.624-.625v-.633a3.47 3.47 0 01-1.813-.59.627.627 0 01-.09-.984l.469-.441c.21-.2.52-.211.77-.063.235.139.503.212.777.211h1.758c.441 0 .8-.41.8-.914 0-.414-.246-.777-.593-.887l-2.82-.855c-1.133-.344-1.923-1.446-1.923-2.68 0-1.535 1.196-2.785 2.665-2.789z"
        fill={color}
      ></path>
    </svg>
  );
}
