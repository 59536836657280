import { Stack, Typography } from '@mui/material';

import { ShowcaseIcon } from '../../../icons/showcase-icon';
import { BoostIcon } from '../../../icons/boost-icon';
import { DropDownItemVariant, NavbarDropdownItem } from '../navbar-dropdown-item/navbar-dropdown-item';
import { navbarQuestionsDropdownStyles } from '../navbar-questions-dropdown/navbar-questions-dropdown.styles';
import { PrimaryDropdown } from '../../../components/primary-dropdown/primary-dropdown';
import { PrimaryDropdownVariant } from '../../../components/primary-dropdown/primary-dropdown-types';
import { getExternalLinks } from '../../../constants/link-constants';
import { Products } from '../../../constants/products-constants';
import { Colors } from '../../../constants/style.constants';

const BoostDropdownItem = ({ productVariant }: { productVariant: Products }) => {
  return (
    <NavbarDropdownItem
      iconAlignment={'flex-start'}
      productVariant={productVariant}
      variant={productVariant === Products.Boost ? DropDownItemVariant.Text : DropDownItemVariant.Success}
      icon={<BoostIcon />}
      header={'Boost Registration'}
      description={'Grow and engage your audience from registration to attendance'}
    />
  );
};

const ShowcaseDropdownItem = ({ productVariant }: { productVariant: Products }) => {
  return (
    <NavbarDropdownItem
      productVariant={productVariant}
      variant={productVariant === Products.Boost ? DropDownItemVariant.Text : DropDownItemVariant.Success}
      icon={<ShowcaseIcon color={Colors.DemioColor} />}
      iconAlignment={'flex-start'}
      header={
        <>
          Showcase{' '}
          <Typography
            component="span"
            sx={{
              background: 'rgb(87, 142, 243)',
              borderRadius: '20px',
              color: 'rgb(255, 255, 255)',
              fontFamily: 'Lato',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: '20px',
              letterSpacing: '0.25px',
              marginLeft: '4px',
              padding: '0px 8px 2px',
              textTransform: 'capitalize',
            }}
          >
            Beta
          </Typography>
        </>
      }
      description={'Automatically sync your events to your website landing page'}
    />
  );
};

const getNavbarProductsList = ({
  demioUrl,
  isTrial,
  productVariant,
}: {
  demioUrl: string;
  isTrial: boolean;
  productVariant: Products;
}) => {
  const externalUrl = getExternalLinks(demioUrl);

  const { BoostMainPage, BoostPage, ShowcasePage } = externalUrl;
  const selectedBoostLink = isTrial ? BoostMainPage : BoostPage;

  return [
    {
      id: 0,
      link: selectedBoostLink,
      content: <BoostDropdownItem productVariant={productVariant} />,
      variant: PrimaryDropdownVariant.Link,
      openBlankPage: true,
    },
    {
      id: 1,
      link: ShowcasePage,
      content: <ShowcaseDropdownItem productVariant={productVariant} />,
      variant: PrimaryDropdownVariant.Link,
      openBlankPage: true,
    },
  ];
};

const DropdownButtonContent = () => {
  const styles = navbarQuestionsDropdownStyles();

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={styles.buttonWrapper}>
      <Typography sx={{ fontWeight: 500 }}>Products</Typography>
    </Stack>
  );
};

export function NavbarProductsDropdown({
  productVariant,
  demioUrl,
  isTrial,
}: {
  productVariant: Products;
  demioUrl: string;
  isTrial: boolean;
}) {
  return (
    <PrimaryDropdown
      variant={PrimaryDropdownVariant.Link}
      arrowSettings={{ color: 'currentColor' }}
      buttonContentWrapperSx={{
        color: 'rgba(0,0,0,0.65)',
        '&:hover': {
          color: 'primary.main',
        },
      }}
      contentTopSpace={productVariant === Products.Showcase ? 92 : 90}
      wrapperSx={{ width: '280px', padding: 0, height: '100%' }}
      options={getNavbarProductsList({ demioUrl, isTrial, productVariant })}
      buttonContent={<DropdownButtonContent />}
      bottomLineDistance={productVariant === Products.Showcase ? 30 : 32}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    />
  );
}
