import { useUnleashContext } from '@unleash/proxy-client-react'
import ROUTES from 'constants/routes'
import { ParsedToken } from 'firebase/auth'
import Events from 'pages/Events/Events'
import Loading from 'pages/Loading/Loading'
import Share from 'pages/Share/Share'
import { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Helmet } from 'react-helmet'
import { hotjar } from 'react-hotjar'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import { auth } from '../src/firebase'
import { useUnleashContextUpdater } from './libs/unleash/unleashContext'

import { createTheme, ThemeProvider } from '@mui/material'
import { ThemeOptions } from '@mui/material/styles'
import { LogoutPage } from './pages/Logout/Logout'

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || ''
const hotjarEnv = process.env.REACT_APP_HOTJAR_ENABLED
const heapEnabled = process.env.REACT_APP_HEAP_ENABLED

const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: ['Reader', 'sans-serif', 'lato'].join(','),
    allVariants: {
      fontFamily: ['Reader', 'sans-serif', 'lato'].join(','),
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 400,
    },
  },
  palette: {
    greenLight: '#eefdf6',
    redLight: '#EE4C3B',
    redLighter: '#fffafa',
    gray100: '#EFF3F6',
    gray300: '#9FA6AD',
    teal500: '#1D4842',
    textDark: '#5D676B',
    primary: {
      main: '#1D4842',
    },
  },
}

const theme = createTheme(themeOptions)

const App = () => {
  const [user, loading] = useAuthState(auth)
  const [tokenResult, setTokenResult] = useState<ParsedToken>()
  const [darkMode, setDarkMode] = useState(false)
  const [searchParams] = useSearchParams()
  const boostUniqueCode = searchParams.get('boost_unique_code')

  useEffect(() => {
    if (hotjarEnv === 'true') {
      hotjar.initialize(3320921, 6)
    }
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)')
    setDarkMode(darkModeQuery.matches)
    darkModeQuery.addEventListener('change', (e) => {
      setDarkMode(e.matches)
    })
  }, [])

  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]')
    favicon?.setAttribute('href', darkMode ? '/faviconDark.svg' : '/favicon.svg')
  }, [darkMode])

  useEffect(() => {
    if (loading) {
      return
    }
    const fetchClaim = async () => {
      const idTokenResult = await user?.getIdTokenResult()
      setTokenResult(idTokenResult)
    }
    fetchClaim()
  }, [loading, user])

  const { heap = {} } = window as any

  useEffect(() => {
    if (heapEnabled) {
      if (heap && tokenResult) {
        heap?.addUserProperties?.({
          email: tokenResult?.claims?.email,
          eventToPromoterId: boostUniqueCode,
        })
      } else if (heap?.addUserProperties) {
        heap?.addUserProperties?.({
          eventToPromoterId: boostUniqueCode,
        })
      }
    }
  }, [boostUniqueCode, heap, tokenResult])

  const updateContext = useUnleashContext()
  useUnleashContextUpdater(tokenResult, updateContext)

  return (
    <ThemeProvider theme={theme}>
      {heapEnabled === 'true' ? (
        <Helmet>
          <script type='text/javascript'>
            {` ;(window.heap = window.heap || []),
          (heap.load = function (e, t) {
            ;(window.heap.appid = e), (window.heap.config = t = t || {})
            var r = document.createElement('script')
            ;(r.type = 'text/javascript'),
              (r.async = !0),
              (r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js')
            var a = document.getElementsByTagName('script')[0]
            a.parentNode.insertBefore(r, a)
            for (
              var n = function (e) {
                  return function () {
                    heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                  }
                },
                p = [
                  'addEventProperties',
                  'addUserProperties',
                  'clearEventProperties',
                  'identify',
                  'resetIdentity',
                  'removeEventProperty',
                  'setEventProperties',
                  'track',
                  'unsetEventProperty',
                ],
                o = 0;
              o < p.length;
              o++
            )
              heap[p[o]] = n(p[o])
          })
            heap.load('1881840299');
       `}
          </script>
        </Helmet>
      ) : (
        ''
      )}

      {tokenResult ? (
        <IntercomProvider
          autoBoot={true}
          appId={INTERCOM_APP_ID}
          autoBootProps={{
            name: String(tokenResult?.claims?.name) || undefined,
            email: String(tokenResult?.claims.email) || undefined,
            userId: String(tokenResult?.claims.demioId) || undefined,
            createdAt: String(tokenResult?.claims.auth_time) || undefined,
            userHash: String(tokenResult?.claims.intercomUserHash) || undefined,
            actionColor: '#1D4842',
            backgroundColor: '#1D4842',
          }}
        >
          <Routes>
            <Route path={ROUTES.BOOST} element={<Events />} />
            <Route path={`${ROUTES.BOOST}/event/:id`} element={<Events />} />
            <Route path={ROUTES.SHARE} element={<Share />} />
            <Route path={`${ROUTES.SHARE}/:id`} element={<Share />} />
            <Route path={ROUTES.EVENTSYNC} element={<Loading />} />
            <Route path='/' element={<Navigate to={ROUTES.EVENTSYNC} />} />
            <Route path={ROUTES.LOGOUT} element={<LogoutPage />} />
            <Route path='*' element={<Navigate to={ROUTES.BOOST} />} />
          </Routes>
        </IntercomProvider>
      ) : (
        <Routes>
          <Route path={ROUTES.BOOST} element={<Events />} />
          <Route path={`${ROUTES.BOOST}/event/:id`} element={<Events />} />
          <Route path={ROUTES.SHARE} element={<Share />} />
          <Route path={`${ROUTES.SHARE}/:id`} element={<Share />} />
          <Route path={ROUTES.EVENTSYNC} element={<Loading />} />
          <Route path='/' element={<Navigate to={ROUTES.EVENTSYNC} />} />
          <Route path={ROUTES.LOGOUT} element={<LogoutPage />} />
          <Route path='*' element={<Navigate to={ROUTES.BOOST} />} />
        </Routes>
      )}
    </ThemeProvider>
  )
}

export default App
