import * as React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Typography } from '@mui/material'

interface DropdownSelectProps {
  title: string
  values: Array<string>
  selectedValues: CustomizationTemplateType
  setSelectedValues: React.Dispatch<React.SetStateAction<CustomizationTemplateType>>
  setChanged: React.Dispatch<React.SetStateAction<boolean>>
}

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  title,
  values,
  selectedValues,
  setSelectedValues,
  setChanged,
}) => {
  const optionalSetter = (
    title: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    switch (title) {
      case 'Header Alignment:':
        setSelectedValues((prev) => ({
          ...prev,
          headerAlignment: e.target.value,
        }))
        break
      case 'Theme:':
        setChanged(true)
        setSelectedValues((prev) => ({
          ...prev,
          theme: e.target.value,
        }))
        break
      case 'Component Alignment:':
        setSelectedValues((prev) => ({
          ...prev,
          componentAlignment: e.target.value,
        }))
        break
      case 'Primary Font Size:':
        setSelectedValues((prev) => ({
          ...prev,
          primaryFontSize: e.target.value,
        }))
        break
      case 'Secondary Font Size:':
        setSelectedValues((prev) => ({
          ...prev,
          secondaryFontSize: e.target.value,
        }))
        break
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        sx={{ marginTop: title === 'Header Alignment:' ? '0px' : '30px', marginBottom: '20px' }}
      >
        {title}
      </Typography>
      <FormControl fullWidth>
        <Select
          sx={{ color: '#5D676B', fontSize: '14px' }}
          MenuProps={{
            PaperProps: {
              sx: {
                '&:focus': {
                  outline: 'none',
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #484850',
                  borderRadius: '5px 5px 0 0',
                },
                '& .MuiMenuItem-root.Mui-selected': {
                  backgroundColor: '#FAFAFA',
                },
                '& .MuiMenuItem-root:hover': {
                  backgroundColor: 'rgba(100, 173, 163, 0.1);',
                },
                '& .MuiMenuItem-root.Mui-selected:hover': {
                  backgroundColor: 'rgba(100, 173, 163, 0.1);',
                },
              },
            },
          }}
          id='demo-simple-select'
          value={
            title === 'Header Alignment:'
              ? selectedValues.headerAlignment
              : title === 'Theme:'
              ? selectedValues.theme
              : title === 'Component Alignment:'
              ? selectedValues.componentAlignment
              : title === 'Primary Font Size:'
              ? selectedValues.primaryFontSize
              : title === 'Secondary Font Size:'
              ? selectedValues.secondaryFontSize
              : ''
          }
          onChange={(e) => optionalSetter(title, e as React.ChangeEvent<HTMLInputElement>)}
        >
          {values?.map((value: string) => {
            return (
              <MenuItem key={value} value={value} sx={{ fontSize: '14px' }}>
                {value === '12px'
                  ? 'Small (12px)'
                  : value === '13px'
                  ? 'Small (13px)'
                  : value === '16px'
                  ? 'Medium (16px)'
                  : value === '24px'
                  ? 'Large (24px)'
                  : value}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default DropdownSelect
