import ListRegistrants from 'components/ListRegistrants/ListRegistrants'
import './Audience.scss'

const Audience = () => {
  return (
    <div className='audience_wrapper' style={{ marginTop: '20px' }}>
      <div className='container'>
        <div className='line' style={{ marginTop: '20px' }}></div>
        <ListRegistrants />
      </div>
    </div>
  )
}

export default Audience
