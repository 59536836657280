import { useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import * as ChartDataLabels from 'chartjs-plugin-datalabels'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import axios from 'axios'
import { useParams } from 'react-router-dom'

interface GraphDataItem {
  count: number
  id: number
  label: string
}

const BACKEND_API = process.env.REACT_APP_BACKEND_API

const graphDataInitialState = [
  {
    id: 1,
    count: 0,
    label: 'Linkedin',
  },
  {
    id: 2,
    count: 0,
    label: 'Facebook',
  },
  {
    id: 3,
    count: 0,
    label: 'Twitter',
  },
  {
    id: 4,
    count: 0,
    label: 'Email',
  },
]

const BarComponent = () => {
  const [user, loading] = useAuthState(auth)

  const { id } = useParams()
  const [graphData, setGraphData] = useState<GraphDataItem[]>(graphDataInitialState)

  useEffect(() => {
    if (loading) {
      return
    }
    const getEventToPromoterEmail = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events/event/${id}/promoters?filter[eventToPromoter.referringSource][eq]=email`,
        {
          headers: {
            Authorization: `Bearer ${await user?.getIdToken(true)}`,
          },
        },
      )

      setGraphData((oldGraphData) => {
        const newGraphData = [...oldGraphData]
        newGraphData[3] = {
          id: 4,
          count: data.total,
          label: 'Email',
        }

        return newGraphData
      })
    }

    const getEventToPromoterFacebook = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events/event/${id}/promoters?filter[eventToPromoter.referringSource][eq]=facebook`,
        {
          headers: {
            Authorization: `Bearer ${await user?.getIdToken(true)}`,
          },
        },
      )
      setGraphData((oldGraphData) => {
        const newGraphData = [...oldGraphData]
        newGraphData[1] = {
          id: 2,
          count: data.total,
          label: 'Facebook',
        }

        return newGraphData
      })
    }

    const getEventToPromoterLinkedin = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events/event/${id}/promoters?filter[eventToPromoter.referringSource][eq]=linkedin`,
        {
          headers: {
            Authorization: `Bearer ${await user?.getIdToken(true)}`,
          },
        },
      )
      setGraphData((oldGraphData) => {
        const newGraphData = [...oldGraphData]
        newGraphData[0] = {
          id: 1,
          count: data.total,
          label: 'Linkedin',
        }

        return newGraphData
      })
    }

    const getEventToPromoterTwitter = async () => {
      const { data } = await axios.get(
        `${BACKEND_API}/events/event/${id}/promoters?filter[eventToPromoter.referringSource][eq]=twitter`,
        {
          headers: {
            Authorization: `Bearer ${await user?.getIdToken(true)}`,
          },
        },
      )
      setGraphData((oldGraphData) => {
        const newGraphData = [...oldGraphData]
        newGraphData[2] = {
          id: 3,
          count: data.total,
          label: 'Twitter',
        }

        return newGraphData
      })
    }

    getEventToPromoterFacebook()
    getEventToPromoterEmail()
    getEventToPromoterTwitter()
    getEventToPromoterLinkedin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  Chart.register(CategoryScale)
  Chart.register(ChartDataLabels)

  const chartData = useMemo(
    () => ({
      labels: graphData?.map((data) => data.label),

      datasets: [
        {
          data: graphData?.map((data) => data.count),
          backgroundColor: ['#B794F4', '#C0D4FF', '#AAE5C9', '#FFF1F1'],
          enable: true,
          minBarLength: 10,
        },
      ],
    }),
    [graphData],
  )

  return (
    <div className='chart-container' style={{ width: '100%' }}>
      <Bar
        data={chartData}
        options={{
          scales: {
            x: {
              grid: {
                display: false,
              },
            },

            y: {
              display: false,

              grid: {
                display: false,
              },
            },
          },

          plugins: {
            datalabels: {
              color: '#36A2EB',
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  )
}

export default BarComponent
