import axios from 'axios'
import SharePage from 'components/SharePage/SharePage'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

const BACKEND_API = process.env.REACT_APP_BACKEND_API

const customizationInitialState = {
  componentAlignment: '',
  headerAlignment: '',
  backgroundImageURL: '',
  opacity: '',
  logoURL: '',
  elementColorScheme: '',
  backgroundColorScheme: '',
  primaryFontSize: '',
  primaryFontColorScheme: '',
  secondaryFontSize: '',
  secondaryFontColorScheme: '',
}

const Share = () => {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const [customization, setCustomization] = useState(customizationInitialState)

  const token = searchParams.get('boost_unique_code')

  useEffect(() => {
    const fetchByToken = async () => {
      const { data } = await axios.get(`${BACKEND_API}/events/event-details-token/${token}`)
      setCustomization({
        ...customization,
        componentAlignment: data?.customization?.componentAlignment ?? '',
        headerAlignment: data?.customization?.headerAlignment ?? '',
        backgroundImageURL: data?.customization?.backgroundImageURL ?? '',
        opacity: data?.customization?.opacity ?? '',
        logoURL: data?.customization?.logoURL ?? '',
        elementColorScheme: data?.customization.elementColorScheme ?? '',
        backgroundColorScheme: data?.customization.backgroundColorScheme ?? '',
        primaryFontSize: data?.customization.primaryFontSize ?? '',
        primaryFontColorScheme: data?.customization.primaryFontColorScheme ?? '',
        secondaryFontSize: data?.customization.secondaryFontSize ?? '',
        secondaryFontColorScheme: data?.customization.secondaryFontColorScheme ?? '',
      })
    }

    const fetchSingleEvent = async () => {
      const { data } = await axios.get(`${BACKEND_API}/events/event/${id}`)
      setCustomization({
        ...customization,
        componentAlignment: data?.customization?.componentAlignment ?? '',
        headerAlignment: data?.customization?.headerAlignment ?? '',
        backgroundImageURL: data?.customization?.backgroundImageURL ?? '',
        opacity: data?.customization?.opacity ?? '',
        logoURL: data?.customization?.logoURL ?? '',
        elementColorScheme: data?.customization.elementColorScheme ?? '',
        backgroundColorScheme: data?.customization.backgroundColorScheme ?? '',
        primaryFontSize: data?.customization.primaryFontSize ?? '',
        primaryFontColorScheme: data?.customization.primaryFontColorScheme ?? '',
        secondaryFontSize: data?.customization.secondaryFontSize ?? '',
        secondaryFontColorScheme: data?.customization.secondaryFontColorScheme ?? '',
      })
    }
    if (token) {
      fetchByToken()
    } else {
      fetchSingleEvent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SharePage
      headerAlignment={customization.headerAlignment}
      componentAlignment={customization.componentAlignment}
      photo={customization.backgroundImageURL}
      opacity={customization.opacity}
      backgroundImageURL={customization.backgroundImageURL}
      logoURL={customization.logoURL}
      logo={customization.logoURL}
      elementColorScheme={customization.elementColorScheme}
      backgroundColorScheme={customization.backgroundColorScheme}
      primaryFontSize={customization.primaryFontSize}
      primaryFontColorScheme={customization.primaryFontColorScheme}
      secondaryFontSize={customization.secondaryFontSize}
      secondaryFontColorScheme={customization.secondaryFontColorScheme}
    />
  )
}

export default Share
