import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material'

interface CustomAccordionProps {
  text: string
  isOpen: string | false
  handleOpen: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void
  panel: string
  mainImage: string
  arrow: string
  children: React.ReactNode
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({
  isOpen,
  handleOpen,
  panel,
  mainImage,
  arrow,
  text,
  children,
}) => {
  return (
    <Accordion
      sx={{
        '&:before': {
          backgroundColor: 'transparent !important',
        },
        border: 'none !important',
        boxShadow: 'none !important',
      }}
      expanded={isOpen === panel}
      onChange={handleOpen(panel)}
      data-intercom-target='rewards'
    >
      <AccordionSummary
        aria-controls='panel1bh-content'
        id='panel1bh-header'
        sx={{
          padding: '12px 16px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <img style={{ width: '24px', height: '24px' }} src={mainImage} alt='' />
            <p style={{ fontSize: '14px', color: '#4D4D4D', marginLeft: '10px' }}>{text}</p>
          </Grid>
          <Grid item>
            <img style={{}} src={arrow} alt='' />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default CustomAccordion
