import { IconProps } from '../types/icon-types';

export function SettingsIcon({ color = 'currentColor' }: IconProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="demio-icon dropdown-menu-item-hover"
      style={{ fontSize: '20px' }}
    >
      <path
        opacity="0.4"
        d="M45.687 29.587l-3.994-2.306a18.06 18.06 0 000-6.581l3.994-2.306a1.135 1.135 0 00.516-1.313 23.361 23.361 0 00-5.129-8.868 1.125 1.125 0 00-1.387-.216l-3.994 2.306a17.703 17.703 0 00-5.7-3.29V2.409a1.125 1.125 0 00-.876-1.096 23.572 23.572 0 00-10.238 0 1.125 1.125 0 00-.881 1.096v4.613a18.244 18.244 0 00-5.7 3.29l-3.99-2.306a1.114 1.114 0 00-1.387.216 23.218 23.218 0 00-5.128 8.869 1.125 1.125 0 00.516 1.312l3.994 2.306a18.058 18.058 0 000 6.582l-3.994 2.306a1.132 1.132 0 00-.516 1.312 23.343 23.343 0 005.128 8.87 1.125 1.125 0 001.388.215l3.994-2.306a17.675 17.675 0 005.7 3.29v4.613a1.125 1.125 0 00.88 1.096c3.372.75 6.867.75 10.238 0a1.125 1.125 0 00.882-1.096v-4.613a18.252 18.252 0 005.7-3.29l3.993 2.306a1.115 1.115 0 001.388-.216 23.205 23.205 0 005.128-8.869 1.158 1.158 0 00-.525-1.322zm-21.694 3.394a8.99 8.99 0 110-17.981 8.99 8.99 0 010 17.981z"
        fill={color}
      ></path>
      <path d="M23.994 29.981a5.991 5.991 0 110-11.982 5.991 5.991 0 010 11.982z" fill={color}></path>
    </svg>
  );
}
