import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import { useState } from 'react'

interface CustomSelectProps {
  selectedValues: CustomizationTemplateType
  setSelectedValues: React.Dispatch<React.SetStateAction<CustomizationTemplateType>>
}

const CustomSelect: React.FC<CustomSelectProps> = ({ setSelectedValues }) => {
  const [selectedItem, setSelectedItem] = useState('100%')

  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ marginBottom: '20px' }}>Opacity:</Typography>
      <ButtonGroup
        variant='outlined'
        aria-label='outlined button group'
        sx={{ padding: '0px 0px', width: '100%' }}
      >
        <Button
          onClick={() => {
            setSelectedItem('100%')
            setSelectedValues((prev) => ({
              ...prev,
              opacity: '100%',
            }))
          }}
          sx={{
            color: selectedItem === '100%' ? '#1D4842' : '#676E76',
            border: '1px solid #E8ECF3 !important',
            borderColor: selectedItem === '100%' ? '#1D4842 !important ' : '#E8ECF3 !important',
            marginRight: '1px',
          }}
        >
          Low
        </Button>
        <Button
          sx={{
            color: selectedItem === '50%' ? '#1D4842' : '#676E76',
            border: '1px solid #E8ECF3 !important',
            borderColor: selectedItem === '50%' ? '#1D4842 !important ' : '#E8ECF3 !important',
            marginRight: '1px',
          }}
          onClick={() => {
            setSelectedItem('50%')
            setSelectedValues((prev) => ({
              ...prev,
              opacity: '50%',
            }))
          }}
        >
          Medium
        </Button>
        <Button
          sx={{
            color: selectedItem === '20%' ? '#1D4842' : '#676E76',
            border: '1px solid #E8ECF3 !important',
            borderColor: selectedItem === '20%' ? '#1D4842 !important ' : '#E8ECF3 !important',
            marginRight: '1px',
          }}
          onClick={() => {
            setSelectedItem('20%')
            setSelectedValues((prev) => ({
              ...prev,
              opacity: '20%',
            }))
          }}
        >
          High
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default CustomSelect
