import { useEffect, useState } from 'react'
import ListRegistrantsItem from './ListRegistrantsItem'
import search from 'assets/images/search.svg'
import Pagination from '@mui/material/Pagination'
import { useParams } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import axios from 'axios'
import success from 'assets/images/success.svg'
import close from 'assets/images/close.svg'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import arrowup from 'assets/images/ArrowUp.svg'
import arrowdown from 'assets/images/ArrowDown.svg'
import arrows from 'assets/images/Arrows.svg'
import emptystate from 'assets/images/emptystate.svg'
import infoIconOutline from 'assets/images/info-icon-outline.svg'
import documentImage from 'assets/images/document.svg'
import { ClipLoader } from 'react-spinners'
import { debounce } from 'lodash'
import './ListRegistrants.scss'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Snackbar from '@mui/material/Snackbar'
import { IconButton, Paper } from '@mui/material'
import Typography from '@mui/material/Typography'

const ListRegistrants = () => {
  const BACKEND_API = process.env.REACT_APP_BACKEND_API
  const filter = 'All Registered'
  const [count, setCount] = useState('20')
  const [registrants, setRegistrants] = useState<Array<RegistrantType>>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [sync, setSync] = useState('DESC')
  const [referrals, setReferrals] = useState('')
  const [totalAvailablePageCount, setTotalAvailablePageCount] = useState<number>(0)
  const [total, setTotal] = useState(0)
  const [changed, setChanged] = useState(true)
  const [page, setPage] = useState<number>(1)
  const [copied, setCopied] = useState(false)
  const [removed, setRemoved] = useState(false)
  const [resend, setResend] = useState(false)
  const [sortQuery, setSortQuery] = useState('sort[promoter.createdDate]=DESC')
  const [back, setBack] = useState(false)
  const [user, loading] = useAuthState(auth)
  const { id } = useParams()
  const [regisrantsLoading, setRegistrantsLoading] = useState(true)
  const [isCsvExportSnackbarOpen, setIsCsvExportSnackbarOpen] = useState(false)

  const getPagination = async () => {
    const { data } = await axios.get(
      `${BACKEND_API}/events/event/${id}/promoters?page=${page}&limit=${count}&filter[promoter.fullName][include]=${searchQuery}&sort[eventToPromoter.archived]=ASC&${sortQuery}`,
      {
        headers: {
          Authorization: `Bearer ${await user?.getIdToken(true)}`,
        },
      },
    )
    setRegistrants(data.result)
    setTotal(data.total)
    setTotalAvailablePageCount(Number(Math.ceil(Number(data.total) / Number(count))))
    setChanged(false)
  }

  const debouncedGetPagination = debounce(getPagination, 1700)

  useEffect(() => {
    if (changed) {
      debouncedGetPagination()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed])

  useEffect(() => {
    getPagination()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    const spinnerTimer = setTimeout(() => {
      setRegistrantsLoading(false)
    }, 2000)
    return () => {
      clearTimeout(spinnerTimer)
    }
  }, [regisrantsLoading])

  const onUserDelete = async (promoterId: string, eventToPromoterId: string) => {
    const { data } = await axios.patch(
      `${BACKEND_API}/events/event/${id}/promoters/promoter/${promoterId}/event-to-promoters/event-to-promoter/${eventToPromoterId}`,
      {
        archived: true,
      },
      {
        headers: {
          Authorization: `Bearer ${await user?.getIdToken(true)}`,
        },
      },
    )
    if (data) {
      setRemoved(true)
    }
    setChanged(true)
  }

  const onUserAddBack = async (promoterId: string, eventToPromoterId: string) => {
    await axios.patch(
      `${BACKEND_API}/events/event/${id}/promoters/promoter/${promoterId}/event-to-promoters/event-to-promoter/${eventToPromoterId}`,
      {
        archived: false,
      },
      {
        headers: {
          Authorization: `Bearer ${await user?.getIdToken(true)}`,
        },
      },
    )
    setChanged(true)
  }

  const onPageChange = (page: number) => {
    setPage(page)
    setChanged(true)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    setSearchQuery(query)
    setChanged(true)
  }

  const handleExportCSV = async () => {
    const response = await axios.post(`${BACKEND_API}/events/event/${id}/export-promoters`, null, {
      headers: {
        Authorization: `Bearer ${await user?.getIdToken(true)}`,
      },
    })
    setIsCsvExportSnackbarOpen(response.data)
  }

  const handleCloseCsvExportSnackbar = () => setIsCsvExportSnackbarOpen(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false)
      setRemoved(false)
      setResend(false)
      setBack(false)
    }, 4000)
    return () => {
      clearTimeout(timer)
    }
  }, [copied, removed, resend, back])

  return (
    <>
      <div className='flex_between'>
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            backgroundColor: 'white',
            width: '366px',
          }}
          open={isCsvExportSnackbarOpen}
          onClose={handleCloseCsvExportSnackbar}
        >
          <Paper sx={{ padding: '24px', position: 'relative' }} elevation={0} variant='outlined'>
            <IconButton
              onClick={handleCloseCsvExportSnackbar}
              sx={{ position: 'absolute', top: '18px', right: '7px' }}
            >
              <img src={close} alt={'Close'} />
            </IconButton>

            <Box display='flex' alignItems='flex-start' sx={{ gap: '0 16px' }}>
              <img src={infoIconOutline} alt={'Info icon'} />
              <Typography sx={{ fontSize: '16px', lineHeight: '24px', letterSpacing: '0.5px' }}>
                Your export is running in the background and will be sent to {user?.email} upon
                completion.
              </Typography>
            </Box>
          </Paper>
        </Snackbar>

        {copied ? (
          <div className='copy_snackbar'>
            <img className='success_icon' src={success} alt='sucess' /> <p>URL copied!</p>
            <img className='close_x' src={close} alt='close' onClick={() => setCopied(false)} />
          </div>
        ) : (
          ' '
        )}
        {removed ? (
          <div className='copy_snackbar'>
            <img className='success_icon' src={success} alt='sucess' /> <p>Promoter removed!</p>
            <img className='close_x' src={close} alt='close' onClick={() => setRemoved(false)} />
          </div>
        ) : (
          ' '
        )}
        {resend ? (
          <div className='copy_snackbar'>
            <img className='success_icon' src={success} alt='sucess' /> <p>Email sent!</p>
            <img className='close_x' src={close} alt='close' onClick={() => setResend(false)} />
          </div>
        ) : (
          ' '
        )}

        {back ? (
          <div className='copy_snackbar'>
            <img className='success_icon' src={success} alt='sucess' /> <p>Promoter added!</p>
            <img className='close_x' src={close} alt='close' onClick={() => setBack(false)} />
          </div>
        ) : (
          ' '
        )}
        <FormControl sx={{ m: 1, minWidth: 300 }}>
          <Select labelId='demo-simple-select-label' id='demo-simple-select' value={filter}>
            <MenuItem value={'All Registered'}>All Registered</MenuItem>
          </Select>
        </FormControl>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <div
            style={{
              display: 'flex',
              gap: '0 20px',
              alignItems: 'center',
            }}
          >
            <p className='promoters'> {total} Promoters</p>
            <div className='input-icons'>
              <img src={search} className='fa fa-user icon' alt='Search' />
              <input
                placeholder='Type to search'
                value={searchQuery}
                onChange={handleSearch}
                className='input-field'
                type='text'
                maxLength={20}
              />
            </div>
          </div>
          <div style={{ marginLeft: '20px' }}>
            <Button
              disabled={!registrants.length}
              variant='text'
              onClick={handleExportCSV}
              sx={{ color: '#1D4842' }}
            >
              <Box display='flex' alignItems='center'>
                <img src={documentImage} alt='Document' style={{ marginRight: '10px' }} />{' '}
                <span> Export CSV</span>
              </Box>
            </Button>
          </div>
        </Box>
      </div>

      {registrants.length > 0 ? (
        <>
          <table className='table'>
            <thead>
              <tr className='table__tr'>
                <th className='tableth'>Promoter</th>
                <th className='tableth'>Source</th>
                <th className='tableth' style={{ width: '150px' }}>
                  Registrations
                  <img
                    className='arrowimg'
                    onClick={() => {
                      setChanged(true)
                      setSync('')
                      if (referrals === 'DESC') {
                        setReferrals('ASC')
                        setSortQuery('sort[countr]=ASC')
                      } else if (referrals === '') {
                        setReferrals('DESC')
                        setSortQuery('sort[countr]=DESC')
                      } else {
                        setReferrals('DESC')
                        setSortQuery('sort[countr]=DESC')
                      }
                    }}
                    style={{ marginLeft: '9px' }}
                    src={referrals === 'DESC' ? arrowdown : referrals === 'ASC' ? arrowup : arrows}
                    alt=''
                  />
                </th>

                <th className='tableth' style={{ marginLeft: '50px' }}>
                  Sync Date
                  <img
                    className='arrowimg'
                    onClick={() => {
                      setChanged(true)
                      setReferrals('')
                      if (sync === 'DESC') {
                        setSync('ASC')
                        setSortQuery('sort[promoter.createdDate]=ASC')
                      } else {
                        setSync('DESC')
                        setSortQuery('sort[promoter.createdDate]=DESC')
                      }
                    }}
                    style={{ marginLeft: '9px' }}
                    src={sync === 'DESC' ? arrowdown : sync === 'ASC' ? arrowup : arrows}
                    alt=''
                  />
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {registrants?.map((registrant: RegistrantType) => {
                return (
                  <ListRegistrantsItem
                    onUserDelete={onUserDelete}
                    key={registrant.eventToPromoter_id}
                    id={registrant.eventToPromoter_id}
                    name={registrant.promoter_full_name}
                    syncdate={registrant.eventToPromoter_created_date}
                    email={registrant.promoter_email}
                    sessions={registrant.sessions}
                    registrations={registrant.countr}
                    eventToPromoterId={registrant.eventToPromoter_id}
                    eventToPromoterReferringSource={registrant.eventToPromoter_referring_source}
                    emailRecieved={registrant.eventToPromoter_invitation_email_sent}
                    setChanged={setChanged}
                    archived={registrant.eventToPromoter_archived}
                    onUserAddBack={onUserAddBack}
                    setCopied={setCopied}
                    setRemoved={setRemoved}
                    setBack={setBack}
                    setResend={setResend}
                  />
                )
              })}
            </tbody>
          </table>
          <div className='flex_between'>
            <Pagination
              size='large'
              page={page}
              count={totalAvailablePageCount || 0}
              variant='outlined'
              shape='rounded'
              onChange={(event, page) => onPageChange(page)}
              className='pagination'
              sx={{
                '& .MuiPaginationItem-page': { color: '#1d4842', border: '1px solid #DEE4E8' },
                '& .Mui-selected': { backgroundColor: '#0000', border: '1px solid #1d4842' },
                '& .MuiPaginationItem-root:hover': { backgroundColor: 'transparent' },
              }}
            />
            <select
              onChange={(e) => {
                setCount(e.target.value)
                setPage(1)
                setChanged(true)
              }}
              value={count}
              className='select_count'
            >
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </div>
        </>
      ) : regisrantsLoading ? (
        <div className='flex_center'>
          <ClipLoader color='#1D4842' size={20} speedMultiplier={0.6} />
        </div>
      ) : (
        <div className='empty_div'>
          <img src={emptystate} alt='' />
          <h2>No Results</h2>
          <p>
            The audience will appear when your <br /> event starts receiving registrations.
          </p>
        </div>
      )}
    </>
  )
}

export default ListRegistrants
