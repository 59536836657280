import EventInfo from 'components/EventInfo/EventInfo'
import EventsList from 'components/EventsList/EventsList'
import { useLocation } from 'react-router-dom'
import './Events.scss'
import { NavbarContainer } from '../../ui-kit/containers/navbar/navbar-container/navbar-container'
import { Products } from '../../ui-kit/constants/products-constants'
import { useEffect, useState } from 'react'
import { getUserFullInfo } from '../../helper/User'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { UserType } from '../../ui-kit/types/user-types'
import ROUTES from '../../constants/routes'

const Events = () => {
  const [user, setUser] = useState<UserType>(null)
  const [firebaseUser] = useAuthState(auth)
  const location = useLocation()

  useEffect(() => {
    if (firebaseUser) getUserFullInfo(firebaseUser).then((userFullInfo) => setUser(userFullInfo))
  }, [firebaseUser])

  return (
    <div className='events_wrapper'>
      <NavbarContainer user={user} productVariant={Products.Boost} demioUrl={ROUTES.DEMIO} />

      {(() => {
        if (location.state && location.state.page === '/audience') return <EventInfo />
        else return <EventsList />
      })()}
    </div>
  )
}

export default Events
