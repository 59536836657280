import * as React from 'react'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress sx={{ color: '#ffff' }} thickness={1.5} variant='determinate' {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        <Typography variant='caption' component='div' color='#ffff'>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

interface CircularProps {
  ready: boolean
}
export const CircularStatic: React.FC<CircularProps> = ({ ready }) => {
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    if (ready) {
      setProgress(100)
    } else {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 90 ? 90 : prevProgress + 10))
      }, 500)
      return () => {
        clearInterval(timer)
      }
    }
  }, [ready])

  return <CircularProgressWithLabel value={progress} />
}
