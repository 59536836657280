export function BoostIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 51 85" width="1.5rem" height="1.5rem">
      <mask width="51" height="85" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'luminance' }}>
        <path fill="#fff" fillRule="evenodd" d="M0 84.2V.5h50.024v83.7H0z" clipRule="evenodd"></path>
      </mask>

      <g fill="currentColor" fillRule="evenodd" clipRule="evenodd" mask="url(#BoostMenuIconGreen_svg__a)">
        <path d="M24.343 52.273a32.838 32.838 0 0121.671 8.166 20.989 20.989 0 00-9.316-16.709 1.997 1.997 0 01-.765-2.347c1.415-3.873 2.163-8.364 2.163-12.993 0-12.95-5.991-23.89-13.082-23.89-7.091 0-13.082 10.94-13.082 23.89 0 4.633.748 9.129 2.163 13a1.998 1.998 0 01-.766 2.349 20.983 20.983 0 00-9.24 15.525c5.757-4.536 12.803-6.99 20.254-6.99zm23.372 14.715a2 2 0 01-1.514-.692 28.86 28.86 0 00-21.858-10.023c-7.892 0-15.266 3.137-20.763 8.833a2 2 0 01-3.428-1.181A26.172 26.172 0 010 61.199a24.967 24.967 0 019.845-19.883c-1.254-3.958-1.913-8.392-1.913-12.926C7.932 12.752 15.435.5 25.014.5s17.082 12.252 17.082 27.89c0 4.53-.659 8.962-1.913 12.917A24.977 24.977 0 0150.028 61.2c0 1.366-.115 2.75-.339 4.115a2.003 2.003 0 01-1.974 1.675z"></path>
        <path d="M25.018 42.765a2 2 0 01-2-2V16.253a2 2 0 014 0v24.512a2 2 0 01-2 2M12.93 76.507a2.001 2.001 0 01-1.765-2.936l4.286-8.101a1.996 1.996 0 012.702-.832 2 2 0 01.832 2.703l-4.285 8.1a2 2 0 01-1.77 1.066m24.165 0a2 2 0 01-1.77-1.065l-4.284-8.1a2 2 0 01.832-2.704 1.997 1.997 0 012.702.832l4.285 8.1a2.001 2.001 0 01-1.765 2.937m-12.263 7.692a2 2 0 01-2-2V66.813a2 2 0 014 0V82.2a2 2 0 01-2 2"></path>
      </g>
    </svg>
  );
}

export const BoostLogo = () => {
  return (
    <svg
      style={{ display: 'block' }}
      width="150"
      height="45"
      viewBox="0 0 150 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1968_49899"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="150"
        height="45"
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M0 44.9997V-0.000145161H149.999V44.9997H0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1968_49899)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.2683 29.6162H53.0969C55.0736 29.6162 56.6948 28.748 56.6948 26.2222C56.6948 23.6958 55.0736 22.8673 53.0969 22.8673H45.2683V29.6162ZM45.2683 17.8163H52.5831C54.2436 17.8163 55.5879 16.869 55.5879 14.9346C55.5879 13.0013 54.2436 12.0932 52.5831 12.0932H45.2683V17.8163ZM39.5747 34.6678V7.04102H53.0177C58.0784 7.04102 61.2809 9.48832 61.2809 14.1845C61.2809 16.3948 60.1746 18.3679 58.2367 19.5915C59.9769 20.3415 62.3883 22.5512 62.3883 26.2221C62.3883 32.3393 58.3557 34.6678 53.0177 34.6678H39.5747Z"
          fill="#1D4842"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.9181 24.2524C81.9181 20.2664 79.7438 18.0164 76.4621 18.0164C73.1799 18.0164 71.0055 20.2664 71.0055 24.2524C71.0055 28.2777 73.1799 30.4884 76.4621 30.4884C79.7438 30.4884 81.9181 28.2777 81.9181 24.2524ZM65.4697 24.2524C65.4697 18.3718 69.9773 13.4385 76.4615 13.4385C82.9457 13.4385 87.4533 18.3718 87.4533 24.2524C87.4533 30.1728 82.9457 35.0664 76.4615 35.0664C69.9773 35.0664 65.4697 30.1728 65.4697 24.2524Z"
          fill="#1D4842"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106.827 24.2524C106.827 20.2664 104.653 18.0164 101.37 18.0164C98.0887 18.0164 95.9143 20.2664 95.9143 24.2524C95.9143 28.2777 98.0887 30.4884 101.37 30.4884C104.653 30.4884 106.827 28.2777 106.827 24.2524ZM90.3789 24.2524C90.3789 18.3718 94.8865 13.4385 101.371 13.4385C107.855 13.4385 112.362 18.3718 112.362 24.2524C112.362 30.1728 107.855 35.0664 101.371 35.0664C94.8865 35.0664 90.3789 30.1728 90.3789 24.2524Z"
          fill="#1D4842"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.655 30.0546L119.321 27.8451C120.151 29.423 121.931 30.489 124.224 30.489C126.557 30.489 127.782 29.5418 127.782 28.3972C127.782 27.0553 126.043 26.4629 122.919 25.9108C119.163 25.2398 115.684 23.5432 115.684 19.7541C115.684 16.2812 119.005 13.4399 124.342 13.4399C127.821 13.4399 130.747 14.7022 132.527 16.9129L129.008 19.8729C127.821 18.4913 126.24 17.8596 124.342 17.8596C122.207 17.8596 120.942 18.6095 120.942 19.6751C120.942 20.8987 123.156 21.6879 126.715 22.319C131.775 23.227 133.317 25.4764 133.317 28.5155C133.317 31.8701 130.194 35.0667 124.105 35.0667C119.716 35.0667 115.802 32.8964 114.655 30.0546"
          fill="#1D4842"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M139.442 28.2402V18.4126H135.843V13.834H139.442V7.04573H144.977V13.834H150V18.4126H144.977V27.7273C144.977 29.5429 145.69 30.4902 147.626 30.4902C148.418 30.4902 149.248 30.4112 150 30.1343V34.7129C148.773 34.9494 147.745 35.0682 146.48 35.0682C141.735 35.0682 139.442 32.5816 139.442 28.2402"
          fill="#1D4842"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1111 27.835C17.4339 27.835 21.572 29.4119 24.7831 32.2253C24.6533 28.6044 22.7979 25.2695 19.7655 23.242C19.353 22.9662 19.1822 22.4453 19.3535 21.9797C20.1156 19.898 20.5185 17.4829 20.5185 14.9947C20.5185 8.0324 17.2917 2.15016 13.4725 2.15016C9.65329 2.15016 6.42653 8.0324 6.42653 14.9947C6.42653 17.4856 6.82941 19.9023 7.59153 21.984C7.76226 22.449 7.59207 22.9705 7.17896 23.2463C4.32437 25.1549 2.51305 28.2211 2.20228 31.5931C5.30301 29.1549 9.098 27.835 13.1111 27.835ZM25.6994 35.7466C25.3913 35.7466 25.0918 35.6143 24.8839 35.374C21.9227 31.9498 17.6322 29.9858 13.1112 29.9858C8.86053 29.9858 4.88889 31.6724 1.92819 34.7347C1.63627 35.0364 1.19677 35.1407 0.801978 35.0046C0.405567 34.8686 0.124956 34.5154 0.0818674 34.0993C0.0269301 33.5724 0 33.0934 0 32.6337C0 28.4181 1.96805 24.4815 5.30253 21.9439C4.62713 19.8165 4.27219 17.4326 4.27219 14.995C4.27219 6.58697 8.31331 -0.000105091 13.4726 -0.000105091C18.6318 -0.000105091 22.673 6.58697 22.673 14.995C22.673 17.4305 22.318 19.8133 21.6426 21.9396C24.9771 24.4772 26.9452 28.4159 26.9452 32.6337C26.9452 33.3681 26.8832 34.1122 26.7626 34.8466C26.6942 35.2584 26.3936 35.5933 25.9913 35.7068C25.8949 35.7337 25.7969 35.7466 25.6994 35.7466Z"
          fill="#1D4842"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4749 22.7246C12.8802 22.7246 12.3977 22.2428 12.3977 21.6491V8.47108C12.3977 7.8774 12.8802 7.39558 13.4749 7.39558C14.0695 7.39558 14.5521 7.8774 14.5521 8.47108V21.6491C14.5521 22.2428 14.0695 22.7246 13.4749 22.7246"
          fill="#1D4842"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.96448 40.8656C6.79488 40.8656 6.6226 40.8253 6.46162 40.7403C5.93615 40.4623 5.73587 39.8123 6.01368 39.2869L8.32176 34.9317C8.59903 34.4069 9.24941 34.2058 9.77703 34.4843C10.3025 34.7617 10.5028 35.4123 10.225 35.9377L7.91743 40.2929C7.72415 40.6575 7.35104 40.8656 6.96448 40.8656"
          fill="#1D4842"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9796 40.8655C19.5931 40.8655 19.22 40.6574 19.0267 40.2928L16.7191 35.9376C16.4413 35.4123 16.6416 34.7617 17.1671 34.4843C17.6931 34.2063 18.3451 34.4063 18.6224 34.9316L20.9304 39.2868C21.2083 39.8122 21.008 40.4622 20.4825 40.7402C20.3221 40.8251 20.1492 40.8655 19.9796 40.8655"
          fill="#1D4842"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3744 44.9998C12.7797 44.9998 12.2972 44.518 12.2972 43.9243V35.6527C12.2972 35.059 12.7797 34.5772 13.3744 34.5772C13.969 34.5772 14.4516 35.059 14.4516 35.6527V43.9243C14.4516 44.518 13.969 44.9998 13.3744 44.9998"
          fill="#1D4842"
        />
      </g>
    </svg>
  );
};
